{
  "ariaLabel": {
    "viewDetails": "Afficher les détails",
    "downloadDataAssets": "Télécharger les ressources de données"
  },
  "topMenu": {
    "dihTitle": "Data Intelligence Hub",
    "DIHOrgRegistration": "Data Intelligence Hub - Enregistrement d'organisation",
    "dihSubTitle": "Extraire la valeur des données en toute sécurité",
    "logout": "Se déconnecter",
    "myAccount": "Mon compte",
    "myOrganization": "Mon organisation"
  },
  "userLogoutAlert": {
    "title": "Êtes-vous sûr de vouloir vous déconnecter?",
    "back": "Retour",
    "logout": "Se déconnecter",
    "myOrganization": "Mon organisation"
  },
  "sideMenu": {
    "dashboard": "Tableau de bord",
    "connectors": "Connecteurs",
    "myConnectors": "Mes connecteurs",
    "dataspaces": "Dataspaces",
    "discover": "Découvrir",
    "manage": "Gérer",
    "marketplace": "Marché",
    "myDataspaces": "Mes adhésions",
    "browseDataspaces": "Parcourir les données",
    "organizations": "Organisations",
    "build": "Construire",
    "ownedDataspaces": "Mes espaces de données",
    "collapse": "Barre latérale d'effondrement",
    "participate": "Participer",
    "getVerified": "Être vérifié",
    "workbench": "Table de travail",
    "dataIncoming": "Données entrantes",
    "applications": "Applications",
    "verify": "Vérifier",
    "digitalIDDashboard": "Obtenir des informations",
    "mySignatures": "Mes signatures",
    "externalConnectionManager": "Gestionnaire de connexions externes"
  },
  "dropdown": {
    "selectAll": "Sélectionner tout",
    "noOption": "Aucune option disponible",
    "noSuchOption": "Cette option n'existe pas ..."
  },
  "common": {
    "start": "Commencer",
    "finish": "Terminer",
    "firstName": "Prénom",
    "lastName": "Nom de famille",
    "email": "Adresse e-mail",
    "phoneNumber": "Numéro de téléphone",
    "contactPerson": "Personne de contact",
    "welcome": "Accueillir",
    "street": "Rue",
    "city": "Ville",
    "country": "Pays",
    "postCode": "Code postal",
    "number": "Nombre",
    "next": "Suivant",
    "prev": "Précédent",
    "alreadyAccount": "Vous avez déjà un compte?",
    "login": "Se connecter",
    "loginNow": "Se connecter maintenant",
    "register": "S'inscrire",
    "home": "Page d'accueil",
    "submit": "Envoyer",
    "attention": "Attention",
    "desc": "Description",
    "website": "Site web",
    "contact": "Contact",
    "details": "Détails",
    "plan": "Plan",
    "listView": "Vue liste",
    "gridView": "Vue grille",
    "firstPage": "À la première page",
    "previousPage": "À la page précédente",
    "nextPage": "À la page suivante",
    "lastPage": "À la dernière page",
    "download": "Télécharger",
    "delete": "Supprimer",
    "edit": "Modifier",
    "information": "Information",
    "organization": "Organisation",
    "cancel": "Annuler",
    "save": "Sauvegarder",
    "upload": "Télécharger",
    "link": "Lien",
    "view": "Voir",
    "search": "Rechercher",
    "searchTerm": "Recherche",
    "selectOption": "Choisir une option",
    "sortBy": "Trier par",
    "dataspace": "Espace de données",
    "select": "Sélectionner",
    "date": "Date",
    "title": "Titre",
    "successful": "Réussite",
    "unsuccessful": "Sans succès",
    "statuses": {
      "connected": "connecté",
      "disconnected": "déconnecté",
      "deploying": "en déploiement",
      "deployed": "déployé",
      "failed": "échoué",
      "available": "disponible",
      "success": "succès",
      "active": "actif",
      "pending": "en attente",
      "declined": "refusé",
      "invited": "invité",
      "onboarding": "en cours d'intégration",
      "withdrawn": "retiré",
      "successful": "réussi",
      "unsuccessful": "sans succès",
      "valid": "valide",
      "expired": "expiré",
      "revoked": "révoqué"
    },
    "andMore": "et {count} de plus | et {count} autres",
    "confirm": "Confirmer",
    "no": "Non",
    "links": {
      "dihWiki": "https://docs.dih.telekom.com/en/home"
    },
    "formError": "Quelque chose ne va pas.",
    "question": {
      "interested": "Intéressé?"
    },
    "moreInfo": "Si vous avez besoin de plus d'informations:",
    "goToWebsite": "Aller sur notre site Web",
    "provider": "Fournisseur",
    "required": "Requis",
    "errors": "Erreurs",
    "error": "Erreur"
  },
  "genericError": "Oh, quelque chose a mal tourné ! Veuillez réessayer.",
  "validation": {
    "fieldRequired": "Champ requis.",
    "fieldNumeric": "Le champ peut ne contenir que des nombres.",
    "fieldAlpha": "Le champ ne peut contenir que des lettres, _ et -.",
    "fieldAlphanumeric": "Le champ ne peut contenir que des lettres, des numéros, _ et -.",
    "fieldNumericDash": "Le champ peut ne contenir que des nombres et -.",
    "fieldHouseNumber": "Le champ {field} ne peut contenir que des chiffres, des lettres et les caractères - et /.",
    "fieldPhoneNumber": "Le champ peut ne contenir que des nombres et -. Il doit commencer par + ou 00.",
    "fieldAlphaDash": "Le champ peut ne contenir que des lettres et -.",
    "exceptHTMLTagBrackets": "Le champ ne doit pas contenir les caractères suivants: ^ <>",
    "isMaxLength": "dépasse la longueur maximale de 10 caractères.",
    "emailFormat": "L'adresse e-mail a un mauvais format",
    "fileSizeError": "La taille du fichier est plus grande que {size} MB.",
    "csvFileSizeError": "Ce fichier est trop gros. Vous pouvez télécharger un fichier jusqu'à {size} Mo.",
    "csvFileExtensionError": "Seuls les fichiers avec l'extension csv sont autorisés.",
    "csvFileIsEmptyError": "Ce fichier est vide. Vous devez télécharger un fichier d'au moins {size} Ko.",
    "csvFileFormatError": "Le fichier ne correspond pas au format attendu. Veuillez utiliser le modèle fourni et réessayer.",
    "uploadFails": "Échec du téléchargement",
    "fileExtensionError": "L'extension de fichier ne convient pas",
    "fileIsMalwareInfectedError": "Ce fichier semble contenir des logiciels malveillants. Pour des raisons de sécurité, ce fichier ne peut pas être téléchargé. Veuillez vérifier à nouveau votre fichier",
    "customValidation": "Le {field} a le mauvais format.",
    "selectCountryFirst": "Veuillez d'abord sélectionner le champ du nom du pays.",
    "validationPostCodeCaseDE": "Le champ a le mauvais format. Il doit contenir 5 chiffres, par exemple, 53113",
    "validationAlphanumericCase": "Le champ peut ne contenir que des caractères alphanumériques, des tirets et des soulignements. La valeur doit commencer par un caractère alphanumérique.",
    "validationNumericDashCase": "Le champ peut ne contenir que des nombres et -.",
    "url": "Veuillez saisir une URL valide",
    "maxInvitationSize": "Vous pouvez inviter jusqu’à {size} participants à la fois",
    "password": {
      "passwordStrength": "Fiabilité du mot de passe",
      "weak": "Faible",
      "average": "Moyenne",
      "strong": "Fort",
      "passwordValidationMoreChar": "{count} ou plus de caractères",
      "passwordUpperAndLowerChar": "Caractères supérieurs et minuscules",
      "passwordAtLeastNumber": "Au moins un nombre",
      "passwordAtLeastSpecialChar": "Au moins un personnage spécial (sauf <> & \"` [])",
      "passwordDiffFromPrev": "Diffère de vos mots de passe précédents"
    }
  },
  "footer": {
    "tagLine": "Passons des performances plus élevées",
    "imprint": "Imprimer",
    "imprintShort": "Mentions lég.",
    "imprintLink": "https://dih.telekom.com/en/imprint",
    "contact": "Contact",
    "contactLink": "https://dih.telekom.com/en/contact",
    "termsAndConditions": "Termes et conditions",
    "privacy": "Politique de confidentialité",
    "privacyShort": "Politique confid.",
    "telekom": "T-Systems International GmbH. Tous droits réservés.",
    "telekomShort": "T-Systems International GmbH",
    "note": "Telekom Data Intelligence Hub - pour les clients d'entreprise uniquement",
    "brandedNote": "Propulsé par Telekom Data Intelligence Hub",
    "help": "Aide",
    "cookieSettings": "Cookies",
    "cookieSettingsShort": "Cookies",
    "life": "Life is for sharing"
  },
  "onboarding": {
    "welcome": "Bienvenue sur Data Intelligence Hub",
    "welcomeDIH": "Bienvenue sur Data Intelligence Hub",
    "informationForOrganization": "Les informations de votre organisation:",
    "orgNameLabel": "Nom de l'organisation",
    "orgUnitNameLabel": "Nom de l'unité d'organisation",
    "emailAddressLabel": "Adresse e-mail",
    "countryNameLabel": "Pays",
    "bpnNumberLabel": "Numéro de partenaire commercial (BPN)",
    "contactPersonInfo": "Personne de contact",
    "fillInfoBelow": "Veuillez remplir les informations ci-dessous",
    "orgRegNameLabel": "Nom enregistré de l'organisation / unité",
    "legalNameHint": "Veuillez saisir le nom légal de votre organisation",
    "addressHeading": "Adresse du siège de l'organisation",
    "PLZCode": "Code postal",
    "verifyEmail": "Vérifier l'adresse e-mail",
    "verifyEmailMessage": "Merci d'avoir rejoint Data Intelligence Hub - votre organisation est déjà enregistrée chez nous. Veuillez vérifier votre e-mail.",
    "taxId": "numéro de TVA",
    "commerceRegisterNumber": "Numéro de registre commercial",
    "orgRepresentative": {
      "title": "Configurez le compte de votre représentant de votre organisation",
      "orgContactPerson": "Interlocuteur au sein de l'organisation",
      "whatDefinesYou": "Qu'est-ce qui vous définit le mieux?",
      "agreeText": "Je confirme que j'ai lu et que j'accepte {1} de Data Intelligence Hub",
      "confirmText": "J'approuve que mes données personnelles soient stockées et traitées conformément à <a href='#'>la Déclaration de confidentialité des données</a>"
    },
    "success": "Enregistrement de l'organisation réussie",
    "underReview": "Votre organisation est en cours de vérification.",
    "verified": "Vous avez réussi à vérifier votre organisation",
    "steps": {
      "counter": {
        "step": "Étape",
        "of": "de"
      },
      "stepWizard": {
        "orgInfo": "Informations sur l'organisation",
        "selectConnection": "Sélectionnez votre connexion",
        "configureConnection": "Configure votre connexion",
        "selectPackage": "Sélectionnez votre plan",
        "checkout": "Vérifier"
      },
      "actionButton": {
        "back": "Retour",
        "next": "Suivant",
        "order": "Commande avec obligation de payer",
        "checkout": "Vérifier",
        "selectMyPlan": "Sélectionnez mon plan"
      },
      "orgStep": {
        "fields": {
          "orgInfoLabel": "Détails de l'organisation",
          "dataSpaceAttributes": "Attributs supplémentaires",
          "orgName": "Nom légal de l'organisation",
          "departmentName": "Nom du département",
          "crnNumber": "Numéro de registre commercial",
          "taxId": "numéro de TVA",
          "bpnNo": "Numéro de partenaire commercial (BPN)",
          "addressInfo": "Adresse de facturation",
          "countryName": "Pays",
          "plzCode": "Code postal",
          "number": "Nombre",
          "city": "Ville",
          "street": "Rue",
          "contactPersonLabel": "Personne de contact",
          "fullName": "Nom et prénom",
          "firstName": "Prénom",
          "firstNamePlaceholder": "Entrez votre prénom",
          "lastName": "Nom de famille",
          "lastNamePlaceholder": "Entrez votre nom de famille",
          "contactPerson": "Personne de contact",
          "phoneNo": "Numéro de téléphone",
          "emailAdd": "Adresse e-mail",
          "dataPrivacyCheck": "Je consens à ce que mes données personnelles soient stockées et traitées conformément à {dataprivacystatement} et aux {supplementaryDataPrivacyConditions}",
          "termsAndConditionsCheck": "Je confirme qu’en tant que représentant autorisé de la société, j’ai lu, compris et accepté les {termsandcondition}.",
          "dataprivacystatement": "Déclaration de confidentialité des données",
          "supplementaryDataPrivacyConditions": "conditions supplémentaires de confidentialité des données",
          "termsandcondition": "conditions générales",
          "agreeToEnterContract": "J'accepte de conclure un contrat avec T-Systems International GmbH en fonction de la {serviceDescription} ci-dessus",
          "serviceDescription": "description du service",
          "markettingConsentCheck": "Je consens à recevoir des e-mails, des lettres, des appels ou des SMS/MMS de marketing concernant les produits, services et événements de T-Systems International GmbH et de Telekom Deutschland GmbH. Je peux retirer ce consentement à tout moment en envoyant un e-mail à {email}."
        }
      },
      "connection": {
        "sectionHeading": "Sélectionnez votre connexion",
        "sectionSubHeading": "Veuillez sélectionner votre type de connexion pour {dsName}",
        "textNote": "Seules les options compatibles avec Catena-X peuvent être sélectionnées",
        "recommendedConfiguration": "Configuration recommandée",
        "cloudAndDataPlan": "Plan cloud et données"
      },
      "packages": {
        "sectionHeading": "Sélectionnez votre plan",
        "sectionSubHeading": "Variations du plan de données",
        "basedOnYourSelection": "Sur la base de votre sélection, nous recommandons ce qui suit",
        "dataPackage": "Packages de données",
        "outgoingTraffic": "Trafic sortant",
        "storage": "Stockage",
        "selectedDataPlan": "Sélectionnez le plan de données",
        "dataPlanDetails": "Détails du plan de données",
        "dataPlanLabelForVolume": "Plans avec volume inclus",
        "dataPlanLabelFlexible": "Pay-as-you-go",
        "packageAvailable": "Ce package est disponible uniquement avec ce plan",
        "chargesApply": "Les frais s'appliquent si l'utilisation dépasse la limite de volume",
        "additionalStorage": "Stockage supplémentaire",
        "additionalTraffic": "Trafic sortant supplémentaire"
      },
      "cloudProviders": {
        "cloudInfrastructure": "Sélectionnez le fournisseur de cloud",
        "cloudAvailable": "Ce package est disponible uniquement sur ce fournisseur de cloud"
      },
      "checkout": {
        "sectionHeading": "Votre package de connexion à {orgName}",
        "setupCost": "Coûts de configuration",
        "monthlyCost": "Coûts mensuels",
        "payg": "Pay-as-you-go",
        "pipSheet": "Fiche d'information sur le produit",
        "totalCost": "Coût total",
        "totalPayable": "Total à payer",
        "dataPrivacyCheck": "J'accepte de payer les frais associés aux services commandés ci-dessus",
        "termsAndConditionsCheck": "J'accepte la durée du contrat et les politiques d'annulation",
        "termsandcondition": "termes et conditions",
        "EOMAmountPolicy": "J'accepte que le montant total à payer sera calculé à la fin du mois",
        "generalAgreement": "J'accepte de conclure un contrat avec T-Systems International GmbH en fonction de la description du service ci-dessus",
        "DPAAgreement": "Je consens à l'{dataprocessingagreement} (ATD)",
        "dataprocessingagreement": "Accord de traitement des données",
        "dataPackage": "Packages de données",
        "dataPlan": "Plan de données",
        "outgoingTraffic": "Trafic sortant inclus",
        "storagePrice": "Prix ​​de stockage",
        "perGigabyteForStorage": "/ Go pour le stockage",
        "perGigabyteForOutgoingTraffic": "/ Go pour le trafic sortant",
        "outgoingTrafficPrice": "Prix ​​du trafic sortant",
        "contractDuration": "Durée de contrat",
        "defaultContractDuration": "6 mois",
        "price": "Prix",
        "perGigabyte": "par Go",
        "monthlyCancelable": "résiliable mensuellement",
        "storage": "Rangement inclus",
        "linksText": {
          "information": "Fiche d'information",
          "service": "Description du service"
        },
        "plusCharges": "{plus} Frais de votre consommation",
        "plus": "plus",
        "basedOnConsumption": "en fonction de la consommation"
      },
      "cancelModal": {
        "confirmQuestion": "Êtes-vous sûr que vous souhaitez annuler la connexion sélectionnée?",
        "confirmYes": "Oui, annuler",
        "confirmNo": "Non, continuer"
      }
    },
    "statuses": {
      "complete": {
        "title": "Vous êtes déjà inscrit",
        "description": "Le lien d'enregistrement ne peut plus être utilisé.",
        "buttonText": "Aller vers la page d'accueil"
      },
      "error": {
        "title": "Quelque chose a mal tourné",
        "description": "Veuillez redémarrer le processus",
        "buttonText": "Redémarrage"
      },
      "expired": {
        "title": "Malheureusement, votre lien a expiré",
        "description": "Veuillez redémarrer le processus à partir du portail Catena-X.",
        "buttonText": "Retour à Catena-X"
      },
      "success": {
        "title": "Merci de votre inscription",
        "description": "Nous examinons les détails de votre organisation. Vous recevrez plus d'informations par e-mail.",
        "buttonText": "Aller sur notre site Web"
      },
      "invalid": {
        "title": "Non autorisé",
        "description": "Vous n'êtes pas autorisé à accéder à cette page.",
        "buttonText": "Aller sur notre site Web"
      },
      "dih-success": {
        "title": "Merci pour votre inscription",
        "description": "Nous examinons les détails de votre organisation. Vous recevrez plus d'informations par e-mail.",
        "buttonText": "Aller sur notre site Web"
      },
      "orgNotFound": {
        "title": "Nous n’avons pas trouvé votre organisation",
        "descriptionLine1": "Pardon! Il semble que votre organisation ne soit pas intégrée ou que vous n’ayez pas encore demandé d’abonnement aux services T-Systems.",
        "descriptionLine2": "Veuillez demander un abonnement aux services d’habilitation sur mesure et certifiés T-Systems pour Catena-X via la {marketplace}.",
        "marketplace": "marketplace",
        "descriptionLine3": "Si vous pensez qu’il s’agit d’une erreur, veuillez {contactOurSalesTeam} et nous serons heureux de vous aider.",
        "contactOurSalesTeam": "Contactez notre équipe commerciale",
        "exploreTheKnowledgeBase": "Explorez la base de connaissances",
        "readOurBlog": "Lire notre blog"
      }
    }
  },
  "dashboard": {
    "pageTitle": "Accueil",
    "pageSubtitle": "Ceci est votre centre de contrôle pour tout dans Data Intelligence Hub."
  },
  "services": {
    "finishConfig": "Configuration de la fin",
    "open": "Ouvrir",
    "viewDetails": "Afficher les détails",
    "configure": {
      "uploadCertification": "Veuillez télécharger le certificat qui vous a été envoyé par l'opérateur du Dataspace.",
      "dragAndDropCertificate": "Faites glisser et déposez votre fichier de certificat ici.",
      "didntReceive": "Vous n'en avez pas reçu ou vous ne savez pas comment en obtenir un? Suivez les étapes ici",
      "uploadSuccessMessage": "Certificat téléchargé avec succès"
    },
    "table": {
      "attribute": "Attribut",
      "value": "Valeur",
      "apiKey": "Clé API",
      "apiKeyValue": "Valeur de la clé d’API",
      "controlPlaneUrl": "URL du plan de contrôle",
      "idsURL": "Point de terminaison de l’API DSP",
      "connectorUrl": "URL du connecteur",
      "dataManagementUrl": "Point de terminaison de l’API de gestion des données",
      "dataDashboard": "Tableau de bord de données",
      "participantDid": "ID DID du participant"
    }
  },
  "dataspaces": {
    "dataspaces": "Dataspaces",
    "connectorTestDescription": "Application pour tester si votre connexion fonctionne comme prévu et si votre connexion à l'espace de données est active.",
    "viewAllDataspaces": "Afficher tous les espaces de données",
    "configure": {
      "pageTitle": "Configurer la connexion Dataspace",
      "basicInfo": "Informations de base",
      "certificate": "Certificat",
      "instructionCertrificate": "Veuillez télécharger le certificat que vous avez reçu de l'opérateur de Dataspace."
    },
    "connectorHealthCheck": {
      "title": "Vérification de l'état du connecteur",
      "copiedToClipboard": "Copié dans le presse-papier",
      "description": "Avec l'aide de cette application, un autotest est effectué pour vérifier si le connecteur que vous avez sélectionné est configuré et fonctionne comme prévu. Cela ne modifiera aucun paramètre ni stockage du connecteur sélectionné.",
      "connectorid": "ID du connecteur",
      "model": {
        "title": "Vérification de l'état du connecteur en cours...",
        "body": "Vous ne pouvez pas partir pendant que le bilan de santé est en cours. Si vous souhaitez partir, le bilan de santé sera interrompu et devra être relancé.",
        "toLeave": "Non, je veux partir",
        "toFinish": "Terminer le bilan de santé"
      },
      "banner": {
        "initiate": "L’exécution du contrôle de santé peut prendre quelques minutes. Vous serez averti dès que le résultat sera disponible.",
        "success": "Votre autotest Connect a réussi ! Pour afficher les résultats des tests antérieurs, cliquez sur Résultats ci-dessus.",
        "failed": "Désolé! Votre autotest Connect a échoué. Veuillez contacter {email} et nous serons heureux de vous aider.",
        "email": "dih_support{'@'}telekom.de"
      },
      "buttonState": {
        "initiate": "Lancer",
        "success": "Ouvrir la connexion",
        "failed": "Contactez le support",
        "back": "dos"
      }
    },
    "browse": {
      "browseDataspace": "Parcourir les données",
      "availableDS": "Dataspaces disponibles",
      "howToConnect": "Comment se connecter",
      "getConnected": "Se connecter",
      "requestConnection": "Demande de connexion",
      "viewRequest": "Voir la demande",
      "viewDetails": "Afficher les détails",
      "highlightNote": "Si vous souhaitez gérer votre abonnement avec {selectedDataspaceName}, veuillez contacter notre support:",
      "uncoverByInvitation": "Découvrir par invitation",
      "dataspaceDetails": "Détails de l'espace de données",
      "participantOverview": "Aperçu des participants",
      "services": "Services",
      "joinRequestSuccessTitle": "Votre demande d’adhésion au {dataspaceName} a été soumise",
      "joinRequestSuccessBody": "Vous obtiendrez de plus amples informations une fois votre demande traitée",
      "uncover": {
        "title": "Afficher un Dataspace par invitation",
        "subtitle": "Quel Dataspace recherchez-vous?",
        "fieldLabels": "Entrez les détails fournis par l'opérateur de Dataspace",
        "sendRequest": "Envoyer une demande",
        "back": "Retour",
        "dataspaceId": "ID de Dataspace",
        "password": "Mot de passe"
      },
      "requestDetailsModal": {
        "withdraw": "Retirer",
        "cancel": "Annuler",
        "update": "Mise à jour",
        "withdrawConfirmationHeading": "Retirer une demande de jointure à l’espace de données",
        "withdrawConfirmationBody": "Veuillez reconfirmer que vous avez l’intention de retirer votre demande d’adhésion à l’espace de données {dataspaceName}.",
        "notification": {
          "withdrawSuccessfulTitle": "Demande de jointure d’espace de données retirée",
          "withdrawSuccessfulBody": "Votre demande d’adhésion à {dataspaceName} a été retirée.",
          "updateSuccessfulTitle": "Mise à jour de la demande de jointure à l’espace de données",
          "updateSuccessfulBody": "Votre demande d’adhésion à {dataspaceName} a été mise à jour."
        }
      }
    },
    "user": {
      "myMemberships": "Mes adhésions",
      "enterDS": "Entrez l'espace de données",
      "registeredDS": "Vos Dataspaces",
      "noMembership": {
        "title": "Vous n'avez pas encore d'adhésion",
        "description": "Parcourir les données pour rejoindre un Dataspace",
        "actionButtonText": "Se connecter"
      }
    },
    "statuses": {
      "attentionRequired": "Attention requise",
      "comingSoon": "Disponible prochainement",
      "connected": "Connecté",
      "disconnected": "Déconnecté",
      "deploying": "Déploiement",
      "deployed": "Déployé",
      "failed": "Échoué",
      "available": "Disponible",
      "success": "Succès"
    }
  },
  "verify": {
    "orgProfile": "Profil d'organisation",
    "legalName": "Nom légal",
    "address": "Adresse",
    "contact": "Contact",
    "vatID": "numéro de TVA",
    "requestChange": "Demander un changement",
    "downloadVCMessage": "VC téléchargé avec succès",
    "vcOverview": {
      "masterHeading": "Types de références",
      "credentialTypeCards": {
        "description": {
          "legalPerson": "Il s'agit d'une représentation numérique des informations de l'entité juridique, telles que les détails d'enregistrement, l'identification de l'entreprise ou le statut juridique de l'organisation",
          "serviceOffering": "Il s'agit d'un ensemble d'actifs et de ressources qu'un Fournisseur combine dans un package pour offrir aux Consommateurs",
          "physicalResource": "Il s'agit d'une ressource avec un poids mesurable et une position spatiale, représentant une entité tangible qui sert d'hôte, de manipulateur ou de participant aux interactions avec d'autres entités physiques",
          "softwareResource": "Il s'agit d'un type d'actif qui comprend des fonctionnalités non physiques telles que le code source",
          "dataResource": "Il s'agit d'un type d'actif qui se compose de données (y compris y compris les données dérivées) sous n'importe quelle forme et comprend les informations nécessaires au partage des données"
        },
        "cta": {
          "enter": "Entrer"
        }
      },
      "credentialOverview": "Vue d'ensemble des informations d'identification",
      "credentialErrorMessage": "Erreur lors de la récupération des informations d'identification {type}",
      "searchByNamePlaceholder": "Recherche par nom",
      "credentialErrorDetailsMessage": "Actuellement, nous rencontrons des difficultés techniques lors de la récupération des informations d'identification, veuillez réessayer après un certain temps"
    },
    "verifiableCredentials": {
      "getVerified": "Être vérifié",
      "credentials": "Informations d'identification",
      "requestCredential": "Demander un certificat",
      "whichTypeToSelect": "Quel type dois-je sélectionner ?",
      "noCredentialsMessage": "Vous n'avez pas encore d'informations d'identification {type}",
      "credentialInfoMessage": "L'ajout d'identification augmentera votre niveau de confiance parmi les participants d'un écosystème.",
      "credentialModalMessage": "Vos références sont les qualifications ou des informations qui établissent l'identité, l'expertise ou la légitimité d'une entreprise.",
      "learnMore": "En savoir plus",
      "status": {
        "all": "Tous",
        "valid": "Valide",
        "expired": "Expiré",
        "revoked": "Révoqué"
      },
      "types": {
        "legalPerson": "Identification de l'entreprise",
        "serviceOffering": "Conformité et certification",
        "physicalResource": "Ressources matérielles",
        "dataResource": "Ressources de données",
        "softwareResource": "Ressources logicielles",
        "membership": "Adhésion"
      },
      "form": {
        "type": {
          "placeholder": "Choisissez votre type",
          "label": "Choisissez votre type",
          "error": "Choisissez votre type est requis"
        },
        "types": {
          "legalPerson": "Personne légal",
          "serviceOffering": "Offres de service",
          "physicalResource": "Ressource physique",
          "resource": "Ressource",
          "dataResource": "Ressource de données",
          "softwareResource": "Ressource logicielle"
        },
        "companyInformationSectionTitle": "Informations sur la société",
        "companyInformationSectionSubtitle": "Veuillez saisir les informations de votre entreprise",
        "legalName": {
          "label": "Nom légal de l'organisation",
          "helpText": "Veuillez utiliser le nom juridiquement contraignant de l'organisation"
        },
        "country": {
          "placeholder": "Choisissez le pays",
          "label": "Pays",
          "error": "Le pays est requis"
        },
        "region": {
          "placeholder": "Choisissez une région",
          "label": "Région",
          "error": "La région est obligatoire"
        },
        "street": {
          "label": "Rue et numéro de maison"
        },
        "city": {
          "label": "Ville"
        },
        "postalCode": {
          "label": "Code postal"
        },
        "commercialIdentificationSectionTitle": "Identification commerciale",
        "commercialIdentificationSectionSubtitle": "Entrez au moins l'un des ID uniques suivants pour continuer *",
        "CRN": {
          "label": "Numéro de registre commercial",
          "helpText": "Exemple: HRB 55933",
          "tooltipText": "Le numéro de registre commercial (CRN) est un identifiant unique attribué aux entreprises pour leur enregistrement commercial légal. Il est utilisé pour valider et vérifier l'authenticité des identifiants d'enregistrement et de transaction financière d'une entreprise contre les sources de données de confiance."
        },
        "vatId": {
          "label": "numéro de TVA",
          "helpText": "Exemple: DE 118 645 675",
          "tooltipText": "Un numéro de TVA (numéro d'identification fiscale à valeur ajoutée) est un identifiant unique attribué aux entreprises à des fins fiscales."
        },
        "eori": {
          "label": "EORI",
          "helpText": "Exemple: DE 103 280 493 572",
          "tooltipText": "EORI (numéro d'enregistrement et d'identification de l'opérateur économique) est un identifiant unique attribué aux opérateurs économiques engagés dans le commerce international au sein de l'Union européenne (UE)."
        },
        "lei": {
          "label": "LEI",
          "helpText": "Exemple: 5299009d9bil4d4uht93",
          "tooltipText": "LEI (identifiant d'entité juridique) est un code d'identification unique attribué aux entités juridiques engageant des transactions financières impliquées sur les marchés financiers mondiaux."
        },
        "didSectionTitle": "Identifiant décentralisé (DID)",
        "didSubSectionTitle": "Si vous n'avez pas de DID, veuillez laisser ce champ vide, il sera généré automatiquement.",
        "did": {
          "label": "Objet du certificat (DID)",
          "helpText": "did:example123456789abcd",
          "tooltipText": "Un DID est un identifiant unique qui peut être résolu en un document DID. Les DID sont les éléments fondamentaux de l'identité numérique décentralisée."
        },
        "request": "Demande",
        "requesting": "Demander...",
        "certificateSectionHeading": "Signature",
        "certificateSectionTitle": "Quel certificat souhaitez-vous utiliser pour signer?",
        "addCertificate": "Ajouter un nouveau certificat",
        "chooseCertificate": "Choisissez votre certificat",
        "tsystemsCertificate": "Certificat T-Systems",
        "yourCertificate": "Votre certificat",
        "consentTooltip": "En sélectionnant cette option, vous autorisez T-Systems à signer le document ci-dessus en votre nom. Pour plus de détails, veuillez consulter la documentation",
        "consentPersonalData": "Je suis d'accord et confirme par la présente que toutes mes informations personnelles et données (\"données personnelles\") indiquées ci-dessus sont exactes.",
        "consentProcessing": "J'autorise par la présente Gaia-X à traiter toutes mes données  (y compris toutes les données sensibles personnelles)."
      },
      "vcRequestSuccess": "Certificat signé avec succès",
      "selectAllButton": "Tout sélectionner",
      "deselectAllButton": "Tout déselectionner",
      "revokeButton": "Révoquer",
      "searchboxPlaceholder": "Entrez au moins 3 caractères",
      "credentialTile": {
        "revokeButton": "Révoquer",
        "issuedBy": "Délivré par",
        "issuedOn": "Publié le",
        "expiresOn": "Expire le",
        "expiredOn": "A expiré le",
        "revokedOn": "Révoqué",
        "viewDetailsButton": "Afficher les détails"
      },
      "revokeModal": {
        "heading": "Confirmer la révocation",
        "question": "Êtes-vous sûr de vouloir révoquer l'article?",
        "note": "Remarque:",
        "message": "Après avoir révoqué un diplôme, il devient invalide et ne peut plus être utilisé. Pour obtenir un diplôme valide, vous devrez en demander un nouveau.",
        "primaryButton": "Révoquer",
        "secondaryButton": "Annuler"
      },
      "credentialsDetailsModal": {
        "heading": "Détails du titre",
        "selfDescriptionCredential": "Informations d'auto-description",
        "type": "Veuillez taper",
        "id": "identifiant",
        "issuer": "émetteur",
        "issuanceDate": "émis",
        "expirationDate": "date d'expiration",
        "credentialSubject": "credentialSubject",
        "bpn": "CTXSD: BPN",
        "participantName": "GX-participant: nom",
        "participantNameLegalName": "GX-participant: nom légal",
        "legalRegistrationNumber": "GX: Participant: Legal RegistrationNumber",
        "EUID": "GX: EUID",
        "leiCode": "GX: Leicode",
        "vatID": "GX: Vatid",
        "registrationNumberType": "GX: Enregistrement",
        "registrationNumberNumber": "GX: Enregistrement",
        "headquarterAddress": "GX: Participant: le siège social",
        "addressCountryCode": "GX: AddressCountrycode",
        "countrySubdivisionCode": "gx:countrySubdivisionCode",
        "locality": "GX: localité",
        "streetAddress": "GX: StreetAddress",
        "legalAddress": "GX: Participant: LegalAddress",
        "termsAndConditions": "GX-participant: termes et conditions",
        "serviceOfferingName": "gx:serviceOfferingName",
        "serviceOfferingDescription": "gx:serviceOfferingDescription",
        "providedBy": "gx:providedBy",
        "aggregationOf": "gx:aggregationOf",
        "aggregationOfId": "gx:id",
        "policy": "gx:policy",
        "serviceOfferingTermsAndConditions": "gx:termsAndConditions",
        "url": "gx:URL",
        "hash": "gx:hash",
        "dataAccountExport": "gx:dataAccountExport",
        "requestType": "gx:requestType",
        "accessType": "gx:accessType",
        "formatType": "gx:formatType",
        "proof": "Preuve",
        "created": "créé",
        "proofPurpose": "proofPurpose",
        "verificationMethod": "verificationMethod",
        "jws": "jws",
        "status": "Statut",
        "primaryButton": "Télécharger",
        "secondaryButton": "Annuler",
        "resourceName": "gx:name",
        "resourceDescription": "gx:description",
        "resourceCopyrightOwnedBy": "gx:copyrightOwnedBy",
        "resourceExposedThrough": "gx:exposedThrough",
        "resourceProducedBy": "gx:producedBy",
        "resourcePolicy": "gx:policy",
        "resourceLicense": "gx:license",
        "resourceContainsPII": "gx:containsPII",
        "resourceMaintainedBy": "gx:maintainedBy",
        "resourceOwnedBy": "gx:ownedBy",
        "resourceManufacturedBy": "gx:manufacturedBy",
        "resourceCountryCode": "Indicatif du pays",
        "resourceLocality": "Localité",
        "resourcePostalCode": "Code postal",
        "resourceStreetAddress": "Adresse postale",
        "legalPersonRegistrationNumber": "gx:lagelRegistrationNumber"
      },
      "notificationText": "Désolé, nous n'avons trouvé aucun résultat correspondant à vos critères de recherche. Veuillez affiner votre recherche et réessayer.",
      "bulkUpload": {
        "title": "Valider vos partenaires",
        "helpText": "Validez vos partenaires commerciaux d'un seul coup en remplissant le modèle fourni",
        "uploadFileTitle": "Téléverser un fichier",
        "requestCredentials": "Demander des informations d'identification",
        "uploadFormHelpHeading": "Pour valider plusieurs entreprises, procédez de la manière suivante :",
        "uploadFormHelpText1": "Téléchargez le fichier de modèle à partir du lien ci-dessous",
        "uploadFormHelpText2": "Remplissez le fichier fourni avec les détails de vos partenaires commerciaux",
        "uploadFormHelpText3": "Déposez le fichier dans la zone de téléchargement ci-dessous",
        "downloadTemplateBtn": "Télécharger le modèle",
        "partnerValidationHelpText": "Si vous souhaitez en savoir plus sur la validation des partenaires, veuillez",
        "partnerValidationHelpLink": "Cliquez ici",
        "uploadFile": {
          "uploadFileHeading": "Sélectionnez un fichier CSV à télécharger"
        },
        "vcProcessingTitle": "Traitement des dossiers",
        "bulkVcContinueBtn": "Continuer",
        "issuingProgressMessage": "La délivrance des accréditations est en cours...",
        "doNotCloseWarning": "S'il vous plaît, ne fermez pas le formulaire tant que le traitement n'est pas terminé.",
        "bulkVcDownloadReportBtn": "Télécharger le rapport",
        "successMessage": "Complété avec succès!",
        "failedErrorMessage": "Erreurs dans les entrées {result}",
        "processAbortTitle": "Traitement",
        "processAbortDescription": "Êtes-vous sûr de vouloir arrêter le traitement?",
        "stopProcessingBtn": "Arrêter le traitement"
      }
    },
    "serviceOfferingForm": {
      "generalInformationHeading": "Informations générales",
      "labelSOName": "Nom de l'offre de service",
      "labelProvidedBy": "Fourni par",
      "labelSODescription": "Description de l'offre de service",
      "hintSOName": "Nom lisible par l'homme de l'offre de service",
      "hintProvidedBy": "Lien résoluble sous forme DID vers l'auto-description du participant fournissant le service",
      "hintSODescription": "Une liste de politiques exprimées à l'aide de DSL (par exemple, Rego ou ODRL, contrôle d'accès, limitation, utilisation, rétention, …)",
      "resourcesHeading": "Ressources",
      "labelSOResources": "Auto-description de la ressource",
      "hintSOResources": "Lien résoluble sous forme DID d'auto-description de ressource, qui est lié au service et peut exister indépendamment de celui-ci",
      "termsAndConditionsHeading": "Termes et conditions",
      "labelUrlSO": "URL",
      "labelHashSO": "Hash",
      "labelSOPolicy": "Politique",
      "policyTooltip": "Lien résolvable vers la Politique s'appliquant à l'Offre de service",
      "termsAndConditionsTooltip": "Lien résoluble vers les Conditions Générales applicables à l'Offre de Service",
      "personalDEHeading": "Exportation de données personnelles",
      "personalDETooltip": "Permet aux clients du service d'exporter des données personnelles et non personnelles hors du service.",
      "labelRequestTypeSO": "Type de demande",
      "labelAccessTypeSO": "Type d'accès",
      "placeholderRequestTypeSO": "Sélectionnez le type de demande",
      "placeholderAccessTypeSO": "Sélectionnez le type d'accès",
      "labelFormatTypeSO": "Type de format",
      "hintRequestTypeSO": "Le moyen de demander la récupération des données : API, email, formulaire Web, unregisteredLetter, approvedLetter, supportCenter",
      "hintAccessTypeSO": "Type de support de données : numérique, physique",
      "hintFormatTypeSO": "Type de types de médias (types MIME) tels que définis par",
      "consentTC1": "J'accepte et confirme par la présente que toutes les données saisies indiquées ci-dessus sont exactes",
      "consentTC2": "Par la présente, j'accepte et autorise T-Systems à traiter mes données (y compris toutes les données personnelles sensibles)",
      "consentTC3": "J'accepte par la présente le",
      "gaiaXTermsText": "Conditions générales de Gaia-X",
      "requestTypesList": {
        "api": "API",
        "email": "E-mail",
        "webform": "Formulaire Web",
        "unregisteredLetter": "Lettre non recommandée",
        "registeredLetter": "Lettre recommandée",
        "supportCenter": "Centre de soutien"
      },
      "requestTypesError": "Le type de demande est requis",
      "accessTypesList": {
        "digital": "Numérique",
        "physical": "Physique"
      },
      "accessTypesError": "le type d'accès est requis",
      "helpSOName": "Veuillez saisir un nom lisible par l'homme de votre service",
      "helpProvidedBy": "Identifiant décentralisé du Participant fournissant le service",
      "helpSOResources": "Identificateurs décentralisés des ressources exposées par le biais de ce service",
      "helpUrlSO": "Veuillez saisir le lien vers votre document CGV (par ex. https://service.com/terms)",
      "helpHashSO": "Veuillez saisir un hachage sha256 de votre document CGV",
      "helpPolicySO": "Veuillez saisir une politique exprimée à l'aide d'un DSL (par exemple, Rego ou ODRL)",
      "helpRequestTypeSO": "Veuillez sélectionner un type de demande dans la liste déroulante",
      "helpAccessTypeSO": "Veuillez sélectionner un type d'accès dans la liste déroulante",
      "helpFormatTypeSO": "Veuillez saisir le type MIME (par exemple, application/json)"
    },
    "resourceForm": {
      "generalInformationHeading": "Informations générales",
      "generalInformationSubHeading": "Veuillez entrer les informations générales de votre ressource",
      "labelResourceType": "Type de ressource",
      "placeholderResourceType": "Sélectionner le type de ressource",
      "errorResourceType": "Le type de ressource est requis",
      "labelResourceName": "Nom de la ressource",
      "labelResourceDescription": "Description de la ressource",
      "hintResourceType": "Type de ressource décrite",
      "hintResourceName": "Nom lisible par l'homme de la ressource",
      "hintResourceDescription": "Description lisible par l'homme de la ressource",
      "physicalResource": "Ressource physique",
      "softwareResource": "Ressource logicielle",
      "dataResource": "Ressource de données",
      "additionalInformationHeading": "Informations complémentaires",
      "additionalInformationSubHeading": "Veuillez saisir les informations supplémentaires sur votre ressource",
      "labelMaintainedBy": "Maintenu par",
      "hintMaintainedBy": "Lien résolvable vers Gaia-X Credential du participant maintenant la ressource en condition opérationnelle et ayant ainsi un accès physique à celle-ci",
      "labelOwnedBy": "Propriété de",
      "hintOwnedBy": "Lien résolvable vers Gaia-X Identifiant du participant propriétaire de la ressource",
      "labelManufacturedBy": "Fabriqué par",
      "hintManufacturedBy": "Lien résolvable vers Gaia-X Identifiant du participant fabriquant la ressource",
      "labelCountry": "Pays",
      "hintCountry": "Pays dans lequel se trouve la ressource",
      "labelCity": "Ville",
      "hintCity": "Ville où se trouve la ressource",
      "labelPostCode": "Code postal",
      "hintPostCode": "Code postal dans lequel se trouve la ressource",
      "labelStreet": "Rue",
      "hintStreet": "Numéro de maison et rue dans lesquels se trouve la ressource",
      "labelCopyrightOwnerBy": "Titulaire du droit d'auteur par",
      "hintCopyrightOwnerBy": "Un titulaire de droit d'auteur est une personne ou une organisation qui a le droit d'exploiter la ressource. Le titulaire du droit d'auteur ne fait pas nécessairement référence à l'auteur de la ressource, qui est une personne physique et peut différer du titulaire du droit d'auteur. Exprimé soit sous forme de texte libre, soit sous forme de lien résolvable vers Gaia-X Credential du propriétaire du droit d'auteur.",
      "labelLicense": "Licence",
      "hintLicense": "URL du document de licence ou de l'une des licences de la page",
      "labelPolicy": "Politique",
      "hintPolicy": "Une liste de politiques exprimées à l'aide d'un DSL (par exemple, Rego ou ODRL) (contrôle d'accès, limitation, utilisation, rétention, ...). Si la contrainte de stratégie est omise, il est supposé que l'utilisation est autorisée à tous les participants",
      "labelProducedBy": "Produit par",
      "hintProducedBy": "Lien résolvable vers l'identifiant Gaia-X du participant permettant légalement l'utilisation des données.",
      "labelExposedThrough": "Exposé à travers",
      "hintExposedThrough": "Lien résolvable vers le composant d'échange de données qui a exposé la ressource de données",
      "locationHeading": "Détails de l'emplacement",
      "locationSubHeading": "Veuillez saisir les détails de l'emplacement de votre ressource",
      "licenseTooltipText": "Liste des licences SPDX",
      "licenseTooltipLink": "https://spdx.org/licenses/",
      "consentPII": "Je confirme par la présente que la ressource de données ne contient pas d'informations personnelles identifiables (PII)."
    },
    "verifyLanding": {
      "heading": "Bienvenue à “Get verified”",
      "subHeading": "Augmentez la confiance de votre écosystème numérique !",
      "description1": "Vous souhaitez vérifier et valider vos partenaires commerciaux, ou vous souhaitez présenter vos propres identifications et vérifications ? Ensuite, vous devez demander vos premières informations d'identification vérifiables ‚Äì nos informations numériques. Produit d'identification.",
      "description2": "Les informations d'identification vérifiables peuvent contenir les mêmes informations que celles de vos identités physiques (par exemple carte d'identité, permis de conduire) et encore plus d'attributs. L'ajout de technologies, telles en tant que signatures numériques, rend les informations d'identification vérifiables plus inviolables et plus fiables que leurs homologues physiques.",
      "description3": "T-Systems fonctionne comme l'un des premiers nœuds de compensation numérique Gaia-X et émet vos preuves numériques conformément aux spécifications du Gaia-X Trust Framework. De plus, nous ajoutons notre signature numérique conforme eIDAS de notre partenaire Telekom Security.",
      "requestButton": "Demander Digital.ID",
      "digitalID": "Digital.ID produit.",
      "consultation" : {
        "heading": "Demandez vos identifiants ou validez vos partenaires",
        "placeholder": "Avez-vous une expérience antérieure avec des informations d'identification vérifiables ? Intéressé par vos premiers diplômes ?"
      },
      "sendAnotherRequest": "Envoyer une autre demande",
      "lastRequest": "Votre dernière demande a été envoyée le"
    },
    "getInsights": {
      "tab1": "Rapports",
      "tab2": "Limites d'utilisation de l'API",
      "tab3": "Intégration technique",
      "monthsByOptions": {
        "option1": "3 mois",
        "option2": "6 mois",
        "option3": "1 mois"
      },
      "table": {
        "heading2": "Période de référence",
        "heading3": "Créé à",

        "heading4": "Actions"
      },
      "successDownload": "Rapport(s) téléchargé(s) avec succès",
      "errorDownload": "Le téléchargement de votre fichier pour {filename} a été interrompu avec une erreur. Veuillez réessayer.",
      "apiUsageLimit": {
        "requestNameTitle": "Nom de la demande",
        "dailyTitle": "Requêtes API quotidiennes",
        "monthlyTitle": "Requêtes API mensuelles",
        "limitexhaustedWarning": "Moins de 10 % des demandes restantes",
        "limitExtentionHeading": "Demander une extension de limite",
        "limitExtentionPlaceholder": "Intéressé par l'extension de vos limites d'utilisation de l'API ?",
        "supportBtn": "Soutien",
        "limitExtentionText": "Sur le point d'atteindre la limite ? Contactez-nous et réglons cela !"
      },
      "addIntegration": "Ajouter une intégration",
      "integrationData": {
        "clientId": "Client ID",
        "createdAt": "Créé à",
        "actions": "Actions"
      }
    },
    "addIntegration": {
      "generalInformationHeading": "Informations générales",
      "addAnIntegration": "Ajouter une intégration",
      "clientName": "Nom du client",
      "clientId": "ID client",
      "clientSecret": "Secret client",
      "tokenUrl": "URL du jeton",
      "pcCallbackUrl": "URL de rappel des identifiants des participants",
      "soCallbackUrl": "URL de rappel des identifiants de l'offre de service",
      "digitalIdAuthenticateInfo1": "Comment la Validation de Digital.ID s'authentifiera-t-elle contre votre système?",
      "digitalIdAuthenticateInfo2": "Comment la Conformité de Digital.ID s'authentifiera-t-elle contre votre système?",
      "seeResults": "Où devons-nous envoyer les résultats?",
      "requestSuccess": "ajouté avec succès",
      "callbackSuccess": "Callback ajouté avec succès",
      "step1Name": "Détails d'authentification de validation",
      "step2Name": "Détails d'authentification de conformité",
      "step3Name": "Informations de rappel",
      "deleteSuccess": "Supprimé avec succès",
      "downloadSuccess": "Téléchargé avec succès",
      "editCallbackHeading": "Ajouter un rappel",
      "deleteModal": {
        "heading": "Supprimer la confirmation",
        "deleteContent": "Êtes-vous sûr de vouloir supprimer le client ?",
        "deleteNote": "Après avoir supprimé un client, il disparaîtra de la liste et ne pourra plus être utilisé. Pour le restaurer, vous devrez recréer à partir de zéro."
      }
    },  
    "mySignatures": {
      "certificatesOrdering": {
        "heading": "Bienvenue dans \"Mes signatures\"",
        "subHeading": "Vous souhaitez demander un certificat numérique qui vous permette de signer vos revendications ?",
        "description1": "Avec notre produit Digital.ID, vous pouvez facilement demander votre premier certificat et commencer à profiter des avantages des collaborations numériques sécurisées.",
        "description2": "Les informations d'identification vérifiables peuvent contenir les mêmes informations que vos identifiants physiques, tels que les cartes d'identité et les permis de conduire, et bien plus encore. Au cours du processus d'émission, vous confirmerez l'exactitude de vos informations en les signant avec votre propre certificat digne de confiance.",
        "description3": "Vous pouvez demander et maintenir vos certificats numériques en toute sécurité pour tous vos besoins de signature. Nous travaillons exclusivement en partenariat avec des fournisseurs de certificats numériques certifiés, garantissant que vos certificats numériques sont à la fois sécurisés et dignes de confiance.",
        "requestButton" : "Demander un certificat",
        "consultation" : {
          "heading": "Demander un certificat",
          "placeholder": "Veuillez saisir les détails nécessaires à votre demande"
        }
      },
      "certificateOverview" : {
        "heading" : "Mes signatures",
        "requestCertificateCTA" : "Demander un certificat",
        "placeholderCertificateType" : "Type de certificat",
        "placeholderCertificateStatus" : "Statut du certificat",
        "EVSSL" : "EV SSL",
        "EIDAS" : "EIDAS",
        "headingEVSSL" : "Certificats SSL EV",
        "headingEIDAS" : "Certificats EIDAS",
        "certificateDetailsHeading" : "Détails du certificat",
        "certificateName" : "Nom",
        "certificateId" : "ID",
        "certificateType" : "Type",
        "provider" : "Prestataire",
        "organizationId" : "ID de l'organisation",
        "UUID" : "UUID",
        "certificate" : "Certificat",
        "createdAt" : "Créé le",
        "updatedAt" : "Mis à jour le",
        "certificateExpiry" : "Expire le",
        "certificateVerificationMethod" : "Méthode de vérification",
        "certificateDidJson" : "DID JSON",
        "status" : "Statut",
        "certificateDownloadSuccess" : "Certificat téléchargé avec succès",
        "deleteCertificate" : {
          "heading" : "Supprimer la confirmation",
          "question" : "Êtes-vous sûr de vouloir supprimer le certificat ?",
          "deleteSuccess" : "Certificat supprimé avec succès"
        }
      }
    }
  },
  "build": {
    "ownedDataspaces": "Mes espaces de données",
    "checkConsent": "Je consentement à mes données de contact fournies à une équipe de vente T-Systems et à être contacté.",
    "welcomeOwnedDataspace": "Bienvenue dans «Mes Dataspaces»",
    "resendInvitation": "Renvoyer l’invitation",
    "resendInvitationInfo": "Vous pouvez envoyer la notification dès que 24 heures se sont écoulées depuis le dernier e-mail d’invitation.",
    "requestSuccessTitle": "Demande réussie!",
    "requestSuccessBody": "Votre demande a bien été envoyée... Vous recevrez une réponse dans les jours ouvrables",
    "requestAuthorisation": "Veuillez contacter dih.support{'@'}telekom.de si vous souhaitez augmenter l'autorisation nécessaire.",
    "inviteParticipant": {
      "buttonLabel": "Inviter un participant",
      "successNotificationTitle": "Invitation réussie !",
      "successNotificationContent": "Votre invitation a été envoyée avec succès",
      "alreadySentNotificationTitle": "Une invitation a déjà été envoyée à cette adresse e-mail.",
      "alreadySentNotificationContent": "Veuillez attendre 24 heures avant d’envoyer à nouveau l’invitation.",
      "resendInviteNotificationTitle": "Ré-invitation réussie!",
      "resendInviteNotificationContent": "Votre réinvitation a été envoyée avec succès",
      "modal": {
        "title": "Inviter un participant",
        "informationTitle": "Information",
        "informationText": "L’organisation invitée peut échanger des données avec d’autres participants et publier de nouvelles ressources de données dans l’espace de données. De plus, l’organisation peut consommer des applications et des services qui font partie de votre espace de données.",
        "formTitle": "Veuillez saisir les coordonnées du nouveau participant",
        "organizationName": "Nom de l’organisation",
        "proceed": "Procéder",
        "cancel": "Annuler"
      },
      "deleteInvitation": {
        "modal": {
          "title": "Supprimer l’invitation",
          "informationText": "Veuillez confirmer à nouveau que vous avez l’intention de supprimer cette invitation à l’espace de données. Cette action ne peut pas être annulée.",
          "successNotificationTitle": "Suppression réussie",
          "successNotificationBody": "L’invitation a été supprimée avec succès",
          "cancel": "Annuler",
          "delete": "Supprimer"
        }
      }
    },
    "editParticipant": {
      "modal": {
        "title": "Modifier le participant",
        "cancel": "Annuler",
        "save": "Sauvegarder",
        "roleSection": {
          "name": "Rôle",
          "helpText": "Quel rôle dois-je choisir ?",
          "helpUrl": "https://docs.dih.telekom.com/en/space/living-lab/manage-your-livinglab",
          "ownerLabel": "Propriétaire de l'espace de données",
          "ownerTooltip": "Propriétaire de l'espace de données pouvant gérer les participants et les applications",
          "operatorLabel": "Opérateur de l'espace de données",
          "operatorTooltip": "Participant pouvant gérer l'outil de l'opérateur et intégrer des applications",
          "operatorCheckboxTooltip": "Vous êtes le propriétaire de l'espace de données. Par conséquent, vous ne pouvez pas désélectionner Opérateur.",
          "appProviderLabel": "Fournisseur d'applications",
          "appProviderTooltip": "Participant de l'espace de données pouvant intégrer des applications dans l'espace de données",
          "participantLabel": "Participant de l'espace de données",
          "participantTooltip": "Participant pouvant agir en tant que fournisseur et consommateur de données",
          "participantCheckboxTooltip": "DLe participant Ataspace ne peut pas être désactivé. C’est le statut par défaut d’un utilisateur dans un espace de données."
        },
        "visibilitySection": {
          "name": "Visibilité",
          "helpText": "Quelle visibilité dois-je choisir ?",
          "helpUrl": "https://docs.dih.telekom.com/en/space/living-lab/manage-your-livinglab",
          "anonymousLabel": "Anonyme",
          "anonymousLabelTitle": "Participant anonyme",
          "anonymousTooltip": "Participant qui est masqué des autres participants et qui n’est pas en mesure de voir les autres participants"
        }
      },
      "updateSuccessfulTitle": "Mise à jour réussie !",
      "updateSuccessfulBody": "De nouveaux rôles et une nouvelle visibilité ont été adoptés"
    },
    "applicationManagement": {
      "buttonLabel": "Enregistrer la demande",
      "registerForm": {
        "pageTitle": "Enregistrement de l’application",
        "step1Name": "Détails de l’application",
        "step2Name": "Accès à l’application",
        "displayName": "Nom d’affichage",
        "providedBy": "Fourni par",
        "providedByPlaceholder": "Nom de l’organisation",
        "description": "Description",
        "descriptionPlaceholder": "Texte de description",
        "link": "Link",
        "linkPlaceholder": "https://www.application-link-example.org/",
        "agreementCheckboxLabel": "Par la présente, je consens à être entièrement responsable de l’application que j’enregistre et à ce qu’il ne s’agisse pas d’un logiciel malveillant d’aucune sorte. De plus, j’ai veillé à ce qu’il n’y ait pas de violation de licence. Je comprends que je suis entièrement responsable de la gestion de l’accès à l’application et que T-Systems International GmbH n’assume aucune responsabilité à cet égard. T-System International GmbH n’est pas responsable de l’application et de ses conséquences possibles de quelque manière que ce soit."
      },
      "deleteApplication": {
        "modal": {
          "title": "Supprimer l’application",
          "informationText1": "Veuillez reconfirmer que vous êtes sûr de vouloir supprimer ce lien de candidature de votre espace de données. Cette candidature ne sera plus disponible pour aucun participant de votre espace de données.",
          "informationText2": "Veuillez noter que l'application proprement dite ne sera pas affectée!",
          "successNotificationTitle": "Suppression réussie",
          "successNotificationBody": "Application supprimée avec succès",
          "cancel": "Annuler",
          "delete": "SupprimerTranslation"
        }
      },
      "editForm": {
        "pageTitle": "Enregistrement de l’application",
        "step1Name": "Détails de l’application",
        "step2Name": "Accès à l’application",
        "displayName": "Nom d’affichage",
        "providedBy": "Fourni par",
        "providedByPlaceholder": "Nom de l’organisation",
        "description": "Description",
        "descriptionPlaceholder": "Texte de description",
        "link": "Link",
        "successNotificationTitle": "Édition réussie",
        "successNotificationBody": "La demande a été éditée avec succès",
        "linkPlaceholder": "https://www.application-link-example.org/",
        "agreementCheckboxLabel": "Par la présente, je consens à être entièrement responsable de l’application que j’enregistre et à ce qu’il ne s’agisse pas d’un logiciel malveillant d’aucune sorte. De plus, j’ai veillé à ce qu’il n’y ait pas de violation de licence. Je comprends que je suis entièrement responsable de la gestion de l’accès à l’application et que T-Systems International GmbH n’assume aucune responsabilité à cet égard. T-System International GmbH n’est pas responsable de l’application et de ses conséquences possibles de quelque manière que ce soit.",
        "cancel": "Annuler",
        "save": "Enregistrer les modifications"
      }
    },
    "invite": {
      "inviteLabel": "Inviter un participant"
    },
    "allocateConnectors": {
      "pageTitle": "Allocation de connecteur",
      "allocateConnectorLabel": "Allouer des connecteurs",
      "topUpConnectorsButtonLabel": "Connecteurs de recharge",
      "topUpConnectorTitle": "Votre demande a été soumise avec succès.",
      "topUpConnectorBody": "Notre équipe d’assistance vous contactera dans les plus brefs délais pour discuter de plus amples détails.",
      "generalTooltipMessage": "Veuillez contacter le support DIH (dih_support{'@'}telekom.de) pour allouer de nouveaux connecteurs aux participants existants ou pour inviter un nouveau participant dans l’espace de données.",
      "tooltipMessage": "Vous avez déjà atteint le nombre maximum de connecteurs disponibles selon votre contrat. Veuillez contacter dih.support{'@'}telekom.de si vous souhaitez augmenter le quota ou modifier le contrat.",
      "allocationForm": {
        "step1Name": "Organisation",
        "step2Name": "Connecteurs",
        "organizationNameDescription": "Sélectionnez l’entreprise à laquelle les nouveaux connecteurs doivent être attribués",
        "organizationNameLabel": "Nom de l’organisation",
        "organizationPlaceholder": "Choisir l’organisation",
        "inviteParticipantDescription": "Ou cliquez sur le bouton Inviter un participant pour inviter une nouvelle entreprise à collaborer, sur l’espace de données",
        "inviteParticipantButton": "Inviter un participant",
        "numberOfConnectorsDescription": "Choisissez le nombre de connexions",
        "numberOfConnectorsLabel": "Nombre de connecteurs",
        "numberOfConnectorsPlaceholder": "Compter",
        "progressBarDescription": "Quota de connecteur",
        "progressBarInfo": "{numberOfConnectors} connecteurs disponibles",
        "allocateButtonLabel": "Allouer",
        "cancelButtonLabel": "Annuler",
        "noConnectorRemaining": "Tous les connecteurs sont utilisés. Veuillez cliquer sur \"Connecteurs de recharge\" pour discuter de l’extension des connecteurs avec notre équipe."
      },
      "successRequestTest": "Demande réussie",
      "successRequestBody": "L’allocation de connecteur a été lancée avec succès",
      "decisionModal": {
        "title": "Vous avez été invité(e) à rejoindre {dataspaceName}",
        "accept": "Accepter",
        "decline": "Décliner",
        "acceptSuccessNotificationTitle": "Acceptation réussie !",
        "acceptSuccessNotificationBody": "Vous avez accepté avec succès l’invitation à rejoindre {dataspaceName}.",
        "declineSuccessNotificationTitle": "Invitation refusée !",
        "declineSuccessNotificationBody": "Vous avez décliné l’invitation à rejoindre {dataspaceName}."
      }
    },
    "request": {
      "requestTitle": "Faites la prochaine étape sur le parcours de partage de données et créez votre propre Dataspace!",
      "requestDescription1": "Vous souhaitez non seulement partager vos propres données, mais aussi permettre à vos partenaires commerciaux de partager les données les uns avec les autres? Ensuite, vous devriez envisager de créer une salle de données, qui est une communauté de confiance pour partager ou monétiser les données.",
      "requestDescription2": "T-Systems configurera et exploitera votre Dataspace sur notre infrastructure fiable.",
      "requestDescription3": "Nous proposons également des configurations de test à faible seuil pour vos premières expériences de Dataspace („LivingLab“).",
      "request": "Demande",
      "sendAnotherRequest": "Envoyer une autre demande",
      "lastRequest": "Votre dernière demande a été envoyée",
      "back": "Retour",
      "sendRequest": "Envoyer une demande",
      "requestedTextPlaceholder": "Vous recherchez quelque chose de spécifique ou de consultation générale? Une expérience antérieure avec DataSpaces? Intéressé par une configuration de test?"
    },
    "create": {
      "createTitle": "Créez votre propre Dataspace",
      "createDesc": "Vous pouvez envoyer une demande à notre équipe d'experts et nous vous répondrons.",
      "tellUsMore": "Pouvez-vous nous en dire plus sur votre demande?"
    },
    "details": {
      "approve": "Approuver",
      "decline": "Décliner",
      "approval": "Approbation",
      "requesterNotes": "Notes du demandeur",
      "adminNotes": "Notes de l’administrateur",
      "decisionTakenTooltip": "L’acceptation ou le refus a déjà eu lieu. La mise à disposition des connecteurs est en cours de préparation. Vous serez informé dès que celles-ci auront été remplies.",
      "decisionInputPlaceholder": "Notes d’approbation ou de refus ...",
      "activeParticipants": "Participants actifs",
      "incomingRequests": "Demandes entrantes",
      "invitations": "Invitations",
      "manage": "Gérer",
      "enter": "Entrer",
      "providedBy": "Fourni par",
      "registered": "Inscrit",
      "operatorToolkit": "Boîte à outils de l'opérateur",
      "dataspaceDetails": "Détails du Dataspace",
      "contactDetails": "Coordonnées",
      "visibilityTitle": "Masquer votre espace de données de la liste des espaces de données de navigation",
      "manageParticipants": "Gérer les participants",
      "applicationManagement": "Gestion des applications",
      "noApplications": {
        "title": "Vous n’avez pas encore de candidature",
        "actionButtonText": "Enregistrer la demande"
      },
      "contactUs": "Si vous souhaitez mettre à jour des informations, veuillez nous contacter directement",
      "noData": "Vous n'avez pas encore de données",
      "basic": "Basique",
      "companyName": "Nom de l'entreprise",
      "participationMode": "Mode de participation",
      "connectorName": "Nom du connecteur",
      "contact": "Contact",
      "address": "Adresse",
      "street": "Rue",
      "number": "Nombre",
      "postalCode": "Code postal",
      "city": "Ville",
      "country": "Pays",
      "contactPerson": "Personne de contact",
      "phoneNo": "Phone number",
      "email": "E-mail",
      "dateOfJoining": "Date d'adhésion",
      "dateOfInvitation": "Date de l’invitation",
      "dateOfRequest": "Date de la demande",
      "url": "URL",
      "status": "Statut",
      "bpn": "Numéro de partenaire commercial",
      "name": "Nom",
      "description": "Description",
      "website": "Site web",
      "dataspaceId": "ID de Dataspace",
      "password": "Mot de passe",
      "digitalId": "Cet espace de données est compatible avec Digital.ID",
      "disableDigitalId": "Digital.ID n'est pas activé pour cette Dataspace",
      "company": "Compagnie | Entreprises",
      "connector": "Connecteur | Connecteurs",
      "application": "Application | Applications",
      "dataoffer": "Offre de données | Offres de données",
      "visibility": "Votre Dataspace est maintenant {Access}",
      "discoverable": "Découvrable",
      "private": "Privé",
      "deleteParticipant": {
        "modal": {
          "title": "Supprimer le participant",
          "informationText": "Le connecteur appartenant au participant sera dédéployé lors de la confirmation. Cette action ne peut pas être annulée.",
          "successNotificationTitle": "Suppression Demandée",
          "successNotificationBody": "La suppression d'un participant a été demandée",
          "cancel": "Annuler",
          "delete": "Supprimer"
        }
      },
      "services": {
        "applicationOrchestration": "Orchestration des applications",
        "applicationOrchestrationDesc": "Déployez et gérez vos applications d'entreprise",
        "identityAccessManagement": "Gestion de l'identité et de l'accès",
        "identityAccessManagementDesc": "Gérez vos utilisateurs et leur accès aux ressources",
        "continuousDelivery": "Livraison continue",
        "continuousDeliveryDesc": "Adoptez les meilleures pratiques de déploiement et d'opérations",
        "secretCredentialManagement": "Gestion secrète et des diplômes",
        "secretCredentialManagementDesc": "Sécuriser, stocker et contrôler l'accès aux jetons, aux mots de passe et aux certificats",
        "monitoring": "Surveillance",
        "monitoringDesc": "Interroger, visualiser, être alerté et comprendre vos mesures et vos KPI",
        "connectorTest": "Vérification de la santé du connecteur",
        "connectorTestDesc": "Service pour exécuter une vérification de la santé des connecteurs.",
        "catenaXSandbox": "Catena-X Sandbox",
        "catenaXSandboxDesc": "Démarrez votre bac à sable Catena-X dans votre LivingLab pour développer et tester vos nouvelles applications métier dans l’environnement Catena-X."
      }
    },
    "connectorHealthCheck": {
      "title": "Vérification de la santé du connecteur",
      "viewResults": "Voir les résultats",
      "close": "Fermer",
      "description": "Sélectionnez un ou plusieurs connecteurs sur lesquels vous souhaitez effectuer une vérification de la santé. Les opérations de l'espace de données standard ne sont pas affectées par la vérification de la santé.",
      "connectors": "Connecteurs",
      "placeholder": "Sélectionnez des connecteurs",
      "searchInputLabel": "Rechercher des connecteurs...",
      "banner": "La vérification de la santé peut prendre quelques minutes. Vous serez informé dès que les résultats seront disponibles.",
      "action": "Initier",
      "history": {
        "connectorName": "Nom du connecteur",
        "initiated": "Initié",
        "completed": "Terminé",
        "result": "Résultat",
        "successMessage": "{connectorName} fonctionne comme prévu",
        "unsuccessfulMessage": "{connectorName} ne fonctionne pas comme prévu. Veuillez contacter "
      },
      "successNotificationTitle": "Vérification de l'état du connecteur lancée.",
      "successNotificationContent": "Vous serez informé lorsque le résultat sera disponible.",
      "noTest": "Vous n'avez pas encore de tests",
      "initiateTest": "Lancer le test"
    },
    "specifications": {
      "table": {
        "attribute": "Attribut",
        "value": "Valeur",
        "cloudProvider": "Fournisseur de cloud",
        "contractStart": "Début du contrat",
        "contractDuration": "Durée du contrat",
        "cpu": "CPU",
        "ram": "RAM",
        "storage": "Stockage",
        "maximumConnectorsBookable": "Connecteurs maximums réservables",
        "subscribedConnectors": "Connecteurs souscrits"
      },
      "dataspaceSpecifications": "Spécifications de l'espace de données",
      "livinglabCore": "Noyau de LivingLab",
      "livinglabAppLayer": "Couche d'application de LivingLab"
    }
  },
  "profile": {
    "profile": "Profil",
    "userProfile": "Profil de l'utilisateur",
    "personalInfo": "Informations personnelles",
    "personalDetails": "Détails personnels et paramètres",
    "changeDetailsMessage": "Si vous souhaitez modifier des détails, veuillez contacter",
    "changeDetailsLinkText": "Assistance",
    "memberships": "Adhésion",
    "security": "Sécurité",
    "setUp2FA": "Configurer 2FA",
    "accountInformation": "Information sur le compte",
    "deleteAccount": "Supprimer le compte utilisateur",
    "deleteAccountDescription": "Avec cette action, tous les fichiers de données et de données associés à votre compte personnel seront supprimés.",
    "accountSecurity": "Sécurité du compte",
    "yourInfomration": "Vos informations",
    "fullName": "Nom et prénom",
    "contact": "Contact",
    "language": "Langue",
    "changePassword": "Changer le mot de passe",
    "please": "Veuillez",
    "contactSupport": "Contactez le support",
    "toDownload": "Pour télécharger ces informations",
    "toDelete": "Pour supprimer votre profil",
    "roles": "Les rôles",
    "memberSince": "Membre depuis",
    "orgLegalName": "Nom légal de l'organisation",
    "bpnNumber": "Numéro de partenaire commercial (BPN)",
    "commRegNumber": "Numéro de registre commercial",
    "vatIdNumber": "numéro de TVA",
    "hqAddress": "Adresse du siège",
    "legalIdentifier": "Identifiant légal",
    "additionalAttributes": "Attributs supplémentaires"
  },
  "plansBenefits": {
    "communicationIntegrity": "Intégrité de la communication",
    "protectedConnection": "Connexion protégée",
    "mutualAuth": "Authentification mutuelle",
    "stateArtOfCrypto": "Cryptographie de pointe",
    "remoteAttestation": "Attestation à distance",
    "platforInt": "Intégrité de la plate-forme",
    "configureAndApp": "Configuration et intégration des applications",
    "dataUsageControl": "Contrôle d'utilisation des données",
    "definitionOfUsagePolicy": "Définition de la politique d'utilisation",
    "from": "depuis",
    "month": "mois",
    "connect": "Connecter"
  },
  "cookieSettings": {
    "collapsedTitle": "Paramètres de confidentialité",
    "collapsedText": "Ce site Web utilise des cookies et des technologies similaires. Ce sont de petits fichiers texte qui sont stockés et lus sur votre ordinateur. En cliquant sur '{0}', vous acceptez le traitement, la création de profils utilisateur individuels sur les sites Web et les partenaires, ainsi que la transmission de vos données à des tiers, dont certains traitent vos données dans des pays en dehors de l'Union européenne (RGPD Art. 49). Les détails se trouvent dans la section 2 de la {1}. Les données sont utilisées à des fins d'analyse, de reciblage et d'affichage de contenu et de publicité personnalisés sur les sites Telekom et les sites tiers. De plus amples informations, y compris des informations sur le traitement des données par des fournisseurs tiers et la possibilité de révocation, se trouvent dans les paramètres et dans notre politique de confidentialité. Ici, vous pouvez {2} uniquement avec les outils nécessaires.",
    "extendedTitle": "Paramètres de confidentialité",
    "extendedText": "Afin de vous offrir une expérience optimale sur notre site Web, nous utilisons des cookies. Ceux-ci incluent des cookies pour le fonctionnement et l'optimisation du site, ainsi que pour des services tels que le chat texte ou vidéo et la publicité basée sur votre comportement en ligne. Cela nous permet, par exemple, de détecter si vous visitez nos pages à plusieurs reprises à partir du même appareil. Nous aimerions vous donner le choix des cookies que vous autorisez :",
    "onlyRequired": "Seulement requis",
    "policyLinkHref": "https://dih.telekom.com/en/privacy-policy",
    "policyLinkCookieHref": "https://dih.telekom.com/en/privacy-policy",
    "policyLinkTextShort": "informations de confidentialité",
    "policyLinkText": "Informations de confidentialité",
    "rejectLinkText": "continuer",
    "acceptButton": "Accepter tout",
    "saveButton": "Enregistrer les paramètres",
    "settingsButton": "Modifier les paramètres",
    "showMore": "Montrer plus",
    "showLess": "Montrer moins",
    "categoryTitleEssential": "Cookies requis",
    "categoryTitleAnalytics": "Cookies analytiques",
    "categoryTitleMarketing": "Cookies marketing",
    "categoryTitleThirdParty": "Services d'autres sociétés (fournisseurs de tiers indépendants)",
    "categoryTextEssential": "Ces cookies sont nécessaires pour vous permettre de naviguer sur les sites Web et d'utiliser des fonctions essentielles. Ils prennent en charge des fonctions de base, telles que le traitement des commandes dans la boutique en ligne et l'accès aux zones sécurisées du site Web. Ils servent également à effectuer une analyse anonyme des modèles d'utilisation, que nous utilisons pour développer et améliorer continuellement nos pages Web.",
    "categoryTextAnalytics": "Ces cookies nous aident à améliorer notre compréhension du comportement des utilisateurs. Les cookies d'analyse permettent la compilation de données d'utilisation et d'identification par le fournisseur d'origine ou par des fournisseurs tiers en profils d'utilisation pseudonymes. Nous utilisons des cookies d'analyse, par exemple, pour déterminer le nombre de visiteurs individuels sur une page Web ou un service, pour collecter des données statistiques sur les performances de nos produits et pour analyser les modèles d'utilisation et les interactions des visiteurs sur la base d'informations anonymes et pseudonymes. Ces informations ne peuvent pas être reliées à une personne.",
    "categoryTextMarketing": "Ces cookies et technologies similaires sont utilisés pour permettre l'affichage de contenu marketing personnalisé et donc pertinent. Les cookies marketing sont utilisés pour afficher un contenu publicitaire intéressant et mesurer l'efficacité de nos campagnes. Cela se produit non seulement sur les sites Web Telekom, mais aussi sur d'autres sites de partenaires publicitaires (fournisseurs tiers). Ceci est également appelé reciblage. Il est utilisé pour créer du contenu pseudonyme ou des profils publicitaires, pour placer la publicité pertinente sur d'autres sites Web et pour dériver des informations sur les groupes cibles qui ont visionné les annonces et le contenu. Ces informations ne peuvent pas être retracées à une personne. Les outils de marketing et de reciblage nous aident à vous servir de contenu publicitaire potentiellement pertinent pour vous. En supprimant les cookies marketing, vous verrez toujours la même quantité de publicité, mais cela peut être moins pertinent pour vous.",
    "categoryTextThirdParty": "Les sites Internet de Telekom comportent des liens vers des fournisseurs de services tiers, qui proposent leurs services sous leur propre responsabilité. Lorsque vous consultez un site Internet de Telekom, des cookies ou des technologies similaires enregistrent des données et les transmettent à des tiers, en partie pour répondre à des objectifs propres à Telekom. L'étendue, l'objectif et la base juridique du traitement ultérieur effectué à des fins propres à la tierce partie sont indiqués dans les informations sur la confidentialité des données de ladite tierce partie. Des informations relatives aux fournisseurs tiers indépendants sont disponibles dans les informations sur la confidentialité des données."
  },
  "consultation": {
    "createDescription": "Vous pouvez envoyer une demande à notre équipe d'experts et nous vous répondrons.",
    "tellUsMore": "Pouvez-vous nous en dire plus sur votre demande ?",
    "checkConsent": "J'accepte que mes coordonnées soient fournies à une équipe commerciale de T-Systems et que je sois contacté.",
    "back": "Dos",
    "sendRequest": "Envoyer une demande",
    "successMsgtitle": "Demande réussie !",
    "successMsgBody": "Votre demande a bien été envoyée... vous recevrez une réponse dans quelques jours ouvrés"
  },
  "notifications": {
    "notifications": "Notifications",
    "inbox": "Boîte de réception",
    "clearAll": "Tout effacer",
    "blankState": {
      "heading": "Aucune notification pour le moment",
      "description": "Lorsque vous recevrez des notifications, ils apparaîtront ici",
      "actionButtonText": "Rafraîchir"
    },
    "actionMessage": {
      "seeRequest": "Voir la demande",
      "checkResult": "Vérifier le résultat ",
      "here": "ici"
    },
    "goToMyAccount": "Veuillez consulter les informations sur votre nouveau rôle sous {myAccountLink}."
  },
  "statusTile": {
    "noMembership": {
      "title": "Vous n'avez pas encore d'adhésion",
      "subTitle": "Parcourir les données pour rejoindre un",
      "getConnected": "Soyez connectés"
    }
  },
  "settings": {
    "organization": {
      "pageTitle": "Mon organisation",
      "orgDetails": "Détails de l'organisation",
      "teamMembers": "Membres de l'équipe"
    },
    "teamMembers": {
      "title": "Gestion des utilisateurs",
      "description": "Cette section aide à gérer les utilisateurs de votre organisation"
    },
    "inviteUser": {
      "inviteUsers": "Inviter les utilisateurs",
      "newEmailAddress": "Adresse e-mail des nouveaux membres de l'équipe",
      "chooseRole": "Choisir le rôle",
      "whichRoleToSelect": "Quel rôle dois-je sélectionner?",
      "roleNote": "Un rôle sélectionné sera donné à tous les utilisateurs invités",
      "roleError": "Veuillez sélectionner au moins 1 rôle parmi les options disponibles",
      "roleErrorShort": "Veuillez sélectionner au moins 1 rôle",
      "cancel": "Annuler",
      "sendInvite": "Envoyer l'invitation",
      "fullName": "Nom et prénom",
      "status": "Statut",
      "role": "Rôle",
      "memberSince": "Membre depuis"
    },
    "inviteSuccess": {
      "title": "Résumé de l'invitation",
      "totalInvitation": "{count} invitation a été envoyé | {count} Les invitations ont été envoyées",
      "description": "{count} invite n'a pas été envoyé car l'adresse e-mail existe déjà parmi les utilisateurs de votre organisation | {count} Les invitations n'ont pas été envoyées car les adresses e-mail existent déjà parmi les utilisateurs de votre organisation",
      "actionContinue": "Continuer"
    },
    "roleChange": {
      "areYouSure": "Êtes-vous sûr de vouloir faire ces changements de rôle ?",
      "yeahSure": "Oui, j’en suis sûr"
    }
  },
  "completeInviteProfile": {
    "title": "Complétez votre compte",
    "description": "Vous avez été invité à Telekom Data Intelligence Hub. Pour compléter votre compte, veuillez remplir les informations requises.",
    "firstName": "Prénom",
    "firstNamePlaceholder": "Entrez votre prénom",
    "lastName": "Nom de famille",
    "lastNamePlaceholder": "Entrez votre nom de famille",
    "email": "E-mail",
    "emailPlaceholder": "Entrer le courrier électronique",
    "organisation": "Organisation",
    "organisationPlaceholder": "Entrez votre organisation",
    "enterPassword": "Entrer le mot de passe",
    "dataPrivacyCheck": "J'approuve que mes données personnelles sont stockées et traitées en fonction des objectifs indiqués dans le {dataAndPrivacyStatement}. *",
    "termsAndConditionsCheck": "Je confirme que j'ai lu et accepte les {termsandcondition} de Data Intelligence Hub.",
    "termsandcondition": "termes et conditions",
    "dataAndPrivacyStatement": "Déclaration de confidentialité des données",
    "cancel": "Annuler",
    "submit": "Soumettre",
    "success": {
      "title": "Votre compte est terminé",
      "description": "Vous serez dirigé vers la page de connexion",
      "actionContinue": "Continuer"
    }
  },
  "workbench": {
    "incomingDataAgreements": {
      "title": "Accords de données entrantes",
      "addedOn": "Ajouté sur",
      "expiresOn": "Expire le",
      "providedBy": "Fourni par",
      "dateAddedOn": "Date ajoutée",
      "noFilesAttached": "Aucun fichier n'est actuellement joint à cet accord de contrat",
      "noAgreementsMessage": "Vous n'avez aucun accord de données entrant",
      "receivingData": "Réception des données",
      "dataReceived": "Téléchargé",
      "error": "Une erreur s'est produite",
      "transferInitiated": "Transfert initié",
      "transferCompleted": "Transfert terminé !",
      "view": "Vue",
      "successDownload": "Ressource de données reçue avec succès",
      "errorDownload": "Le téléchargement de votre fichier pour {filename} a été interrompu avec une erreur, veuillez réessayer",
      "viewAggreement": "Voir l'accord",
      "agreeText": "En téléchargeant les fichiers, vous acceptez de vous conformer aux {contractTerms} du présent accord",
      "contractTerms": "Termes du contrat",
      "includedFile": "Fichier inclus",
      "createdOn": "Créé le",
      "fileType": "Type de fichier",
      "downloadError": "Échec de la récupération des accords de données entrants",
      "downloadSuccess": "Ressource de données reçue avec succès"
    },
    "contractDetails": {
      "termsAndPolicies": "Conditions d'utilisation et politiques",
      "offerBy": "Offre de",
      "addedOn": "Ajouté le",
      "expiresOn": "Expire le",
      "product": "Produit",
      "totalFiles": "Nombre total de dossiers",
      "description": "Description",
      "contractID": "N° de contrat",
      "contractTerms": "Conditions contractuelles",
      "files": "Fichiers",
      "parameters": "Paramètres",
      "referencePeriod": "Période de référence",
      "location": "Emplacement",
      "additionalLinks": "Liens supplémentaires",
      "dihMainPage": "Page principale de DIH",
      "download": "Télécharger",
      "search": "Rechercher",
      "title": "Titre",
      "createdOn": "Créé le",
      "kpiName": "KPI Nom",
      "fileType": "Type de fichier",
      "schema": "Schéma",
      "dashboard": "Tableau de bord",
      "selectAll": "Tout sélectionner",
      "deselectAll": "Tout désélectionner",
      "transfer": "Transfert",
      "downloadSelected": "Télécharger la sélection",
      "noTermsPolicy": "À l'heure actuelle, le fournisseur de données n'a spécifié aucune règle pour l'utilisation des données dans le cadre de ce contrat.",
      "youCan": "Vous pouvez",
      "youCant": "Tu peux pas",
      "youMust": "Il le faut",
      "respectContent": "Respecter le contenu du contrat de licence",
      "licenceAgreement": "Contrat de licence",
      "noAttachment": "Pas encore de pièces jointes",
      "termsandcondition": "Termes et conditions",
      "resellData": "Revendre les données",
      "youCanUseDataCommercial": "Nutzung der Daten für kommerzielle Zwecke",
      "youCanProcessData": "Traiter les données sur l'infrastructure en dehors de l'Union européenne",
      "youCanEmpty": "À l'heure actuelle, le fournisseur de données n'a spécifié aucune autorisation pour l'utilisation des données dans le cadre de ce contrat",
      "youCantEmpty": "À l'heure actuelle, le fournisseur de données n'a spécifié aucune interdiction d'utilisation des données dans le cadre de ce contrat",
      "youMustEmpty": "À l'heure actuelle, le fournisseur de données n'a spécifié aucune obligation pour l'utilisation des données dans le présent contrat"
    }
  },
  "messages": {
    "noDataAvailable": "Pas de données disponibles",
    "noMatchFound": "Pas de résultat trouvé",
    "maximumFileSize": "Taille maximale du fichier",
    "suitableFileFormats": "Formats de fichiers appropriés",
    "loading": "Chargement...",
    "dragDropText": "ou faites-le glisser et déposez-le ici"
  },
  "pageNotFound": {
    "title": "Oops ! Il semble que la page que vous cherchez n'existe pas encore.",
    "subtitle": "Découvrez nos autres ressources en attendant, qui pourraient vous être utiles !"
  },
  "unauthorized": {
    "pageNotAccessible": "Oups ! Il semble que la page que vous recherchez ne soit pas accessible avec vos autorisations.",
    "reachOutToAdministrator": "Veuillez contacter votre administrateur pour en savoir plus sur votre accès.",
    "checkOtherResources": "En attendant, consultez nos autres ressources."
  },
  "helpResources": {
    "needHelp": "Besoin d’aide pour trouver le bon plan, plus d’informations, des questions techniques ?",
    "FAQ": "Foire aux questions",
    "FAQDescription": "Trouvez plus de réponses courantes, de la facturation à la confidentialité",
    "browse": "Parcourir",
    "demos": "Démos",
    "demosDescription": "Trouvez des cas d'utilisation et des démos liés à votre secteur industriel",
    "glossary": "Glossaire",
    "glossaryDescription": "Trouvez les termes et définitions liés à la DIH, IDS, Catena-X, GAIA-X et plus encore."
  },
  "feedbackCard": {
    "giveUsFeedback": "Donnez-nous votre avis sur ce qui nous manque ?",
    "contactUs": "Contactez-nous"
  },
  "requestDataspaceJoin": {
    "title": "Demander la connexion à {dsName}",
    "basic": "Basique",
    "orgName": "Nom de l'organisation:",
    "contact": "Contact",
    "address": "Adresse:",
    "name": "Nom:",
    "email": "Messagerie électronique:",
    "joinReasonTitle": "S'il vous plaît, indiquez pourquoi vous voulez rejoindre l'espace de données ?",
    "joinConsent": "J'accepte de partager les détails ci-dessus avec le propriétaire de l'espace de données",
    "sendRequest": "Envoyer une demande"
  },
  "applications": {
    "myApplication": "Mes applications"
  },
  "externalConnection": {
    "title": "Gestionnaire de connexions externes",
    "noConnectionConfigureTitle": {
      "title": "Vous n'avez pas encore configuré de connexion",
      "newConnection": "Nouvelle connexion"
    },
    "request": {
      "connectionDetails": "Détails de connexion",
      "preferConnection": "Sélectionnez le mode de connexion au serveur préféré",
      "selectConnection": "Sélectionnez Connexion",
      "ageeTerms": "Par la présente, j’accepte et confirme que toutes mes informations et données personnelles mentionnées ci-dessus sont exactes",
      "grantConsent": "J’accorde mon consentement à Telekom Data Intelligence Hub pour le traitement et le stockage de ces données afin de faciliter les transferts d’actifs via la connexion fournie",
      "back": "Précédent",
      "addConnection": "Ajouter une connexion",
      "save": "Sauvegarder",
      "verifyConnectivity": "Vérifier la connectivité",
      "checkingConnection": "Vérification de la connexion",
      "connectionVerified": "Connexion vérifiée",
      "error": "Erreur",
      "requestSuccessTitle": "Ajouté avec succès !",
      "requestSuccessText": "Vous avez ajouté avec succès vos informations de connexion.",
      "requestUpdatedTitle": "Mise à jour réussie !",
      "requestUpdatedText": "Vous avez mis à jour avec succès vos informations de connexion.",
      "maxConnectionReached": "Vous avez atteint le nombre maximum de connexions gratuites."
    },
    "delete": {
      "title": "Suppression de connexion",
      "description": "Voulez-vous vraiment supprimer cette connexion ?",
      "cancel": "Annuler",
      "delete": "Supprimer",
      "requestDeleteSuccessTitle": "Supprimé avec succès !",
      "requestDeleteSuccessText": "Vous avez réussi à supprimer votre connexion."
    },
    "transfer": {
      "title": "Gestionnaire de connexions",
      "connectionDetails": "Détails de connexion",
      "type": "Type",
      "hostName": "Nom d'hôte",
      "rootPath": "Chemin d'accès à la racine",
      "authType": "Type d'authentification",
      "username": "Nom d'utilisateur",
      "verificationStatus": "Statut de vérification",
      "disclaimer": "Démenti",
      "disclaimerText": "En confirmant votre sélection, vous reconnaissez que la connexion choisie est indépendante de la volonté de Telekom Data Intelligence Hub. Par conséquent, vous assumez la responsabilité de tout problème de transfert de données résultant de facteurs externes, y compris, mais sans s'y limiter, un stockage insuffisant, une connectivité réseau, des restrictions d'accès et d'authentification et d'autres considérations pertinentes.",
      "confirmSelection": "Confirmer la sélection",
      "newConnection": "Nouvelle connexion",
      "noConnectionModalTitle": "Vous n'avez pas encore de connexions",
      "noConnectionModalDesc": "Vous pouvez créer une nouvelle connexion en cliquant sur le bouton ci-dessous.",
      "noConnectionAddNewButton": "Ajouter une nouvelle connexion",
      "successModalTitle": "Le transfert prendra plus de temps que d’habitude",
      "successModalDesc": "Nous vous enverrons une notification une fois le transfert de fichier terminé.",
      "successModalAction": "Accédez à Données entrantes"
    }
  },
  "error": {
    "pageNotFound": "Page introuvable (404)",
    "unauthorized": "Accès non autorisé (403)"
  },
  "helpWidget": {
    "askSupport": "Demander de l’aide",
    "chatboxTitle": "Chat d’aide",
    "chatboxSubtitle": "Prise en charge de Telekom Data Intelligence Hub",
    "defaultMessage": {
      "body": "Bonjour, bienvenue sur le canal d’assistance Telekom Data Intelligence Hub. Veuillez sélectionner une option ci-dessous."
    },
    "whatCanWeHelpWith": "En quoi pouvons-nous vous aider aujourd’hui ?",
    "suggestedAnswers": {
      "knowledgeBase": "Base de connaissances, documentation et FAQ",
      "contactSupport": "Contacter l'assistance",
      "contactSales": "Contacter le service commercial"
    }
  },
  "partnerNetworkManagement": {
    "title": "Gestion du réseau de partenaires",
    "hint": "Cette page permettra aux utilisateurs d'inviter des organisations partenaires au Telekom Data Intelligence Hub et de surveiller le statut des invitations.",
    "noInvitations": {
      "title": "Vous n'avez encore invité aucune organisation",
      "actionButtonText": "Inviter une organisation"
    },
    "overview": {
      "title": "Aperçu des invitations de partenaires",
      "actionButtonText": "Inviter des organisations",
      "dateOfInvitation": "Date de l'invitation",
      "resendInvitation": "Renvoyer l'invitation",
      "deleteInvitation": "Supprimer l'invitation",
      "resendReminderInfo": "Vous pouvez renvoyer l'invitation dès que 24 heures se sont écoulées depuis le dernier email d'invitation.",
      "invitedBy": "Invité par"
    },
    "information": {
      "title": "Informations importantes",
      "description": "La personne de contact assumera le rôle \"d'Administrateur\" au sein du Telekom Data Intelligence Hub. En tant qu'administrateur, elle aura l'autorité d'ajouter ou de supprimer des utilisateurs de sa propre organisation, de superviser les rôles et de prendre des décisions commerciales concernant les services que nous proposons. Nous transmettrons les étapes suivantes pour l'intégration de l'organisation à la personne de contact par email, et vous pourrez suivre les progrès dans la page \"Aperçu des invitations de partenaires\"."
    },
    "invite": {
      "additionalText": "Veuillez décider si vous souhaitez inviter des organisations individuellement ou en masse - et faites votre sélection ci-dessous.",
      "bulkInvite": "Invitations en masse",
      "bulkInviteHint": "Si vous souhaitez effectuer une invitation en masse (>5 invitations), veuillez sélectionner cette option.",
      "limitationHint": "Limite : Maximum 5 organisations. Pour en ajouter davantage, veuillez sélectionner \"Invitations en masse\".",
      "singleInvite": "Invitation individuelle",
      "singleInviteHint": "Pour inviter jusqu'à 5 organisations, veuillez sélectionner cette option.",
      "orgNameLabel": "Entrez le nom de l'organisation partenaire",
      "emailLabel": "Entrez l'e-mail du contact"
    },
    "inviteStatus": {
      "INVITED": "Invité",
      "COMPLETED": "Accepté",
      "FAILED": "Rejeté"
    },
    "upload": {
      "hint": "Pour inviter plusieurs organisations, veuillez suivre les étapes suivantes :",
      "step1": "Téléchargez le fichier modèle via le lien ci-dessous",
      "step2": "Remplissez le fichier fourni avec les détails des invités",
      "step3": "Téléchargez le fichier dans la zone prévue",
      "downloadTemplate": "Télécharger le modèle",
      "templateHeader": {
        "organizationName": "nom_de_l_organisation",
        "email": "courriel"
      },
      "templateFileName": "modele_d_invitations_en_gros.csv",
      "learnMore": "Si vous souhaitez en savoir plus sur l'invitation en masse, veuillez {clickHere}.",
      "clickHere": "cliquer ici"
    },
    "report": {
      "invitationsInProgress": "Les invitations des organisations sont en cours",
      "invitationsSent": "Invitations envoyées",
      "error": {
        "errorsInInvitations": "Erreurs dans {result} invitations envoyées",
        "single": {
          "invalidEmail": "L'adresse e-mail est invalide",
          "pleaseEnterValidEmail": "Veuillez entrer une adresse e-mail valide",
          "pleaseEnterValidOrganization": "Veuillez entrer un nom d'organisation valide",
          "emailUnique": "L'email doit être unique",
          "pleaseEnterValidName": "Veuillez entrer un nom valide"
        },
        "bulk": {
          "allowedFileExtension": "Seuls les fichiers avec l'extension csv sont autorisés",
          "fileIsTooBig": "Le fichier est trop volumineux. Vous pouvez télécharger des fichiers jusqu'à 1 Mo",
          "formatMismatch": "Le fichier ne correspond pas au format attendu. Veuillez utiliser le modèle fourni et réessayer",
          "duplicateEmail": "Le fichier contient {number} {duplicate}",
          "duplicate": "doublon | doublons",
          "fileHasNoEntries": "Le fichier ne contient aucune entrée",
          "invalidEmailAddresses": "{number} adresses e-mail sont invalides.",
          "invalidOrganizationNames": "{number} organisations ont des noms invalides.",
          "organizationAlreadyInvited": "{number} organisations ont déjà été invitées par votre organisation.",
          "kindlyDownloadReport": "Veuillez télécharger le rapport pour voir plus de détails"
        },
        "processing": {
          "DUPLICATE_EMAILS": "E-mails en double détectés",
          "EMAIL_ALREADY_EXIST": "L'e-mail existe déjà.",
          "SOMETHING_WENT_WRONG": "Quelque chose a mal tourné.",
          "INVALID_EMAIL_FORMAT": "Format d'e-mail invalide."
        }
      },
      "template": {
        "fileName": "rapport_des_invitations_en_gros.csv"
      }
    },
    "abort": {
      "title": "Annuler l'invitation",
      "confirmText": "Êtes-vous sûr de vouloir fermer ce processus ?",
      "informationText": "Vos invitations ne seront pas envoyées."
    },
    "switchForm": {
      "title": "Abandonner les modifications",
      "confirmText": "Êtes-vous sûr de vouloir changer de formulaire ?",
      "informationText": "Vos modifications ne seront pas conservées."
    }
  },
  "discover": {
    "welcome": {
      "title": "Découvrez les offres data de nos partenaires et fournisseurs de confiance",
      "description1": "Prenez des décisions basées sur les données.",
      "description2": "Les données jouent un rôle central dans les opérations commerciales, remplissant diverses fonctions essentielles telles que l’épine dorsale pour la prise de décision, la segmentation du marché et la génération de prospects. Les données peuvent faire ou défaire le développement de vos produits, optimiser la logistique de votre chaîne d’approvisionnement ou fournir à votre IA les informations dont elle a besoin pour tirer les conclusions dont vous avez besoin pour atteindre vos objectifs.",
      "description3": "Au sein du Data Intelligence Hub de Telekom, nous proposons une plate-forme unifiée pour les fournisseurs de données dans toute l’Allemagne. Explorez vos options et plongez dans le vif du sujet : vous trouverez peut-être une solution idéale pour vos besoins en matière de données. Nous élargissons constamment notre réseau de fournisseurs de données, donc même si vos besoins exacts ne sont pas couverts par notre offre de données actuelle, ne soyez pas un étranger : votre adéquation parfaite avec les données pourrait être dans quelques jours.",
      "buttonLabel": "Explorer les produits de données"
    },
    "dataOffers": {
      "title": "Offres de Données",
      "filter": {
        "subjectAreas": "Domaines d'études",
        "dataOfferingType": "Type d'offre de données",
        "dataProvider": "Fournisseur de données"
      },
      "tiles": {
        "productType": "Produit de données configurable",
        "dataspaceName": "Espace de Données du Data Intelligence Hub",
        "subjectArea": "Domaine d'études",
        "massmovementAnalytics": "Analyses des mouvements de masse",
        "termsOfUse": "Conditions d'utilisation",
        "licensedRestricted": "Sous licence, restreint",
        "viewOffer": "Voir l'offre",
        "product1": {
            "name": "Traffic Analytics par Motion Data",
            "summary": "Statistiques détaillées du comportement de déplacement en Allemagne, y compris la direction du déplacement, l'origine, la destination, permettant des analyses à différents niveaux spatiaux dans n'importe quelle région."
        },
        "product2": {
            "name": "Traffic Analytics PRO par Motion Data",
            "summary": "Mesures détaillées des déplacements en Allemagne, y compris la direction du déplacement, l'origine, la destination, permettant des analyses et des comparaisons à différents niveaux spatiaux à tout moment et dans n'importe quelle région."
        },
        "product3": {
            "name": "Visitor Analytics PRO par Motion Data",
            "summary": "Analyse des informations sur le comportement des visiteurs en raison des schémas de déplacement public, y compris le nombre total d'utilisateurs par jour et par heure, des informations sociodémographiques et les détails d'origine des visiteurs dans n'importe quelle région de l'Allemagne. Prochain niveau d'options d'analyse pour le comportement des visiteurs sur site, combinant les avantages de l'un des plus grands échantillons et une précision spatiale sans précédent."
        }
      }
    }
  },
  "ospOnboarding": {
    "help": "Ici",
    "welcome": {
      "title": "Bienvenue sur Catena-X - Le réseau automobile",
      "card": {
        "note": "Veuillez remplir le formulaire suivant pour compléter l'inscription de votre entreprise à Catena-X.",
        "point-1": "Le formulaire d'inscription se compose de 3 étapes et prendra environ 5 à 10 minutes de votre temps.",
        "point-2": "Comme votre organisation est déjà inscrite sur Telekom Data Intelligence Hub, nous préremplirons le formulaire avec vos données disponibles pour rendre le processus de complétion aussi fluide et facile que possible.",
        "point-3": "Vous pouvez consulter notre guide d'aide sur notre base de connaissances à tout moment pour mieux comprendre ce processus d'inscription. Veuillez cliquer {hereLink} pour en savoir plus.",
        "here": "Ici",
        "letStarted": "Commençons"
      }
    },
    "title": "Inscription à l'écosystème Catena-X",
    "description": "Inscrivez-vous à Catena-X en saisissant toutes les informations demandées dans les 3 étapes suivantes.",
    "description2": "Veuillez terminer l'intégration de votre entreprise en accomplissant les tâches suivantes afin de participer activement et d'utiliser toutes les fonctions du portail du réseau automobile Catena-X.",
    "note": "Veuillez saisir toutes les informations requises avant de continuer. Plus d'informations dans notre {help}.",
    "companyDataStepTitle": "Données de l’entreprise",
    "companyRoleStepTitle": "Rôle de l’entreprise",
    "verifyAndSubmitStepTitle": "Vérifier et soumettre",
    "companyData": {
      "title": "Ajoutez les données de votre entreprise",
      "description": "Veuillez ajouter ci-dessous les données de votre entité juridique. Les champs obligatoires sont mis en évidence par un (*) et le formulaire est pré-rempli avec les informations que vous nous avez déjà fournies lors de l’intégration.",
      "organizationName": "Nom de l’organisation",
      "organizationNameTooltip": "Nom de l’organisation enregistrée",
      "enterYourData": "Veuillez saisir vos données",
      "bpn": "Numéro de partenaire commercial",
      "bpnTooltip": "Le numéro de partenaire commercial (BPN) est un identifiant unique attribué à chaque partenaire commercial dans le cadre de l'écosystème Catena-X.",
      "legalEntityName": "Nom de l'entité légale",
      "registeredName": "Nom enregistré",
      "orgAddress": "Adresse de l'organisation",
      "streetName": "Nom de la rue",
      "houseNumber": "Numéro de maison",
      "postCode": "Code postal",
      "city": "Ville",
      "countryCode": "Code pays",
      "region": "Région",
      "companyIdentifier": "Identifiant de l’entreprise",
      "identifierType": "Type d'identifiant",
      "identifierNumber": "Numéro d'identification",
      "contactInfo": "Coordonnées",
      "emailAddress": "Adresse e-mail",
      "userRole": "Rôle de l'utilisateur",
      "CRN": {
        "label": "Numéro de registre commercial",
        "helpText": "Exemple: HRB 55933",
        "tooltipText": "Le numéro de registre commercial (CRN) est un identifiant unique attribué aux entreprises pour leur enregistrement commercial légal. Il est utilisé pour valider et vérifier l'authenticité des identifiants d'enregistrement et de transaction financière d'une entreprise contre les sources de données de confiance."
      },
      "vatId": {
        "label": "numéro de TVA",
        "helpText": "Exemple: DE 118 645 675",
        "tooltipText": "Un numéro de TVA (numéro d'identification fiscale à valeur ajoutée) est un identifiant unique attribué aux entreprises à des fins fiscales."
      }
    },
    "companyRole": {
      "title": "Le rôle de votre entreprise",
      "description": "Veuillez sélectionner un ou plusieurs rôles d'entreprise avec lesquels vous souhaitez agir/participer dans le réseau Catena-X. Le rôle de l'entreprise peut toujours être mis à jour/modifié ultérieurement. Selon le rôle sélectionné, les services proposés sur le portail peuvent différer.",
      "activeParticipanyNote": "Adaptez et optimisez votre participation à Catena-X avec T-Systems Connect & Integrate pour une connexion facile et automatisée à Catena-X. Commandez le Connect & Integrate depuis la place de marché de l'application Cofinity pour les cas d'utilisation principaux de Catena-X et commencez immédiatement à échanger des données ! À partir de 49 EUR/mois et disponible pour toutes les tailles d'entreprises.",
      "agreeTermsCheckbox": "Oui, j'accepte le {link} du rôle sélectionné",
      "activeParticipantNote": {
        "para1": "Adaptez et optimisez votre participation à Catena-X avec {link} pour une connexion facile et automatisée à Catena-X.",
        "tSystemsConnectAndIntegrate": "T-Systems Connect & Integrate",
        "para2": "Commandez le Connect & Integrate depuis la place de marché de l'application Cofinity pour les cas d'utilisation principaux de Catena-X et commencez immédiatement à échanger des données !",
        "para3": "À partir de {price}/mois et disponible pour toutes les tailles d'entreprises."
      },
      "appProviderNote": {
        "para1": "{link} vous permet de développer, gérer et certifier des applications d’entreprise dans notre environnement officiel Catena-X Sandbox.",
        "para2": "Commandez Build & Operate dès aujourd’hui et laissez-nous gérer votre infrastructure d’espace de données pendant que vous vous concentrez sur l’innovation !",
        "para1PlaceholderLink": "Build & Operate de T-Systems",
        "optionalInformation": "Informations facultatives"
      }
    },
    "verifyAndSubmit": {
      "title": "Vérifiez vos données d'inscription",
      "description": "Vérifiez les données d'inscription de votre entreprise avant de soumettre l'inscription. Veuillez-vous assurer que toutes les informations sont correctement remplies. Dès que la vérification est terminée, cliquez sur le bouton Soumettre ci-dessous pour soumettre l'inscription pour approbation.",
      "companyData": "Données de l'entreprise",
      "activeRole": "Rôle actif",
      "consentText": "Je consens à ce que T-Systems International GmbH soumette ma demande d’inscription à Cofinity-X GmbH et traite les données personnelles pertinentes dans le but de mener à bien la procédure d’intégration et à des fins connexes. Je reconnais que mes données seront traitées conformément aux lois et réglementations applicables en matière de protection des données, y compris, mais sans s’y limiter, le Règlement général sur la protection des données (RGPD). Je comprends que mes données personnelles ne seront conservées qu’aussi longtemps que nécessaire pour atteindre ces objectifs. Je suis conscient que ce consentement est nécessaire pour l’inscription et l’intégration chez Cofinity-X GmbH, et que je peux retirer mon consentement à tout moment avec effet pour l’avenir."
    },
    "actions": {
      "back": "Retour",
      "next": "Suivant",
      "submit": "Envoyer"
    },
    "validation": {
      "DE_COMMERCIAL_REG_NUMBER": "Veuillez entrer un numéro valide. Astuce : alphanumérique avec trait d'union et espace avec exactement 9 chiffres.",
      "DE_VAT_ID": "Veuillez entrer un numéro valide. Astuce : Commençant par DE et suivi de nombres à 9 chiffres",
      "Worldwide_COMMERCIAL_REG_NUMBER": "Veuillez entrer un numéro valide. Astuce : Alphanumérique avec tirets de 6 à 21 chiffres.",
      "Worldwide_VAT_ID": "Veuillez entrer un numéro valide. Astuce : alphanumérique avec trait d'union et espace de 8 à 15 chiffres.",
      "invalid_bpn": "Numéro BPN non valide. Le numéro BPN doit commencer par BPNL suivi de 12 caractères alphanumériques."
    },
    "error": {
      "title": "Erreur de validation",
      "para": "Soit votre organisation existe déjà au sein du réseau Cofinity-X, soit des problèmes techniques sont en cours de résolution. N’hésitez pas à nous en dire plus sur votre expérience afin que nous puissions vous aider davantage.",
      "buttonText": "Contactez-nous"
    },
    "success": {
      "title": "Merci d'avoir soumis votre demande",
      "para": "Vos coordonnées ont été envoyées à Cofinity-X, vous recevrez des informations sur les prochaines étapes par e-mail",
      "buttonText": "Aller vers la page d'accueil"
    }
  }
}