export const SET_FETCH_VC_DETAILS_IN_PROGRESS = 'SET_FETCH_VC_DETAILS_IN_PROGRESS';
export const SET_VC_REQUEST_IN_PROGRESS = 'SET_VC_REQUEST_IN_PROGRESS';
export const SET_COUNTRIES = 'SET_COUNTRIES';
export const SET_REGIONS = 'SET_REGIONS';
export const SET_BULK_ISSUING_STATUSES = 'SET_BULK_ISSUING_STATUSES';
export const SET_RL_FILTER_PARAMS = 'SET_RL_FILTER_PARAMS';
export const SET_REPORT_LIST_DETAILS = 'SET_REPORT_LIST_DETAILS';
export const SET_FETCH_REPORT_LIST_IN_PROGRESS = 'SET_FETCH_REPORT_LIST_IN_PROGRESS';
export const SET_API_USAGE_LIMIT_DATA = 'SET_API_USAGE_LIMIT_DATA';
export const SET_FETCH_API_USAGE_IN_PROGRESS = 'SET_FETCH_API_USAGE_IN_PROGRESS';
export const SET_CERTIFICATE_LIST = 'SET_CERTIFICATE_LIST';
export const SET_FETCH_CERTIFICATE_LIST_IN_PROGRESS = 'SET_FETCH_CERTIFICATE_LIST_IN_PROGRESS';
