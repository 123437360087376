import type { Module } from 'vuex';
import { actions } from './actions';
import mutations from './mutations';
import { getters } from './getters';
import type { IntBuildDataspaceState } from '@/common/interfaces/build/IntBuildDataspaces';
import { EXISTING_ORGANIZATION } from '../common/AllocateConnectorRequestOrigin';
import { ParticipantRoles } from '../common/ParticipantRoles';

// initial state
const getDefaultState = (): IntBuildDataspaceState => {
  return {
    isLoading: false,
    ownedDataspace: [],
    participantDetail: [],
    serviceOverview: {
      appManagementUrl: '',
      iamUrl: '',
      continuousDeliveryUrl: '',
      secretCredManagementUrl: '',
      monitoringUrl: '',
    },
    dataspaceDetails: {
      dataspaceName: '',
      description: '',
      emailId: '',
      phoneNo: '',
      address: '',
      orgAddress: {
        street: '',
        city: '',
        zip: '',
        number: '',
        country: '',
      },
      website: '',
      lilaDataspaceId: '',
      lilaDataspacePassword: '',
      dataspaceType: ParticipantRoles.DATASPACE_PARTICIPANT
    },
    allocateConnectorOriginDetails: {
      initPoint: EXISTING_ORGANIZATION,
    },
    applicationData: {
      id: '',
      dataspaceId: '',
      ownerOrgId: '',
      ownerOrgName: '',
      description: '',
      dataspaceName: '',
      dashboardUrl: '',
      name: '',
      logo: '',
      updateDateTime: '',
    },
  };
};
const INITIAL_STATE = getDefaultState();

// living lab store
const LivingLabStore: Module<IntBuildDataspaceState, {}> = {
  state: INITIAL_STATE,
  actions,
  mutations,
  getters,
};

export default LivingLabStore;
