export const FETCH_COUNTRIES = 'FETCH_COUNTRIES';
export const FETCH_REGIONS = 'FETCH_REGIONS';
export const SEND_BULK_VC_REQUEST = 'SEND_BULK_VC_REQUEST';
export const FETCH_REPORT_LIST = 'FETCH_REPORT_LIST';
export const DOWNLOAD_REPORTS = 'DOWNLOAD_REPORTS';
export const FETCH_API_USAGE_LIMIT = 'FETCH_API_USAGE_LIMIT';
export const FETCH_SUBSCRIPTION_DATA = 'FETCH_SUBSCRIPTION_DATA';
export const DELETE_INTEGRATION = 'DELETE_INTEGRATION';
export const DOWNLOAD_INTEGRATION = 'DOWNLOAD_INTEGRATION';
export const FETCH_CERTIFICATE_LIST = 'FETCH_CERTIFICATE_LIST';
export const GET_WALLET_VC = 'GET_WALLET_VC';
export const ISSUE_WALLET_VC = 'ISSUE_WALLET_VC';
