{
  "ariaLabel": {
    "viewDetails": "Details anzeigen",
    "downloadDataAssets": "Data Asset herunterladen"
  },
  "topMenu": {
    "dihTitle": "Data Intelligence Hub",
    "DIHOrgRegistration": "Data Intelligence Hub – Organisationsregistrierung",
    "dihSubTitle": "Wert sicher aus Daten extrahieren",
    "myAccount": "Mein Konto",
    "logout": "Abmelden",
    "myOrganization": "Meine Organisation"
  },
  "userLogoutAlert": {
    "title": "Möchten Sie sich wirklich abmelden?",
    "back": "Zurück",
    "logout": "Abmelden",
    "myOrganization": "Meine Organisation"
  },
  "sideMenu": {
    "dashboard": "Dashboard",
    "connectors": "Konnektoren",
    "myConnectors": "Meine Konnektoren",
    "dataspaces": "Datenräume",
    "discover": "Entdecken",
    "manage": "Verwalten",
    "marketplace": "Marktplatz",
    "myDataspaces": "Meine Mitgliedschaften",
    "browseDataspaces": "Datenräume durchsuchen",
    "organizations": "Organisationen",
    "build": "Erstellen",
    "ownedDataspaces": "Meine Datenräume",
    "orgProfile": "Organisations profil",
    "collapse": "Seitenleiste einklappen",
    "participate": "Teilnehmen",
    "getVerified": "Verifizieren lassen",
    "workbench": "Workbench",
    "dataIncoming": "Eingehende Daten",
    "applications": "Anwendungen",
    "verify": "Verifizieren",
    "digitalIDDashboard": "Einblicke erhalten",
    "mySignatures": "Meine Unterschriften",
    "externalConnectionManager": "Externer Verbindungs Manager"
  },
  "dropdown": {
    "selectAll": "Alle auswählen",
    "noOption": "Keine Optionen verfügbar",
    "noSuchOption": "Es gibt keine solche Option ..."
  },
  "common": {
    "start": "Start",
    "finish": "Fertig",
    "firstName": "Vorname",
    "lastName": "Nachname",
    "email": "E-Mail Adresse",
    "phoneNumber": "Telefonnummer",
    "contactPerson": "Ansprechpartner*in",
    "welcome": "Willkommen",
    "street": "Straße",
    "city": "Stadt",
    "country": "Land",
    "postCode": "Postleitzahl",
    "number": "Nummer",
    "next": "Weiter",
    "prev": "Zurück",
    "alreadyAccount": "Verfügen Sie bereits über ein Konto?",
    "login": "Anmelden",
    "loginNow": "Jetzt anmelden",
    "register": "Registrieren",
    "home": "Startseite",
    "submit": "Abschicken",
    "attention": "Bitte beachten",
    "desc": "Beschreibung",
    "website": "Website",
    "contact": "Kontakt",
    "details": "Details",
    "plan": "Plan",
    "listView": "Listenansicht",
    "gridView": "Rasteransicht",
    "firstPage": "Zur ersten Seite",
    "previousPage": "Zur vorherigen Seite",
    "nextPage": "Zur nächsten Seite",
    "lastPage": "Zur letzten Seite",
    "download": "Herunterladen",
    "delete": "Löschen",
    "edit": "Bearbeiten",
    "information": "Informationen",
    "organization": "Organisation",
    "cancel": "Abbrechen",
    "save": "Speichern",
    "upload": "Hochladen",
    "link": "Link",
    "view": "Video",
    "search": "Suchen",
    "searchTerm": "Suchen",
    "selectOption": "Option auswählen",
    "sortBy": "Sortieren nach",
    "dataspace": "Datenraum",
    "select": "Auswählen",
    "date": "Datum",
    "title": "Titel",
    "successful": "Erfolgreich",
    "unsuccessful": "Erfolglos",
    "statuses": {
      "connected": "verbunden",
      "disconnected": "getrennt",
      "deploying": "Einsatz",
      "deployed": "eingesetzt",
      "failed": "fehlgeschlagen",
      "available": "verfügbar",
      "success": "Erfolg",
      "active": "aktiv",
      "pending": "anhängig",
      "declined": "abgelehnt",
      "invited": "eingeladen",
      "onboarding": "Onboarding",
      "withdrawn": "zurückgezogen",
      "successful": "erfolgreich",
      "unsuccessful": "erfolglos",
      "valid": "gültig",
      "expired": "abgelaufen",
      "revoked": "widerrufen"
    },
    "andMore": "und {count} weitere",
    "confirm": "Bestätigen",
    "no": "Nein",
    "links": {
      "dihWiki": "https://docs.dih.telekom.com/en/home"
    },
    "formError": "Irgendetwas stimmt nicht.",
    "question": {
      "interested": "Haben wir Ihr Interesse geweckt?"
    },
    "moreInfo": "Falls Sie weitere Informationen benötigen:",
    "goToWebsite": "Unsere Website aufrufen",
    "provider": "Anbieter",
    "required": "Erforderlich",
    "errors": "Irrtümer",
    "error": "Fehler"
  },
  "genericError": "Leider ist ein Fehler aufgetreten! Versuchen Sie es bitte erneut.",
  "validation": {
    "fieldRequired": "{field} ist ein Pflichtfeld.",
    "fieldNumeric": "Das Feld {field} darf nur Zahlen enthalten.",
    "fieldAlpha": "Das Feld {field} darf nur Buchstaben, Unter- und Bindestriche enthalten.",
    "fieldAlphanumeric": "Das Feld {field} darf nur Buchstaben, Zahlen, Unter- und Bindestriche enthalten.",
    "fieldNumericDash": "Das Feld {field} darf nur Zahlen und Bindestriche enthalten.",
    "fieldHouseNumber": "Das Feld {field} darf nur Zahlen, Buchstaben und die Zeichen - und / enthalten.",
    "fieldPhoneNumber": "Das Feld {field} darf nur Zahlen und Bindestriche enthalten. Muss mit + oder 00 beginnen.",
    "fieldAlphaDash": "Das Feld {field} darf nur Buchstaben und Bindestriche enthalten.",
    "exceptHTMLTagBrackets": "Das Feld {field} darf keine der folgenden Zeichen enthalten: ^<>",
    "isMaxLength": "überschreitet die maximale Länge von 10 Zeichen.",
    "emailFormat": "E-Mail-Adresse weist ein falsches Format auf",
    "fileSizeError": "Die Datei ist größer als  {size} MB.",
    "csvFileSizeError": "Diese Datei ist zu groß. Sie können Dateien bis zu einer Größe von {size} MB hochladen.",
    "csvFileExtensionError": "Es sind nur Dateien mit der Erweiterung csv zulässig.",
    "csvFileIsEmptyError": "Diese Datei ist leer. Sie müssen eine Datei mit mindestens {size} KB hochladen.",
    "csvFileFormatError": "Die Datei entspricht nicht dem erwarteten Format. Bitte verwenden Sie die bereitgestellte Vorlage und versuchen Sie es erneut.",
    "uploadFails": "Upload fehlgeschlagen",
    "fileExtensionError": "Die Dateierweiterung ist nicht zulässig",
    "fileIsMalwareInfectedError": "Diese Datei scheint Malware zu enthalten. Aus Sicherheitsgründen kann diese Datei nicht hochgeladen werden. Bitte überprüfen Sie Ihre Akte noch einmal",
    "customValidation": "{field} weist ein falsches Format auf.",
    "selectCountryFirst": "Bitte wählen Sie zuerst das Feld \"Ländername\" aus.",
    "validationPostCodeCaseDE": "Das Feld weist ein falsches Format auf. Muss 5 Zahlen enthalten, z. B. 53113.",
    "validationAlphanumericCase": "Das Feld darf nur alphanumerische Zeichen, Bindestriche und Unterstriche enthalten. Der Wert muss mit einem alphanumerischen Zeichen beginnen.",
    "validationNumericDashCase": "Das Feld darf nur Zahlen und Bindestriche enthalten.",
    "url": "Geben Sie eine gültige URL",
    "maxInvitationSize": "Sie können bis zu {size} Teilnehmer auf einmal einladen",
    "password": {
      "passwordStrength": "Passwortstärke",
      "weak": "Schwach",
      "average": "Mittel",
      "strong": "Stark",
      "passwordValidationMoreChar": "{count} oder mehr Zeichen",
      "passwordUpperAndLowerChar": "Groß- und Kleinbuchstaben",
      "passwordAtLeastNumber": "Mindestens eine Zahl",
      "passwordAtLeastSpecialChar": "Mindestens ein Sonderzeichen (mit Ausnahme von <>&\"`[])",
      "passwordDiffFromPrev": "Weicht von Ihren vorherigen Passwörtern ab"
    }
  },
  "footer": {
    "tagLine": "Let's power higher performance",
    "imprint": "Impressum",
    "imprintShort": "Impressum",
    "imprintLink": "https://dih.telekom.com/de/impressum",
    "contact": "Kontakt",
    "contactLink": "https://dih.telekom.com/de/kontakt",
    "termsAndConditions": "Nutzungsbedingungen",
    "privacy": "Datenschutzerklärung",
    "privacyShort": "Datenschutz",
    "telekom": "T-Systems International GmbH. Alle Rechte vorbehalten.",
    "telekomShort": "T-Systems International GmbH",
    "note": "Telekom Data Intelligence Hub - Nur für Geschäftskunden",
    "brandedNote": "Powered by Telekom Data Intelligence Hub",
    "help": "Hilfe",
    "cookieSettings": "Cookie Einstellungen",
    "cookieSettingsShort": "Cookies",
    "life": "Erleben, was verbindet."
  },
  "onboarding": {
    "welcome": "Willkommen beim Data Intelligence Hub",
    "welcomeDIH": "Willkommen beim Data Intelligence Hub",
    "informationForOrganization": "Informationen zu Ihrer Organisation",
    "orgRegNameLabel": "Registrierter Name der Organisation/Einheit",
    "legalNameHint": "Bitte die juristische Bezeichnung der Organisation eingeben",
    "addressHeading": "Adresse der Hauptniederlassung der Organisation",
    "orgNameLabel": "Name der Organisation",
    "orgUnitNameLabel": "Name der Organisationseinheit",
    "emailAddressLabel": "E-Mail Adresse",
    "countryNameLabel": "Land",
    "bpnNumberLabel": "Geschäftspartnernummer (BPN)",
    "contactPersonInfo": "Ansprechpartner*in",
    "fillInfoBelow": "Bitte folgende Felder ausfüllen",
    "PLZCode": "Postleitzahl",
    "verifyEmail": "E-Mail Adresse verifizieren",
    "verifyEmailMessage": "Vielen Dank, dass Sie dem Data Intelligence Hub beitreten möchten – Ihre Organisation ist bereits bei uns registriert. Bestätigen Sie bitte Ihre E-Mail-Adresse.",
    "taxId": "USt IdNr.",
    "commerceRegisterNumber": "Handelsregisternummer",
    "orgRepresentative": {
      "title": "Konto für Organisationsvertretung einrichten",
      "orgContactPerson": "Ansprechpartner*in bei der Organisation",
      "whatDefinesYou": "Was trifft am ehesten auf Sie zu?",
      "agreeText": "Ich bestätige, dass ich die  {1} des Data Intelligence Hub gelesen habe und einhalten werde. ",
      "confirmText": "Ich bin damit einverstanden, dass meine personenbezogenen Daten gemäß <a href='#'>Datenschutzerklärung gespeichert und verarbeitet werden</a>"
    },
    "success": "Organisation erfolgreich registriert",
    "underReview": "Ihre Organisation wird verifiziert.",
    "verified": "Sie haben Ihre Organisation erfolgreich verifiziert",
    "steps": {
      "counter": {
        "step": "Schritt",
        "of": "von"
      },
      "stepWizard": {
        "orgInfo": "Informationen zur Organisation",
        "selectConnection": "Verbindung auswählen",
        "configureConnection": "Konfigurieren Sie Ihre Verbindung",
        "selectPackage": "Ihren Plan auswählen",
        "checkout": "Zur Kasse"
      },
      "actionButton": {
        "back": "Zurück",
        "next": "Weiter",
        "order": "Zahlungspflichtig bestellen",
        "checkout": "Zur Kasse",
        "selectMyPlan": "Meinen Plan auswählen"
      },
      "orgStep": {
        "fields": {
          "orgInfoLabel": "Organisationsdetails",
          "orgName": "Juristische Bezeichnung der Organisation",
          "departmentName": "Name der Abteilung",
          "dataSpaceAttributes": "Zusätzliche Attribute",
          "orgUnit": "Name der Organisationseinheit",
          "crnNumber": "Handelsregisternummer",
          "taxId": "USt IdNr.",
          "bpnNo": "Geschäftspartnernummer (BPN)",
          "addressInfo": "Rechnungsanschrift",
          "countryName": "Land",
          "plzCode": "Postleitzahl",
          "number": "Nummer",
          "city": "Stadt",
          "street": "Straße",
          "contactPersonLabel": "Ansprechpartner*in",
          "contactPerson": "Ansprechpartner*in",
          "fullName": "Vollständiger Name",
          "firstName": "Vorname",
          "firstNamePlaceholder": "Vornamen eingeben",
          "lastName": "Nachname",
          "lastNamePlaceholder": "Nachnamen eingeben",
          "phoneNo": "Telefonnummer",
          "emailAdd": "E-Mail Adresse",
          "dataPrivacyCheck": "Ich bin damit einverstanden, dass meine personenbezogenen Daten gespeichert und verarbeitet werden gemäß der {dataprivacystatement} und den {supplementaryDataPrivacyConditions} gespeichert und verarbeitet werden",
          "termsAndConditionsCheck": "Ich bestätige, dass ich als bevollmächtigter Vertreter des Unternehmens die {termsandcondition} gelesen, verstanden und akzeptiert habe.",
          "dataprivacystatement": "Datenschutzerklärung",
          "supplementaryDataPrivacyConditions": "ergänzenden Datenschutzbestimmungen",
          "termsandcondition": " Allgemeinen Geschäftsbedingungen",
          "agreeToEnterContract": "Ich willige ein, einen Vertrag mit T-Systems International GmbH auf Grundlage der obigen {serviceDescription} abzuschließen",
          "serviceDescription": "Leistungsbeschreibung",
          "markettingConsentCheck": "Ich bin damit einverstanden, von der T-Systems International GmbH und der Telekom Deutschland GmbH Marketing-E-Mails, Briefe, Anrufe oder SMS/MMS über Produkte, Dienstleistungen und Veranstaltungen zu erhalten. Diese Einwilligung kann ich jederzeit durch eine E-Mail an {email} widerrufen."
        }
      },
      "connection": {
        "sectionHeading": "Verbindung auswählen",
        "sectionSubHeading": "Bitte Verbindungstyp auswählen für {dsName}",
        "textNote": "Es können nur mit Catena-X kompatible Optionen ausgewählt werden",
        "recommendedConfiguration": "Empfohlene Konfiguration",
        "cloudAndDataPlan": "Cloud- und Datenplan"
      },
      "packages": {
        "sectionHeading": "Ihren Plan auswählen",
        "sectionSubHeading": "Datenplanvarianten",
        "basedOnYourSelection": "Basierend auf Ihrer Auswahl empfehlen wir Folgendes",
        "dataPackage": "Datenpaket",
        "outgoingTraffic": "Ausgehender Datenverkehr",
        "storage": "Speicher",
        "selectedDataPlan": "Datentarif auswählen",
        "dataPlanDetails": "Datenplandetails",
        "dataPlanLabelForVolume": "Pläne mit Inklusiv Volumen",
        "dataPlanLabelFlexible": "Pay-as-you-go",
        "packageAvailable": "Dieses Paket ist nur mit diesem Plan erhältlich",
        "chargesApply": "Wenn das Nutzungsvolumen überschritten wird, fallen Gebühren an",
        "additionalStorage": "Zusätzlicher Speicherplatz",
        "additionalTraffic": "Zusätzlich ausgehender Datenverkehr"
      },
      "cloudProviders": {
        "cloudInfrastructure": "Cloud Anbieter auswählen",
        "cloudAvailable": "Dieses Paket ist nur bei diesem Cloud Anbieter verfügbar"
      },
      "checkout": {
        "sectionHeading": "Ihr Verbindungspaket zu {orgName}",
        "setupCost": "Einrichtungskosten",
        "monthlyCost": "Monatliche Kosten",
        "payg": "Nutzungsbasierte Bezahlung („Pay as you go“)",
        "pipSheet": "Produktinformationsblatt",
        "totalCost": "Gesamtkosten",
        "totalPayable": "Zu zahlender Gesamtbetrag",
        "EOMAmountPolicy": "Ich bin damit einverstanden, dass der zu zahlende Gesamtbetrag am Monatsende berechnet wird",
        "dataPrivacyCheck": "Ich stimme zu, die mit den oben bestellten Dienstleistungen verbundenen Gebühren zu entrichten",
        "termsAndConditionsCheck": "Ich stimme der Vertragslaufzeit und den Stornierungsbedingungen zu",
        "termsandcondition": "Nutzungsbedingungen",
        "generalAgreement": "Ich willige ein, einen Vertrag mit T-Systems International GmbH auf Grundlage der obigen Leistungsbeschreibung abzuschließen",
        "DPAAgreement": "Ich stimme der  {dataprocessingagreement} (DPA) zu",
        "dataprocessingagreement": "Datenverarbeitungsvereinbarung",
        "dataPackage": "Datenpaket",
        "dataPlan": "Datenplan",
        "outgoingTraffic": "Enthaltener ausgehender Datenverkehr",
        "contractDuration": "Vertragslaufzeit",
        "defaultContractDuration": "6 Monate",
        "price": "Preis",
        "perGigabyte": "pro GB",
        "monthlyCancelable": "monatlich kündbar",
        "storagePrice": "Preis für Speicherplatz",
        "perGigabyteForStorage": "/GB für Speicher",
        "perGigabyteForOutgoingTraffic": "/GB für ausgehenden Datenverkehr",
        "outgoingTrafficPrice": "Preis für ausgehenden Datenverkehr",
        "storage": "Enthaltener Speicherplatz",
        "linksText": {
          "information": "Informationsblatt",
          "service": "Leistungsbeschreibung"
        },
        "plusCharges": "{plus} Gebühren für Ihre Nutzung",
        "plus": "plus",
        "basedOnConsumption": "Basierend auf dem Verbrauch"
      },
      "cancelModal": {
        "confirmQuestion": "Möchten Sie die ausgewählte Verbindung wirklich kündigen?",
        "confirmYes": "Ja, kündigen",
        "confirmNo": "Nein, fortfahren"
      }
    },
    "statuses": {
      "complete": {
        "title": "Sie sind bereits registriert",
        "description": "Der Registrierungs Link kann nicht wiederverwendet werden.",
        "buttonText": "Zur Startseite"
      },
      "error": {
        "title": "Es ist ein Fehler aufgetreten",
        "description": "Vorgang neu starten",
        "buttonText": "Neustart"
      },
      "expired": {
        "title": "Ihr Link ist leider abgelaufen",
        "description": "Starten Sie den Vorgang über das Catena-X Portal erneut.",
        "buttonText": "Zurück zu Catena-X"
      },
      "success": {
        "title": "Vielen Dank für Ihre Registrierung",
        "description": "Wir überprüfen die Details Ihrer Organisation. Sie erhalten weitere Informationen per E-Mail.",
        "buttonText": "Unsere Website aufrufen"
      },
      "invalid": {
        "title": "Keine Berechtigung",
        "description": "Sie sind nicht berechtigt, diese Seite aufzurufen.",
        "buttonText": "Unsere Website aufrufen"
      },
      "dih-success": {
        "title": "Vielen Dank für Ihre Registrierung",
        "description": "Wir überprüfen die Details Ihrer Organisation. Sie erhalten weitere Informationen per E-Mail.",
        "buttonText": "Unsere Website aufrufen"
      },
      "orgNotFound": {
        "title": "Wir konnten Ihre Organisation nicht finden",
        "descriptionLine1": "Tut mir Leid! Es sieht so aus, als ob Ihr Unternehmen noch nicht onboarded ist oder Sie noch kein Abonnement für T-Systems-Dienste angefordert haben.",
        "descriptionLine2": "Bitte fordern Sie über den {marketplace} ein Abonnement für T-Systems-zertifizierte und maßgeschneiderte Enablement-Services für Catena-X an.",
        "marketplace": "Marktplatz",
        "descriptionLine3": "Wenn Sie der Meinung sind, dass dies ein Fehler ist, wenden Sie sich bitte an {contactOurSalesTeam} und wir werden Sie gerne unterstützen.",
        "contactOurSalesTeam": "Kontaktieren Sie unser Vertriebsteam",
        "exploreTheKnowledgeBase": "Erkunden Sie die Wissensdatenbank",
        "readOurBlog": "Lesen Sie unseren Blog"
      }
    }
  },
  "dashboard": {
    "pageTitle": "Startseite",
    "pageSubtitle": "Dies ist Ihr Kontrollzentrum für sämtliche Prozesse innerhalb des Data Intelligence Hub."
  },
  "services": {
    "finishConfig": "Konfiguration abschließen",
    "open": "Öffnen",
    "viewDetails": "Details anzeigen",
    "configure": {
      "uploadCertification": "Laden Sie das Zertifikat hoch, dass Sie vom Datenraum Betreiber erhalten haben.",
      "dragAndDropCertificate": "Legen Sie Ihr Zertifikat hier per Drag & Drop ab.",
      "didntReceive": "Sie haben kein Zertifikat erhalten oder wissen nicht, wie Sie eines erhalten können? Folgen Sie diesen Schritte.",
      "uploadSuccessMessage": "Zertifikat erfolgreich hochgeladen"
    },
    "table": {
      "attribute": "Attribut",
      "value": "Wert",
      "apiKey": "API Schlüssel",
      "apiKeyValue": "Wert des API Schlüssels",
      "controlPlaneUrl": "URL Kontrollebene",
      "idsURL": "DSP API Endpunkt",
      "connectorUrl": "URL Connector",
      "dataManagementUrl": "API Endpunkt für die Datenverwaltung",
      "dataDashboard": "Daten Dashboard",
      "participantDid": "DID ID des Teilnehmers"
    }
  },
  "dataspaces": {
    "dataspaces": "Datenräume",
    "viewAllDataspaces": "Alle Datenräume anzeigen",
    "connectorTestDescription": "Anwendung zum Testen, ob Ihre Verbindung wie erwartet läuft und Ihre Verbindung zum Datenraum aktiv ist.",
    "configure": {
      "pageTitle": "Datenraumverbindung konfigurieren",
      "basicInfo": "Grundlegende Informationen",
      "certificate": "Zertifikat",
      "instructionCertrificate": "Laden Sie das Zertifikat hoch, dass Sie vom Datenraum Betreiber erhalten haben."
    },
    "connectorHealthCheck": {
      "title": "Connector Zustandsprüfung",
      "copiedToClipboard": "In die Zwischenablage kopiert",
      "description": "Mit Hilfe dieser Anwendung wird ein Selbsttest durchgeführt, um zu überprüfen, ob der von Ihnen ausgewählte Connector konfiguriert ist und wie erwartet funktioniert. Dadurch werden weder Einstellungen noch Speicher des ausgewählten Connectors geändert.",
      "connectorid": "Connector ID",
      "model": {
        "title": "Connector Zustandsprüfung läuft...",
        "body": "Während des Gesundheitschecks können Sie das Hotel nicht verlassen. Wenn Sie ausreisen möchten, wird der Gesundheitscheck abgebrochen und muss neu gestartet werden.",
        "toLeave": "Nein, ich möchte gehen",
        "toFinish": "Beenden Sie den Gesundheitscheck"
      },
      "banner": {
        "initiate": "Die Ausführung der Gesundheitsprüfung kann einige Minuten dauern. Sobald das Ergebnis vorliegt, werden Sie benachrichtigt.",
        "success": "Ihr Connect Selbsttest war erfolgreich! Um die Ergebnisse vergangener Tests anzuzeigen, klicken Sie oben auf „Ergebnisse“.",
        "failed": "Entschuldigung! Ihr Connect Selbsttest war nicht erfolgreich. Bitte kontaktieren Sie {email} und wir unterstützen Sie gerne.",
        "email": "dih_support{'@'}telekom.de"
      },
      "buttonState": {
        "initiate": "Einleiten",
        "success": "Öffnen Sie Connect",
        "failed": "Kontaktieren Sie Support",
        "back": "zurück"
      }
    },
    "browse": {
      "browseDataspace": "Datenräume durchsuchen",
      "availableDS": "Verfügbare Datenräume",
      "howToConnect": "Herstellen einer Verbindung",
      "getConnected": "Get Connected - Verbinden",
      "requestConnection": "Verbindung anfordern",
      "viewRequest": "Anfrage anzeigen",
      "viewDetails": "Details anzeigen",
      "highlightNote": "Wenn Sie Ihre Mitgliedschaft bei  {selectedDataspaceName} verwalten möchten, wenden Sie sich an unseren Support:",
      "uncoverByInvitation": "Per Einladung entdecken",
      "dataspaceDetails": "Datenraum Details",
      "participantOverview": "Teilnehmerübersicht",
      "services": "Dienste",
      "joinRequestSuccessTitle": "Ihr Antrag auf Beitritt zum {dataspaceName} wurde übermittelt.",
      "joinRequestSuccessBody": "Weitere Informationen erhalten Sie, sobald Ihre Anfrage bearbeitet wurde",
      "uncover": {
        "title": "Datenraum nach Einladung anzeigen",
        "subtitle": "Nach welchem Datenraum suchen Sie?",
        "fieldLabels": "Vom Datenraum Betreiber bereitgestellte Informationen eingeben",
        "sendRequest": "Anfrage senden",
        "back": "Zurück",
        "dataspaceId": "Datenraum ID",
        "password": "Passwort"
      },
      "requestDetailsModal": {
        "withdraw": "Zurückziehen",
        "cancel": "Abbrechen",
        "update": "Aktualisieren",
        "withdrawConfirmationHeading": "Dataspace Join Anfrage zurückziehen",
        "withdrawConfirmationBody": "Bitte bestätigen Sie erneut, dass Sie beabsichtigen, Ihren Antrag auf Beitritt zum Datenraum {dataspaceName} zurückzuziehen.",
        "notification": {
          "withdrawSuccessfulTitle": "Dataspace Join Anfrage zurückgezogen",
          "withdrawSuccessfulBody": "Ihr Antrag auf Beitritt zu {dataspaceName} wurde zurückgezogen.",
          "updateSuccessfulTitle": "Anfrage auf Beitritt zum Datenraum aktualisiert",
          "updateSuccessfulBody": "Ihre Anfrage zum Beitritt zu {dataspaceName} wurde aktualisiert."
        }
      }
    },
    "user": {
      "myMemberships": "Meine Mitgliedschaften",
      "enterDS": "Datenraum betreten",
      "registeredDS": "Ihre Datenräume",
      "noMembership": {
        "title": "Sie verfügen noch nicht über eine Mitgliedschaft",
        "description": "Datenräume durchsuchen und einem von ihnen beitreten",
        "actionButtonText": "Get Connected - Verbinden"
      }
    },
    "statuses": {
      "attentionRequired": "Aufmerksamkeit erforderlich",
      "comingSoon": "Demnächst",
      "connected": "Verbunden",
      "disconnected": "Nicht verbunden",
      "deploying": "Wird bereitgestellt",
      "deployed": "Bereitgestellt",
      "failed": "Fehlgeschlagen",
      "available": "Verfügbar",
      "success": "Erfolgreich"
    }
  },
  "verify": {
    "orgProfile": "Organisationsprofil",
    "legalName": "Juristische Bezeichnung",
    "address": "Anschrift",
    "contact": "Kontakt",
    "vatID": "USt IdNr.",
    "requestChange": "Änderung anfordern",
    "downloadVCMessage": "VC erfolgreich heruntergeladen",
    "vcOverview": {
      "masterHeading": "Arten von Anmeldeinformationen",
      "credentialTypeCards": {
        "description": {
          "legalPerson": "Dies ist eine digitale Darstellung von Informationen über juristische Personen, wie z. B. die Registrierungsdetails, die Unternehmensidentifikation oder den rechtlichen Status der Organisation",
          "serviceOffering": "Dies ist eine Sammlung von Vermögenswerten und Ressourcen, die ein Anbieter zu einem Paket kombiniert, um es den Verbrauchern anzubieten",
          "physicalResource": "Dies ist eine Ressource mit einem messbaren Gewicht und einer räumlichen Position, die eine greifbare Entität darstellt, die als Wirt, Manipulator oder Teilnehmer an Interaktionen mit anderen physischen Entitäten dient",
          "softwareResource": "Dies ist eine Art von Asset, das nicht-physische Funktionalitäten wie Quellcode umfasst",
          "dataResource": "Dies ist eine Art von Asset, das aus Daten (einschließlich abgeleiteter Daten) in beliebiger Form besteht und die notwendigen Informationen für den Datenaustausch enthält"
        },
        "cta": {
          "enter": "Enter"
        }
      },
      "credentialOverview": "Übersicht über die Anmeldeinformationen",
      "credentialErrorMessage": "Fehler beim Abrufen von {type}-Anmeldeinformationen",
      "searchByNamePlaceholder": "Suche nach Namen",
      "credentialErrorDetailsMessage": "Derzeit haben wir technische Schwierigkeiten beim Abrufen von Anmeldeinformationen, bitte versuchen Sie es nach einiger Zeit erneut"
    },
    "verifiableCredentials": {
      "getVerified": "Verifizieren lassen",
      "credentials": "Berechtigungsnachweise",
      "requestCredential": "Berechtigungsnachweis beantragen",
      "whichTypeToSelect": "Welchen Typ soll ich wählen?",
      "noCredentialsMessage": "Sie haben noch keine {type}-Berechtigungsnachweise",
      "credentialInfoMessage": "Durch Hinzufügen von Berechtigungsnachweisen erhöhen Sie Ihren Trust Level / Vertrauensniveau unter den Mitgliedern eines Ökosystems.",
      "credentialModalMessage": "Berechtigungsnachweise sind die Qualifikationen oder Informationen, die die Identität, Expertise oder Legitimität eines Unternehmens belegen.",
      "learnMore": "Mehr erfahren",
      "status": {
        "all": "Alle",
        "valid": "Gültig",
        "expired": "Abgelaufen",
        "revoked": "Widerrufen"
      },
      "types": {
        "legalPerson": "Unternehmensidentifikation",
        "serviceOffering": "Compliance und Zertifizierung",
        "physicalResource": "Physische Ressourcen",
        "dataResource": "Datenressourcen",
        "softwareResource": "Software-Ressourcen",
        "membership": "Mitgliedschaften"
      },
      "form": {
        "type": {
          "placeholder": "Wählen Sie Ihren Anmeldeinformationstyp aus",
          "label": "Typ der Anmeldeinformationen",
          "error": "Der Anmeldeinformationstyp ist erforderlich"
        },
        "types": {
          "legalPerson": "Juristische Person",
          "serviceOffering": "Serviceangebot",
          "physicalResource": "Physische Ressource",
          "resource": "Ressource",
          "dataResource": "Datenressource",
          "softwareResource": "Software-Ressource"
        },
        "companyInformationSectionTitle": "Unternehmensinformationen",
        "companyInformationSectionSubtitle": "Unternehmensinformationen eingeben",
        "legalName": {
          "label": "Juristische Bezeichnung der Organisation",
          "helpText": "Rechtsverbindlichen Namen der Organisation verwenden"
        },
        "country": {
          "placeholder": "Land auswählen",
          "label": "Land",
          "error": "Land ist erforderlich"
        },
        "region": {
          "placeholder": "Region wählen",
          "label": "Region",
          "error": "Region ist erforderlich"
        },
        "street": {
          "label": "Straße und Hausnummer"
        },
        "city": {
          "label": "Stadt"
        },
        "postalCode": {
          "label": "Postleitzahl"
        },
        "commercialIdentificationSectionTitle": "Kommerzielle Identifikation",
        "commercialIdentificationSectionSubtitle": "Zum Fortfahren eine der folgenden eindeutigen IDs eingeben*",
        "CRN": {
          "label": "Handelsregisternummer",
          "helpText": "Beispiel: HRB 55933",
          "tooltipText": "Bei der Handelsregisternummer handelt es sich um eine eindeutige Kennung, die Unternehmen für ihre handelsrechtliche Registrierung zugewiesen wird. Sie wird zur Validierung und Verifizierung der Authentizität der Registrierung eines Unternehmens und der Kennungen von Finanztransaktionen auf Grundlage vertrauenswürdiger Datenquellen genutzt."
        },
        "vatId": {
          "label": "USt IdNr.",
          "helpText": "Beispiel: DE 118 645 675",
          "tooltipText": "Eine USt-IdNr. (Umsatzsteueridentifikationsnummer) ist eine eindeutige Kennung, die Unternehmen für Steuerzwecke zugewiesen wird."
        },
        "eori": {
          "label": "EORI",
          "helpText": "Beispiel: DE 103 280 493 572",
          "tooltipText": "Die EORI-Nummer (EORI: Economic Operators’ Registration and Identification) ist eine eindeutige Kennung, die Wirtschaftsteilnehmern zugewiesen wird, die innerhalb der Europäischen Union (EU) im Außenhandel tätig sind."
        },
        "lei": {
          "label": "LEI",
          "helpText": "Beispiel: 5299009D9BIL4D4UHT93",
          "tooltipText": "Der LEI (Legal Entity Identifier) ist ein eindeutiger Identifikationscode, der juristischen Personen zugewiesen wird, die Finanztransaktionen auf globalen Finanzmärkten tätigen."
        },
        "didSectionTitle": "Decentralized Identifier (DID)",
        "didSubSectionTitle": "Wenn Sie keine DID haben, lassen Sie dieses Feld bitte leer, es wird automatisch generiert.",
        "did": {
          "label": "Berechtigungsnachweis Subjekt (DID)",
          "helpText": "did:example123456789abcd",
          "tooltipText": "Eine DID ist eine eindeutige Kennung, die in ein DID Dokument aufgelöst werden kann. DIDs sind die Grundbausteine ​​einer dezentralen digitalen Identität."
        },
        "request": "Anfrage",
        "requesting": "Anfragen...",
        "certificateSectionHeading": "Unterschrift",
        "certificateSectionTitle": "Welches Zertifikat möchten Sie für die Signatur verwenden?",
        "addCertificate": "Neues Zertifikat hinzufügen",
        "chooseCertificate": "Wählen Sie Ihr Zertifikat",
        "tsystemsCertificate": "T-Systems Zertifikat",
        "yourCertificate": "Ihr Zertifikat",
        "consentTooltip": "Wenn Sie diese Option auswählen, erlauben Sie T-Systems, das oben genannte Dokument in Ihrem Namen zu unterzeichnen. Weitere Informationen finden Sie in der Dokumentation",
        "consentPersonalData": "Hiermit stimme ich zu und bestätige, dass alle meine vorstehend aufgeführten persönlichen Informationen und Daten („personenbezogene Daten“) korrekt sind.",
        "consentProcessing": "Ich willige hiermit ein und ermächtige Gaia-X zur Verarbeitung meiner Daten (einschließlich aller sensiblen personenbezogenen Daten)."
      },
      "vcRequestSuccess": "Berechtigungsnachweis erfolgreich signiert",
      "selectAllButton": "Alle auswählen",
      "deselectAllButton": "Gesamte Auswahl aufheben",
      "revokeButton": "Widerrufen",
      "searchboxPlaceholder": "Mindestens 3 Zeichen eingeben",
      "credentialTile": {
        "revokeButton": "Widerrufen",
        "issuedBy": "Ausgestellt von",
        "issuedOn": "Ausgestellt am",
        "expiresOn": "Läuft ab am",
        "expiredOn": "Abgelaufen am",
        "revokedOn": "Widerrufen am",
        "viewDetailsButton": "Details anzeigen"
      },
      "revokeModal": {
        "heading": "Bestätigung widerrufen",
        "question": "Möchten Sie die Position wirklich widerrufen?",
        "note": "HINWEIS:",
        "message": "Nach dem Widerruf eines Berechtigungsnachweises wird dieser ungültig und kann nicht mehr verwendet werden. Um einen gültigen Berechtigungsnachweis zu erhalten, müssen Sie einen neuen beantragen.",
        "primaryButton": "Widerrufen",
        "secondaryButton": "Abbrechen"
      },
      "credentialsDetailsModal": {
        "heading": "Details zu den Anmeldeinformationen",
        "selfDescriptionCredential": "Berechtigungsnachweis Selbstbeschreibung",
        "type": "Art",
        "id": "id",
        "issuer": "Emittent",
        "issuanceDate": "Ausgabedatum",
        "expirationDate": "Verfallsdatum",
        "credentialSubject": "credentialSubject",
        "bpn": "ctxsd:bpn",
        "participantName": "gx-participant:name",
        "participantNameLegalName": "gx-participant:legalName",
        "legalRegistrationNumber": "gx:participant:legalRegistrationNumber",
        "EUID": "gx:EUID",
        "leiCode": "gx:leiCode",
        "vatID": "gx:vatID",
        "registrationNumberType": "gx:registrationNumberType",
        "registrationNumberNumber": "gx:registrationNumberNumber",
        "headquarterAddress": "gx:participant:headquarterAddress",
        "addressCountryCode": "gx:addressCountryCode",
        "countrySubdivisionCode": "gx:countrySubdivisionCode",
        "locality": "gx:locality",
        "streetAddress": "gx:streetAddress",
        "legalAddress": "gx:participant:legalAddress",
        "termsAndConditions": "gx-participant:termsAndConditions",
        "serviceOfferingName": "gx:serviceOfferingName",
        "serviceOfferingDescription": "gx:serviceOfferingDescription",
        "providedBy": "gx:providedBy",
        "aggregationOf": "gx:aggregationOf",
        "aggregationOfId": "gx:id",
        "policy": "gx:policy",
        "serviceOfferingTermsAndConditions": "gx:termsAndConditions",
        "url": "gx:URL",
        "hash": "gx:hash",
        "dataAccountExport": "gx:dataAccountExport",
        "requestType": "gx:requestType",
        "accessType": "gx:accessType",
        "formatType": "gx:formatType",
        "proof": "Nachweis",
        "created": "erstellt",
        "proofPurpose": "Beweis Zweck",
        "verificationMethod": "Verifizierungsmethode",
        "jws": "jws",
        "status": "Status",
        "primaryButton": "Herunterladen",
        "secondaryButton": "Abbrechen",
        "resourceName": "gx:name",
        "resourceDescription": "gx:description",
        "resourceCopyrightOwnedBy": "gx:copyrightOwnedBy",
        "resourceExposedThrough": "gx:exposedThrough",
        "resourceProducedBy": "gx:producedBy",
        "resourcePolicy": "gx:policy",
        "resourceLicense": "gx:license",
        "resourceContainsPII": "gx:containsPII",
        "resourceMaintainedBy": "gx:maintainedBy",
        "resourceOwnedBy": "gx:ownedBy",
        "resourceManufacturedBy": "gx:manufacturedBy",
        "resourceCountryCode": "Landescode",
        "resourceLocality": "Örtlichkeit",
        "resourcePostalCode": "Postleitzahl",
        "resourceStreetAddress": "Straße",
        "legalPersonRegistrationNumber": "gx:lagelRegistrationNumber"
      },
      "notificationText": "Leider wurden keine Ergebnisse gefunden, die Ihren Suchkriterien entsprechen. Verfeinern Sie Ihre Suche, und versuchen Sie es erneut.",
      "bulkUpload": {
        "title": "Partner validieren",
        "helpText": "Validieren Sie Ihre Geschäftspartner auf einmal, indem Sie die bereitgestellte Vorlage ausfüllen",
        "uploadFileTitle": "Datei hochladen",
        "requestCredentials": "Berechtigungsnachweise beantragen",
        "uploadFormHelpHeading": "Zur Validierung mehrerer Unternehmen führen Sie folgende Schritte aus:",
        "uploadFormHelpText1": "Laden Sie die Vorlagendatei über den folgenden Link herunter",
        "uploadFormHelpText2": "Tragen Sie die Infos zu Geschäftspartnern in die bereitgestellte Datei ein",
        "uploadFormHelpText3": "Ziehen Sie die Datei in den Upload Bereich unten",
        "downloadTemplateBtn": "Vorlage herunterladen",
        "partnerValidationHelpText": "Wenn Sie mehr über die Partnervalidierung erfahren möchten, wenden Sie sich bitte an uns",
        "partnerValidationHelpLink": "klicken Sie hier",
        "uploadFile": {
          "uploadFileHeading": "Wählen Sie eine CSV Datei zum Hochladen aus"
        },
        "vcProcessingTitle": "Bearbeitung von Aufzeichnungen",
        "bulkVcContinueBtn": "Weitermachen",
        "issuingProgressMessage": "Die Ausstellung der Berechtigungsnachweise ist im Gange...",
        "doNotCloseWarning": "Bitte schließen Sie das Formular erst, wenn die Bearbeitung abgeschlossen ist",
        "bulkVcDownloadReportBtn": "Bericht herunterladen",
        "successMessage": "Erfolgreich abgeschlossen!",
        "failedErrorMessage": "Fehler in {result}-Einträgen",
        "processAbortTitle": "wird bearbeitet",
        "processAbortDescription": "Sind Sie sicher, dass Sie die Verarbeitung stoppen möchten?",
        "stopProcessingBtn": "Verarbeitung stoppen"
      }
    },
    "serviceOfferingForm": {
      "generalInformationHeading": "Allgemeine Informationen",
      "labelSOName": "Name des Serviceangebots",
      "labelProvidedBy": "Zur Verfügung gestellt von",
      "labelSODescription": "Beschreibung des Serviceangebots",
      "hintSOName": "Lesbarer Name des Serviceangebots",
      "hintProvidedBy": "Auflösbarer Link in DID Form zur Selbstbeschreibung des Teilnehmers, der die Dienstleistung erbringt",
      "hintSODescription": "Eine Liste von Richtlinien, die über DSL ausgedrückt werden (z. B. Rego oder ODRL, Zugriffskontrolle, Drosselung, Nutzung, Aufbewahrung usw.)",
      "resourcesHeading": "Ressourcen",
      "labelSOResources": "Selbstbeschreibung der Ressource",
      "hintSOResources": "Auflösbarer Link in DID Form der Ressourcenselbstbeschreibung, der sich auf den Dienst bezieht und unabhängig davon existieren kann",
      "termsAndConditionsHeading": "Geschäftsbedingungen",
      "labelUrlSO": "URL",
      "labelHashSO": "Hash",
      "labelSOPolicy": "Politik",
      "policyTooltip": "Auflösbarer Link zu der Richtlinie, die für das Serviceangebot gilt",
      "termsAndConditionsTooltip": "Auflösbarer Link zu den Allgemeinen Geschäftsbedingungen, die für das Serviceangebot gelten",
      "personalDEHeading": "Export persönlicher Daten",
      "personalDETooltip": "Ermöglicht Kunden des Dienstes, persönliche und nicht personenbezogene Daten aus dem Dienst zu exportieren.",
      "labelRequestTypeSO": "Anfragetyp",
      "labelAccessTypeSO": "Zugangsart",
      "placeholderRequestTypeSO": "Wählen Sie den Anforderungstyp aus",
      "placeholderAccessTypeSO": "Wählen Sie den Zugriffstyp aus",
      "labelFormatTypeSO": "Formattyp",
      "hintRequestTypeSO": "Das Mittel zum Abrufen von Daten: API, E-Mail, Webformular, unregisteredLetter, RegisteredLetter, SupportCenter",
      "hintAccessTypeSO": "Art der Datenunterstützung: digital, physisch",
      "hintFormatTypeSO": "Art der Medientypen (MIME-Typen), wie definiert durch",
      "consentTC1": "Hiermit stimme ich zu und bestätige, dass alle oben angegebenen Daten korrekt sind",
      "consentTC2": "Hiermit erkläre ich mich einverstanden und ermächtige T-Systems zur Verarbeitung meiner Daten (einschließlich aller personenbezogenen sensiblen Daten).",
      "consentTC3": "Hiermit stimme ich dem zu",
      "gaiaXTermsText": "Allgemeine Geschäftsbedingungen von Gaia-X",
      "requestTypesList": {
        "api": "API",
        "email": "Email",
        "webform": "Web Formular",
        "unregisteredLetter": "Nicht registrierter Brief",
        "registeredLetter": "Eingeschriebener Brief",
        "supportCenter": "Hilfecenter"
      },
      "requestTypesError": "Anfragetyp ist erforderlich",
      "accessTypesList": {
        "digital": "Digital",
        "physical": "Physisch"
      },
      "accessTypesError": "Der Zugriffstyp ist erforderlich",
      "helpSOName": "Bitte geben Sie einen menschenlesbaren Namen Ihres Dienstes ein.",
      "helpProvidedBy": "Dezentrale Kennung des Teilnehmers, der die Dienstleistung erbringt",
      "helpSOResources": "Dezentrale Bezeichner der Ressourcen, die über diesen Dienst verfügbar gemacht werden",
      "helpUrlSO": "Bitte geben Sie den Link zu Ihrem AGB-Dokument ein (z.B. https://service.com/terms)",
      "helpHashSO": "Bitte geben Sie einen sha256-Hash Ihres AGB-Dokuments ein",
      "helpPolicySO": "Bitte geben Sie eine Richtlinie ein, die über eine DSL ausgedrückt wird (z. B. Rego oder ODRL)",
      "helpRequestTypeSO": "Bitte wählen Sie einen Anfragetyp aus der Dropdown-Liste aus",
      "helpAccessTypeSO": "Bitte wählen Sie einen Zugriffstyp aus der Dropdown-Liste aus",
      "helpFormatTypeSO": "Bitte geben Sie den MIME-Typ ein (z.B. application/json)"
    },
    "resourceForm": {
      "generalInformationHeading": "Allgemeine Informationen",
      "generalInformationSubHeading": "Bitte geben Sie Ihre Ressource ein Allgemeine Informationen",
      "labelResourceType": "Art der Ressource",
      "placeholderResourceType": "Ressourcentyp auswählen",
      "errorResourceType": "Ressourcentyp ist erforderlich",
      "labelResourceName": "Name der Ressource",
      "labelResourceDescription": "Beschreibung der Ressource",
      "hintResourceType": "Typ der beschriebenen Ressource",
      "hintResourceName": "Lesbarer Name der Ressource",
      "hintResourceDescription": "Lesbare Beschreibung der Ressource",
      "physicalResource": "Physische Ressource",
      "softwareResource": "Software-Ressource",
      "dataResource": "Datenressource",
      "additionalInformationHeading": "Zusatzinformation",
      "additionalInformationSubHeading": "Bitte geben Sie Ihre Ressource ein Zusätzliche Informationen",
      "labelMaintainedBy": "Gepflegt von",
      "hintMaintainedBy": "Auflösbarer Link zu Gaia-X Credential des Teilnehmers, der die Ressource in betriebsbereitem Zustand hält und somit physischen Zugriff darauf hat",
      "labelOwnedBy": "Im Besitz von",
      "hintOwnedBy": "Auflösbarer Link zu Gaia-X Credential des Teilnehmers, der die Ressource besitzt",
      "labelManufacturedBy": "Hergestellt von",
      "hintManufacturedBy": "Auflösbarer Link zu Gaia-X Credential des Teilnehmers, der die Ressource herstellt",
      "labelCountry": "Land",
      "hintCountry": "Land, in dem sich die Ressource befindet",
      "labelCity": "Stadt",
      "hintCity": "Stadt / Ort, in dem sich die Ressource befindet",
      "labelPostCode": "Postleitzahl",
      "hintPostCode": "Postleitzahl, in der sich die Ressource befindet",
      "labelStreet": "Straße",
      "hintStreet": "Straße und Hausnummer, in der sich die Ressource befindet",
      "labelCopyrightOwnerBy": "Copyright-Inhaber von",
      "hintCopyrightOwnerBy": "Ein Urheberrechtsinhaber ist eine Person oder Organisation, die das Recht hat, die Ressource zu nutzen. Urheberrechtsinhaber bezieht sich nicht unbedingt auf den Autor der Ressource, der eine natürliche Person ist und vom Urheberrechtsinhaber abweichen kann. Entweder als Freitext oder als auflösbarer Link zu Gaia-X Credential des Urheberrechtsinhabers.",
      "labelLicense": "Lizenz",
      "hintLicense": "URL des Lizenzdokuments oder einer der Lizenzen aus dem",
      "labelPolicy": "Politik",
      "hintPolicy": "Eine Liste von Richtlinien, die über eine DSL (z. B. Rego oder ODRL) ausgedrückt werden (Zugriffskontrolle, Drosselung, Verwendung, Aufbewahrung, ...). Wenn die Richtlinieneinschränkung weggelassen wird, wird davon ausgegangen, dass die Verwendung allen Teilnehmern gestattet ist",
      "labelProducedBy": "Produziert von",
      "hintProducedBy": "Auflösbarer Link zum Gaia-X Credential des Teilnehmers, der die Datennutzung rechtmäßig ermöglicht.",
      "labelExposedThrough": "Exponiert durch",
      "hintExposedThrough": "Auflösbarer Link zu der Datenaustauschkomponente, die die Datenressource verfügbar gemacht hat",
      "locationHeading": "Angaben zum Standort",
      "locationSubHeading": "Bitte geben Sie die Details Ihres Ressourcenstandorts ein",
      "licenseTooltipText": "SPDX-Lizenzliste",
      "licenseTooltipLink": "https://spdx.org/licenses/",
      "consentPII": "Hiermit bestätige ich, dass die Datenressource keine personenbezogenen Daten (PII) enthält."
    },
    "verifyLanding": {
      "heading": "Willkommen zu “Get verified”",
      "subHeading": "Erhöhen Sie das Vertrauen Ihres digitalen Ökosystems!",
      "description1": "Sie möchten Ihre Geschäftspartner verifizieren und validieren oder Ihre eigenen Ausweise und Nachweise vorlegen? Dann sollten Sie Ihre ersten nachweisbaren Anmeldeinformationen anfordern – unsere digitalen. ID-Produkt.",
      "description2": "Überprüfbare Anmeldeinformationen können die gleichen Informationen enthalten wie Ihre physischen Ausweise (z. B. Personalausweis, Führerschein) und sogar noch mehr Attribute. Durch den Einsatz von Technologien wie digitalen Signaturen sind überprüfbare Ausweise fälschungssicherer und vertrauenswürdiger als ihre physischen Gegenstücke.",
      "description3": "T-Systems fungiert als einer der ersten Gaia-X Digital Clearing House Nodes und stellt Ihre digitalen Proofs gemäß den Gaia-X Trust Framework Spezifikationen aus. Darüber hinaus fügen wir unsere eIDAS konforme digitale Signatur unseres Telekom Security-Partners hinzu.",
      "requestButton": "Anfrage Digital.ID",
      "digitalID": "Digital.ID Produkt.",
      "consultation" : {
        "heading": "Fordern Sie Ihre Anmeldeinformationen an oder validieren Sie Ihre Partner",
        "placeholder": "Gibt es bereits Erfahrungen mit überprüfbaren Anmeldeinformationen? Interessiert an Ihren ersten Zeugnissen?"
      },
      "sendAnotherRequest": "Senden Sie eine weitere Anfrage",
      "lastRequest": "Ihre letzte Anfrage wurde gesendet"
    },
    "getInsights": {
      "tab1": "Berichte",
      "tab2": "API Nutzungsbeschränkungen",
      "tab3": "Technische Integration",
      "monthsByOptions": {
        "option1": "3 Monate",
        "option2": "6 Monate",
        "option3": "1 Monat"
      },
      "table": {
        "heading2": "Bezugszeitraum",
        "heading3": "Erstellt am",
        "heading4": "Aktionen"
      },
      "successDownload":"Bericht(e) erfolgreich heruntergeladen",
      "errorDownload":"Der Download Ihrer Datei für {filename} wurde mit einem Fehler abgebrochen. Bitte versuchen Sie es erneut.",
      "apiUsageLimit": {
        "requestNameTitle": "Fordert Namen an",
        "dailyTitle": "Tägliche API-Anfragen",
        "monthlyTitle": "Monatliche API-Anfragen",
        "limitexhaustedWarning": "Weniger als 10 % der Anfragen übrig",
        "limitExtentionHeading": "Limitverlängerung beantragen",
        "limitExtentionPlaceholder": "Sind Sie an einer Verlängerung Ihrer API-Nutzungsbeschränkungen interessiert?",
        "supportBtn": "Unterstützung",
        "limitExtentionText": "Stehst du kurz vor dem Limit? Nehmen Sie Kontakt mit uns auf und lassen Sie uns das Problem lösen!"
      },
      "addIntegration": "Integration hinzufügen",
      "integrationData": {
        "clientId": "Client-ID",
        "createdAt": "Erstellt am",
        "actions": "Aktionen"
      }
    },
    "addIntegration": {
      "generalInformationHeading": "Allgemeine Informationen",
      "addAnIntegration": "Eine Integration hinzufügen",
      "clientName": "Client Name",
      "clientId": "Client-ID",
      "clientSecret": "Client Geheimnis",
      "tokenUrl": "Token-URL",
      "pcCallbackUrl": "Teilnehmer-Berechtigungs-Callback-URL",
      "soCallbackUrl": "Serviceangebot-Berechtigungs-Callback-URL",
      "digitalIdAuthenticateInfo1": "Wie wird die Digital.ID-Validierung Ihr System authentifizieren?",
      "digitalIdAuthenticateInfo2": "Wie wird die Digital.ID-Compliance Ihr System authentifizieren?",
      "seeResults": "Wohin sollen wir die Ergebnisse senden?",
      "requestSuccess": "erfolgreich hinzugefügt",
      "callbackSuccess": "Callback erfolgreich hinzugefügt",
      "step1Name": "Validierungsauthentifizierungsdetails",
      "step2Name": "Konformitätsauthentifizierungsdetails",
      "step3Name": "Rückrufinformationen",
      "deleteSuccess": "Erfolgreich gelöscht",
      "downloadSuccess": "Erfolgreich heruntergeladen",
      "editCallbackHeading": "Hinzufügen eines Callbacks",
      "deleteModal": {
        "heading": "Bestätigung löschen",
        "deleteContent": "Sind Sie sicher, dass Sie den Client löschen möchten?",
        "deleteNote": "Nach dem Löschen eines Clients verschwindet er aus der Liste und kann nicht mehr verwendet werden. Um es wiederherzustellen, müssen Sie es von Grund auf neu erstellen."
      }
    },
    "mySignatures": {
      "certificatesOrdering": {
        "heading": "Willkommen bei \"Meine Unterschriften\"",
        "subHeading": "Möchten Sie ein digitales Zertifikat anfordern, mit dem Sie Ihre Forderungen signieren können?",
        "description1": "Mit unserem Digital.ID Produkt können Sie ganz einfach Ihr erstes Zertifikat anfordern und die Vorteile einer sicheren digitalen Zusammenarbeit genießen.",
        "description2": "Überprüfbare Anmeldeinformationen können dieselben Informationen enthalten wie Ihre physischen Identifikatoren, z. B. Personalausweise und Führerscheine, und sogar noch mehr. Während des Ausstellungsprozesses bestätigen Sie die Richtigkeit Ihrer Daten, indem Sie sie mit Ihrem eigenen vertrauenswürdigen Zertifikat unterschreiben.",
        "description3": "Sie können Ihre digitalen Zertifikate für alle Ihre Signaturanforderungen sicher anfordern und verwalten. Wir arbeiten ausschließlich mit zertifizierten Anbietern digitaler Zertifikate zusammen, um sicherzustellen, dass Ihre digitalen Zertifikate sowohl sicher als auch vertrauenswürdig sind.",
        "requestButton" : "Zertifikat anfordern",
        "consultation" : {
          "heading": "Zertifikat anfordern",
          "placeholder": "Bitte geben Sie die erforderlichen Details zu Ihrer Anfrage ein"
        }
      },
      "certificateOverview": {
        "heading": "Meine Signaturen",
        "requestCertificateCTA": "Zertifikat anfordern",
        "placeholderCertificateType": "Typ des Zertifikats",
        "placeholderCertificateStatus": "Status des Zertifikats",
        "EVSSL": "EV SSL",
        "EIDAS": "EIDAS",
        "headingEVSSL": "EV SSL-Zertifikate",
        "headingEIDAS": "EIDAS-Zertifikate",
        "certificateDetailsHeading": "Details zum Zertifikat",
        "certificateName": "Name",
        "certificateId": "Ausweis",
        "certificateType": "Typ",
        "provider": "Anbieter",
        "organizationId": "Organisations-ID",
        "UUID": "UUID",
        "certificate": "Zertifikat",
        "createdAt": "Erstellt am",
        "updatedAt": "Aktualisiert am",
        "certificateExpiry": "Läuft ab am",
        "certificateVerificationMethod": "Methode zur Verifizierung",
        "certificateDidJson": "DID JSON",
        "status": "Status",
        "certificateDownloadSuccess": "Zertifikat erfolgreich heruntergeladen",
        "deleteCertificate": {
          "heading": "Bestätigung löschen",
          "question": "Sind Sie sicher, dass Sie das Zertifikat löschen möchten?",
          "deleteSuccess": "Zertifikat erfolgreich gelöscht"
        }
      }
    }
  },
  "build": {
    "ownedDataspaces": "Meine Datenräume",
    "checkConsent": "Ich stimme zu, dass meine Kontaktdaten an ein Vertriebsteam von T-Systems weitergegeben werden und ich kontaktiert werde.",
    "welcomeOwnedDataspace": "Willkommen zu „Eigene Datenräume“",
    "resendInvitation": "Einladung erneut senden",
    "resendInvitationInfo": "Sie können die Benachrichtigung senden, sobald seit der letzten Einladungsmail 24 Stunden vergangen sind.",
    "requestSuccessTitle": "Erfolgreiche Anfrage!",
    "requestSuccessBody": "Sie haben eine Anfrage gesendet... Sie erhalten innerhalb weniger Werktage eine Antwort",
    "requestAuthorisation": "Bitte wenden Sie sich an dih.support{'@'}telekom.de, wenn Sie die erforderliche Autorisierung erhöhen möchten.",
    "inviteParticipant": {
      "buttonLabel": "Teilnehmer einladen",
      "successNotificationTitle": "Einladung erfolgreich!",
      "successNotificationContent": "Einladung wurde erfolgreich versendet",
      "alreadySentNotificationTitle": "Diese E-Mail Adresse hat bereits eine Einladung erhalten.",
      "alreadySentNotificationContent": "Bitte warten Sie 24 Stunden, bis Sie eine neue Einladung versenden.",
      "resendInviteNotificationTitle": "Erneute Einladung erfolgreich!",
      "resendInviteNotificationContent": "Ihre erneute Einladung wurde erfolgreich versendet",
      "modal": {
        "title": "Teilnehmer einladen",
        "informationTitle": "Information",
        "informationText": "Die eingeladene Organisation kann Daten mit anderen Teilnehmern austauschen und neue Datensätze im Dataspace veröffentlichen. Außerdem kann die Organisation Applikationen und Dienste, die Teil Ihres Dataspace sind, in Anspruch nehmen.",
        "formTitle": "Bitte geben Sie die Daten des neuen Teilnehmer ein",
        "organizationName": "Name der Organisation",
        "proceed": "Fortfahren",
        "cancel": "Stornieren"
      },
      "deleteInvitation": {
        "modal": {
          "title": "Einladung löschen",
          "informationText": "Bitte bestätigen Sie erneut, dass Sie beabsichtigen, diese Einladung in den Datenraum zu löschen. Diese Aktion kann nicht rückgängig gemacht werden.",
          "successNotificationTitle": "Löschen erfolgreich",
          "successNotificationBody": "Einladung erfolgreich gelöscht",
          "cancel": "Abbrechen",
          "delete": "Löschen"
        }
      }
    },
    "editParticipant": {
      "modal": {
        "title": "Teilnehmer bearbeiten",
        "cancel": "Abbrechen",
        "save": "Speichern",
        "roleSection": {
          "name": "Rolle",
          "helpText": "Welche Rolle soll ich wählen?",
          "helpUrl": "https://docs.dih.telekom.com/en/space/living-lab/manage-your-livinglab",
          "ownerLabel": "Datenraum-Eigentümer",
          "ownerTooltip": "Eigentümer des Datenraums, der Teilnehmer und Anwendungen verwalten kann",
          "operatorLabel": "Datenraum-Betreiber",
          "operatorTooltip": "Teilnehmer, der das Betreiber-Toolkit verwalten und Anwendungen registrieren kann",
          "operatorCheckboxTooltip": "Sie sind der Eigentümer des Datenraums. Daher können Sie die Option Betreiber nicht deaktivieren.",
          "appProviderLabel": "Anwendungsanbieter",
          "appProviderTooltip": "Datenraum-Teilnehmer, der Anwendungen im Datenraum registrieren kann",
          "participantLabel": "Datenraum-Teilnehmer",
          "participantTooltip": "Teilnehmer, der als Datenanbieter und Datenkonsument agieren kann",
          "participantCheckboxTooltip": "Der Dataspace Teilnehmer kann nicht deaktiviert werden. Das ist der Standardstatus eines Benutzers in einem Datenraum."
        },
        "visibilitySection": {
          "name": "Sichtbarkeit",
          "helpText": "Welche Sichtbarkeit soll ich wählen?",
          "helpUrl": "https://docs.dih.telekom.com/en/space/living-lab/manage-your-livinglab",
          "anonymousLabel": "Anonym",
          "anonymousLabelTitle": "Anonym Teilnehmer",
          "anonymousTooltip": "Teilnehmer, der für andere Teilnehmer ausgeblendet ist und andere Teilnehmer nicht sehen kann"
        }
      },
      "updateSuccessfulTitle": "Update erfolgreich!",
      "updateSuccessfulBody": "Neue Rolle und Sichtbarkeit wurden übernommen"
    },
    "applicationManagement": {
      "buttonLabel": "Applikation registrieren",
      "registerForm": {
        "pageTitle": "Applikation Registrierung",
        "step1Name": "Applikation details",
        "step2Name": "Zugang zur Applikation",
        "displayName": "Name der Applikation",
        "providedBy": "Anbieter:",
        "providedByPlaceholder": "Name der Organisation",
        "description": "Beschreibung",
        "descriptionPlaceholder": "Beschreibungstext",
        "link": "Link",
        "linkPlaceholder": "https://www.application-link-example.org/",
        "agreementCheckboxLabel": "Ich erkläre hiermit, dass ich für die von mir registrierte Anwendung voll verantwortlich und haftbar bin und dass es sich nicht um Schadsoftware jeglicher Art handelt. Mir ist bekannt, dass ich für die Zugangsverwaltung der von mir zu registrierenden Anwendung voll verantwortlich bin und die T-Systems International GmbH diesbezüglich keine Verantwortung trägt. Die T-Systems International GmbH ist nicht verantwortlich oder haftbar für die zu registrierende Anwendung und deren mögliche Folgen in jeglicher Form."
      },
      "deleteApplication": {
        "modal": {
          "title": "Anwendung löschen",
          "informationText1": "Bitte bestätigen Sie noch einmal, dass Sie diesen Bewerbungslink wirklich aus Ihrem Datenraum löschen möchten. Diese Anwendung wird dann für keinen Teilnehmer in Ihrem Datenraum mehr verfügbar sein.",
          "informationText2": "Bitte beachten Sie, dass die eigentliche Bewerbung davon nicht betroffen ist!",
          "successNotificationTitle": "Löschen erfolgreich",
          "successNotificationBody": "Anwendung erfolgreich gelöscht.",
          "cancel": "Abbrechen",
          "delete": "Löschen"
        }
      },
      "editForm": {
        "pageTitle": "Applikation Registrierung",
        "step1Name": "Applikation details",
        "step2Name": "Zugang zur Applikation",
        "displayName": "Name der Applikation",
        "providedBy": "Anbieter:",
        "providedByPlaceholder": "Organization name",
        "description": "Beschreibung",
        "descriptionPlaceholder": "Beschreibungstext",
        "link": "Link",
        "successNotificationTitle": "Bearbeiten Erfolgreich",
        "successNotificationBody": "Bewerbung erfolgreich bearbeitet",
        "linkPlaceholder": "https://www.application-link-example.org/",
        "agreementCheckboxLabel": "Ich erkläre hiermit, dass ich für die von mir registrierte Anwendung voll verantwortlich und haftbar bin und dass es sich nicht um Schadsoftware jeglicher Art handelt. Mir ist bekannt, dass ich für die Zugangsverwaltung der von mir zu registrierenden Anwendung voll verantwortlich bin und die T-Systems International GmbH diesbezüglich keine Verantwortung trägt. Die T-Systems International GmbH ist nicht verantwortlich oder haftbar für die zu registrierende Anwendung und deren mögliche Folgen in jeglicher Form.",
        "cancel": "Abbrechen",
        "save": "Änderungen speichern"
      }
    },
    "invite": {
      "inviteLabel": "Teilnehmer einladen"
    },
    "allocateConnectors": {
      "pageTitle": "Konnektor Zuweisung",
      "allocateConnectorLabel": "Konnektoren zuweisen",
      "topUpConnectorsButtonLabel": "Konnektoren aufstocken",
      "topUpConnectorTitle": "Ihre Anfrage wurde erfolgreich übermittelt.",
      "topUpConnectorBody": "Unser Support-Team wird sich so schnell wie möglich mit Ihnen in Verbindung setzen, um weitere Details zu besprechen.",
      "generalTooltipMessage": "Bitte wenden Sie sich an den DIH-Support (dih_support{'@'}telekom.de), um neue Konnektoren bestehenden Teilnehmern zuzuweisen oder einen neuen Teilnehmer in den Datenraum einzuladen.",
      "tooltipMessage": "Sie haben bereits die maximale Anzahl an verfügbaren Konnektoren gemäß Vertrag ausgeschöpft. Bitte kontaktieren Sie dih.support{'@'}telekom.de, wenn Sie das Kontingent erhöhen oder den Vertrag ändern möchten.",
      "allocationForm": {
        "step1Name": "Organisation",
        "step2Name": "Anschlüsse",
        "organizationNameDescription": "Wählen Sie das Unternehmen aus dem neue Konnektoren zugewiesen werden sollen",
        "organizationNameLabel": "Name der Organisation",
        "organizationPlaceholder": "Organisation auswählen",
        "inviteParticipantDescription": "Oder klicken Sie auf die Schaltfläche Teilnehmer einladen, um ein neues Unternehmen in Ihren Datenraum einzuladen.",
        "inviteParticipantButton": "Teilnehmer einladen",
        "numberOfConnectorsDescription": "Wählen Sie die Anzahl der Konnektoren",
        "numberOfConnectorsLabel": "Anzahl der Konnektoren",
        "numberOfConnectorsPlaceholder": "Zählen",
        "progressBarDescription": "Konnektor kontingent",
        "progressBarInfo": "{numberOfConnectors} Konnektoren verfügbar",
        "allocateButtonLabel": "Zuteilen",
        "cancelButtonLabel": "Stornieren",
        "noConnectorRemaining": "Es werden alle vorhandenen Konnektoren verwendet. Bitte klicken Sie auf \"Konnektoren aufstocken\", um die Erweiterung der Konnektoren mit unserem Team zu besprechen."
      },
      "successRequestTest": "Erfolgreiche Anforderung",
      "successRequestBody": "Die Konnektorzuordnung wurde erfolgreich initiiert.",
      "decisionModal": {
        "title": "Du wurdest eingeladen mitzumachen {dataspaceName}",
        "accept": "Annehmen",
        "decline": "Ablehnen",
        "acceptSuccessNotificationTitle": "Abnahme erfolgreich!",
        "acceptSuccessNotificationBody": "Sie haben die Einladung zum Beitritt zu {dataspaceName} erfolgreich angenommen.",
        "declineSuccessNotificationTitle": "Einladung abgelehnt!",
        "declineSuccessNotificationBody": "Sie haben die Einladung zum Beitritt zu {dataspaceName} abgelehnt."
      }
    },
    "request": {
      "requestTitle": "Wagen Sie den nächsten Schritt auf dem Weg zur gemeinsamen Nutzung von Daten und erstellen Sie Ihren eigenen Datenraum!",
      "requestDescription1": "Sie möchten nicht nur Ihre eigenen Daten freigeben, sondern auch Ihren Geschäftspartnern die Möglichkeit bieten, Informationen auszutauschen? Dann sollten Sie die Erstellung eines Datenraums in Erwägung ziehen. Dabei handelt es sich um eine vertrauenswürdige Community für die gemeinsame Nutzung und Verwertung von Daten.",
      "requestDescription2": "Ihr Datenraum wird von T-Systems in seiner zuverlässigen Infrastruktur eingerichtet und verwaltet.",
      "requestDescription3": "Darüber hinaus bieten wir Ihnen leicht zugängliche Testumgebungen für Ihre ersten Datenraum Erfahrungen („LivingLab“).",
      "request": "Anfrage",
      "sendAnotherRequest": "Weitere Anfrage senden",
      "lastRequest": "Ihre letzte Anfrage wurde gesendet am",
      "back": "Zurück",
      "sendRequest": "Anfrage senden",
      "requestedTextPlaceholder": "Suchen Sie nach etwas Bestimmtem oder wünschen Sie eine allgemeine Beratung? Verfügen Sie bereits über Erfahrung mit Datenräumen? Haben Sie Interesse an einer Testumgebung?"
    },
    "create": {
      "createTitle": "Eigenen Datenraum erstellen",
      "createDesc": "Senden Sie eine Anfrage an unser Expertenteam. Wir melden uns dann bei Ihnen.",
      "tellUsMore": "Können Sie uns mehr über Ihre Anfrage erzählen?"
    },
    "details": {
      "approve": "Akzeptieren",
      "decline": "Ablehnen",
      "approval": "Zustimmung",
      "requesterNotes": "Anmerkungen vos Anfragenden",
      "adminNotes": "Anmerkungen vom Admin",
      "decisionTakenTooltip": "Die Annahme oder Ablehnung ist bereits erfolgt. Die Bereitstellung der Konnektoren wird derzeit vorbereitet.  Sie werden informiert, sobald dies fertiggestellt ist.",
      "decisionInputPlaceholder": "Hinweise für das Akzeptieren oder der Ablehnung ...",
      "activeParticipants": "Aktive Teilnehmer",
      "incomingRequests": "Eingehende Anfragen",
      "invitations": "Einladungen",
      "manage": "Verwalten",
      "enter": "Eingeben",
      "providedBy": "Anbieter",
      "registered": "Eingetragen",
      "operatorToolkit": "Betreiber Toolkit",
      "dataspaceDetails": "Datenraum Details",
      "contactDetails": "Kontaktdaten",
      "visibilityTitle": "Verstecke deinen Datenraum vor dem Durchsuchen des Datenraums",
      "manageParticipants": "Teilnehmer verwalten",
      "applicationManagement": "Anwendungsentwicklung",
      "noApplications": {
        "title": "Sie haben noch keine Bewerbungen",
        "actionButtonText": "Anwendung registrieren"
      },
      "contactUs": "Wenn Sie Ihre Angaben aktualisieren möchten, kontaktieren Sie uns bitte direkt",
      "noData": "Es liegen noch keine Daten für Sie vor",
      "basic": "Grundlagen",
      "companyName": "Name des Unternehmens",
      "participationMode": "Teilnahme Modus",
      "connectorName": "Connector Name",
      "contact": "Kontakt",
      "address": "Anschrift",
      "street": "Straße",
      "number": "Zahl",
      "postalCode": "Postleitzahl",
      "city": "Stadt",
      "country": "Land",
      "contactPerson": "Ansprechpartner*in",
      "phoneNo": "Phone number",
      "email": "E-Mail",
      "dateOfJoining": "Beitrittsdatum",
      "dateOfInvitation": "Datum der Einladung",
      "dateOfRequest": "Datum der Anfrage",
      "url": "URL",
      "status": "Status",
      "bpn": "Geschäftspartnernummer",
      "name": "Name",
      "description": "Beschreibung",
      "website": "Website",
      "dataspaceId": "Datenraum-ID",
      "password": "Passwort",
      "digitalId": "Dieser Datenraum ist Digital.ID-fähig",
      "disableDigitalId": "Digital.ID ist für diesen Datenraum aktiviert",
      "company": "Unternehmen | Unternehmen",
      "connector": "Konnektor | Konnektoren",
      "application": "Anwendung | Anwendungen",
      "dataoffer": "Datenangebot | Datenangebote",
      "visibility": "Ihr Datenraum ist nun {access}",
      "discoverable": "Auffindbar",
      "private": "Privat",
      "deleteParticipant": {
        "modal": {
          "title": "Teilnehmer löschen",
          "informationText": "Der Konnektor, der dem Teilnehmer gehört, wird nach der Bestätigung wieder freigegeben. Diese Aktion kann nicht rückgängig gemacht werden.",
          "successNotificationTitle": "Löschanfrage",
          "successNotificationBody": "Die Löschung eines Teilnehmers wurde angefordert",
          "cancel": "Abbrechen",
          "delete": "Löschen"
        }
      },
      "services": {
        "applicationOrchestration": "Orchestrierung von Anwendungen",
        "applicationOrchestrationDesc": "Stellen Sie Ihre Geschäftsanwendungen bereit und verwalten Sie sie",
        "identityAccessManagement": "Identitäts- und Zugriffsmanagement",
        "identityAccessManagementDesc": "Benutzer*innen und deren Zugriff auf Ressourcen verwalten",
        "continuousDelivery": "Kontinuierliche Bereitstellung",
        "continuousDeliveryDesc": "Bewährte Verfahren für Bereitstellung und Prozesse anwenden",
        "secretCredentialManagement": "Verwaltung von Geheimhaltungs- und Berechtigungsnachweisen",
        "secretCredentialManagementDesc": "Zugriff auf Token, Passwörter und Zertifikate sichern, speichern und steuern",
        "monitoring": "Überwachung",
        "monitoringDesc": "Metriken und KPIs abfragen, visualisieren, analysieren und Meldungen dazu erhalten",
        "connectorTest": "Gesundheitscheck des Steckers",
        "connectorTestDesc": "Dienst, um einen Gesundheitscheck für Konnektoren durchzuführen.",
        "catenaXSandbox": "Catena-X Sandbox",
        "catenaXSandboxDesc": "Starten Sie Ihre Catena-X Sandbox in Ihrem LivingLab, um neue Geschäftsanwendungen in der Catena-X Umgebung zu entwickeln und zu testen."
      }
    },
    "connectorHealthCheck": {
      "title": "Gesundheitscheck des Steckers",
      "viewResults": "Ergebnisse anzeigen",
      "close": "Schließen",
      "description": "Wählen Sie einen oder mehrere Konnektoren aus, für die Sie einen Gesundheitscheck durchführen möchten. Die Standard Datenraumoperationen sind von der Gesundheitsprüfung nicht betroffen.",
      "connectors": "steckverbinder",
      "placeholder": "Steckverbinder auswählen",
      "searchInputLabel": "Anschlüsse suchen...",
      "banner": "Die Durchführung des Gesundheitschecks kann einige Minuten in Anspruch nehmen. Sie werden benachrichtigt, sobald das Ergebnis verfügbar ist.",
      "action": "Einleiten",
      "history": {
        "connectorName": "Name des Anschlusses",
        "initiated": "Initiiert",
        "completed": "Abgeschlossen",
        "result": "Ergebnis",
        "successMessage": "{connectorName} funktioniert wie erwartet",
        "unsuccessfulMessage": "{connectorName} nicht wie erwartet funktioniert. Bitte kontaktieren Sie "
      },
      "successNotificationTitle": "Zustandsprüfung des Anschlusses eingeleitet.",
      "successNotificationContent": "Sie werden benachrichtigt, sobald das Ergebnis verfügbar ist.",
      "noTest": "Sie haben noch keine Tests",
      "initiateTest": "Test einleiten"
    },
    "specifications": {
      "table": {
        "attribute": "Attribut",
        "value": "Wert",
        "cloudProvider": "Cloud Anbieter",
        "contractStart": "Vertragsbeginn",
        "contractDuration": "Vertragsdauer",
        "cpu": "CPU",
        "ram": "RAM",
        "storage": "Speicher",
        "maximumConnectorsBookable": "Maximal buchbare Konnektoren",
        "subscribedConnectors": "Abonnierte Konnektoren"
      },
      "dataspaceSpecifications": "Datenspezifikationen",
      "livinglabCore": "LivingLab Kern",
      "livinglabAppLayer": "LivingLab Anwendungsschicht"
    }
  },
  "profile": {
    "profile": "Profil",
    "userProfile": "Nutzerprofil",
    "personalInfo": "Personenbezogene Informationen",
    "personalDetails": "Persönliche Angaben und Einstellungen",
    "changeDetailsMessage": "Wenn Sie bestimmte Angaben ändern möchten, kontaktieren Sie uns",
    "changeDetailsLinkText": "Support",
    "memberships": "Mitgliedschaften",
    "security": "Sicherheit",
    "setUp2FA": "2FA einrichten",
    "accountInformation": "Kontoinformationen",
    "deleteAccount": "Benutzerkonto löschen",
    "deleteAccountDescription": "Bei diesem Vorgang werden alle Daten und Datendateien gelöscht, die mit Ihrem persönlichen Konto verknüpft sind.",
    "accountSecurity": "Kontosicherheit",
    "yourInfomration": "Ihre Informationen",
    "fullName": "Vollständiger Name",
    "contact": "Kontakt",
    "language": "Sprache",
    "changePassword": "Passwort ändern",
    "please": "Bitte",
    "contactSupport": "Support kontaktieren",
    "toDownload": "Zum Herunterladen dieser Informationen",
    "toDelete": "Zum Löschen Ihres Profils",
    "roles": "Rollen",
    "memberSince": "Mitglied seit",
    "orgLegalName": "Juristische Bezeichnung der Organisation",
    "bpnNumber": "Geschäftspartnernummer (BPN)",
    "commRegNumber": "Handelsregisternummer",
    "vatIdNumber": "USt IdNr.",
    "hqAddress": "Anschrift des Hauptsitzes",
    "legalIdentifier": "Juristische Kennung",
    "additionalAttributes": "Zusätzliche Attribute"
  },
  "plansBenefits": {
    "communicationIntegrity": "Integrität der Kommunikation",
    "protectedConnection": "Geschützte Verbindung",
    "mutualAuth": "Gegenseitige Authentifizierung",
    "stateArtOfCrypto": "Modernste Verschlüsselung",
    "remoteAttestation": "Remote Bescheinigung",
    "platforInt": "Plattformintegrität",
    "configureAndApp": "Konfiguration und App Integration",
    "dataUsageControl": "Datennutzungskontrolle",
    "definitionOfUsagePolicy": "Definition der Nutzungsbedingungen",
    "from": "von",
    "month": "Monat | Monate",
    "connect": "Verbinden"
  },
  "cookieSettings": {
    "collapsedTitle": "Datenschutzeinstellungen",
    "collapsedText": "Diese Website verwendet Cookies und ähnliche Technologien. Dies sind kleine Textdateien, die auf Ihrem Computer gespeichert und gelesen werden. Indem Sie auf „{0}“ klicken, akzeptieren Sie die Verarbeitung, die Erstellung von individuellen Benutzerprofilen über Websites und Partner hinweg und die Übermittlung Ihrer Daten an Dritte, die Ihre Daten teilweise in Ländern außerhalb der Europäischen Union verarbeiten (DSGVO Art 49). Weiteres finden Sie in Abschnitt 2 des {1}. Die Daten dienen der Analyse, dem Retargeting und der Ausspielung personalisierter Inhalte und Werbung auf Telekom Seiten und Drittseiten. Weitere Informationen, auch Informationen zur Datenverarbeitung durch Drittanbieter und die Möglichkeit des Widerrufs, finden Sie in den Einstellungen und in unseren Datenschutzhinweisen. Hier können Sie nur mit den erforderlichen Tools {2}.",
    "extendedTitle": "Datenschutzeinstellungen",
    "extendedText": "Um Ihnen ein optimales Webseiten Erlebnis zu bieten, setzen wir Cookies ein. Dazu zählen Cookies für den Betrieb und die Optimierung der Seite als auch für Services, wie die Nutzung des Text- oder Video Chats als auch für an Ihrem online Nutzungsverhalten orientierte Werbung. So kann z.B. erkannt werden, wenn Sie unsere Seiten vom selben Gerät aus wiederholt besuchen. Wir möchten Ihnen die Wahl geben, welche Cookies Sie zulassen:",
    "onlyRequired": "Nur erforderliche",
    "policyLinkHref": "https://dih.telekom.com/de/datenschutz",
    "policyLinkCookieHref": "https://dih.telekom.com/de/datenschutz/#cookies",
    "policyLinkTextShort": "Hinweise zum Datenschutz",
    "policyLinkText": "Hinweise zum Datenschutz",
    "rejectLinkText": "Weiter",
    "acceptButton": "Alle akzeptieren",
    "saveButton": "Einstellungen speichern",
    "settingsButton": "Einstellungen ändern",
    "showMore": "Mehr anzeigen",
    "showLess": "Weniger anzeigen",
    "categoryTitleEssential": "Erforderliche Cookies",
    "categoryTitleAnalytics": "Analytische Cookies",
    "categoryTitleMarketing": "Marketing Cookies",
    "categoryTitleThirdParty": "Dienste von anderen Unternehmen (eigenverantwortliche Drittanbieter)",
    "categoryTextEssential": "Diese Cookies sind notwendig, damit Sie durch die Seiten navigieren und wesentliche Funktionen nutzen können. Sie ermöglichen Grundfunktionen, wie die Bestellabwicklung im Online Shop und den Zugriff auf gesicherte Bereiche der Webseite. Zudem dienen sie der anonymen Auswertung des Nutzerverhaltens, die von uns verwendet werden, um unseren Webauftritt stetig für Sie weiterzuentwickeln.",
    "categoryTextAnalytics": "Diese Cookies helfen uns, das Nutzungsverhalten besser zu verstehen. Analyse Cookies ermöglichen die Erhebung von Nutzungs und Erkennungsmöglichkeiten durch Erst oder Drittanbieter, in so genannten pseudonymen Nutzungsprofilen. Wir benutzen beispielsweise Analyse Cookies, um die Zahl der individuellen Besucher einer Webseite oder eines Dienstes zu ermitteln oder um andere Statistiken im Hinblick auf den Betrieb unserer Produkte zu erheben, als auch das Nutzerverhalten auf Basis anonymer und pseudonymer Informationen zu analysieren, wie Besucher mit der Webseite interagieren. Ein unmittelbarer Rückschluss auf eine Person ist dabei nicht möglich.",
    "categoryTextMarketing": "Diese Cookies und ähnliche Technologien werden eingesetzt, um Ihnen personalisierte und dadurch relevante Marketing Inhalte anzeigen zu können. Marketing Cookies dienen dazu, interessante Werbeinhalte anzuzeigen und die Wirksamkeit unserer Kampagnen einzuschätzen. Dies geschieht nicht nur auf den Websites der Telekom, sondern auch auf denen von anderen Werbepartnern (Drittanbietern). Dies wird auch als Retargeting bezeichnet. Es dient zur Erstellung pseudonymisierter Inhalts oder Anzeigenprofile, zur Schaltung relevanter Werbung auf anderen Websites sowie dazu, Erkenntnisse über Zielgruppen abzuleiten, die die Anzeigen und Inhalte angesehen haben. Ein unmittelbarer Rückschluss auf Personen ist dabei nicht möglich. Marketing und Retargeting Cookies helfen uns, potenziell relevante Werbeinhalte für Sie anzuzeigen. Durch das Unterdrücken von Marketing Cookies wird Ihnen weiterhin die gleiche Menge an Werbung angezeigt, die jedoch möglicherweise weniger relevant für Sie ist.",
    "categoryTextThirdParty": "Die Websites der Telekom enthalten Links zu Drittanbietern, die ihre Dienste in eigener Verantwortung anbieten. So werden bei Ihrem Besuch der Telekom Websites mittels Cookies oder ähnlicher Technologien Daten erfasst und an Dritte übermittelt, teilweise zu eigenen Zwecken der Telekom. In welchem Umfang, zu welchen Zwecken und auf Basis welcher Rechtsgrundlage eine Weiterverarbeitung des Drittanbieters erfolgt, entnehmen Sie bitte den Datenschutzhinweisen des Drittanbieters. Informationen zu eigenverantwortlichen Drittanbietern finden Sie im Datenschutzhinweis."
  },
  "consultation": {
    "createDescription": "Sie können eine Anfrage an unser Expertenteam senden und wir werden uns bei Ihnen melden.",
    "tellUsMore": "Können Sie uns mehr über Ihr Anliegen erzählen?",
    "checkConsent": "Ich bin damit einverstanden, dass meine Kontaktdaten an ein Vertriebsteam von T-Systems weitergegeben und kontaktiert werden.",
    "back": "Zurück",
    "sendRequest": "Anfrage senden",
    "successMsgtitle": "Erfolgreiche Anfrage!",
    "successMsgBody": "Ihre Anfrage wurde gesendet. Sie erhalten in wenigen Werktagen eine Antwort"
  },
  "notifications": {
    "notifications": "Benachrichtigungen",
    "inbox": "Posteingang",
    "clearAll": "Alle löschen",
    "blankState": {
      "heading": "Noch keine Benachrichtigungen",
      "description": "Wenn Sie Benachrichtigungen erhalten, werden diese hier angezeigt",
      "actionButtonText": "Aktualisieren"
    },
    "actionMessage": {
      "seeRequest": "Siehe die Anfrage",
      "checkResult": "Prüfen Sie das Ergebnis ",
      "here": "hier"
    },
    "goToMyAccount": "Bitte sehen Sie sich Ihre neue(n) Rolle(n) unter {myAccountLink} an."
  },
  "statusTile": {
    "noMembership": {
      "title": "Sie verfügen noch nicht über eine Mitgliedschaft",
      "subTitle": "Datenräume durchsuchen und einem von ihnen beitreten",
      "getConnected": "Get Connected - Verbinden"
    }
  },
  "settings": {
    "organization": {
      "pageTitle": "Meine Organisation",
      "orgDetails": "Angaben zur Organisation",
      "teamMembers": "Teammitglieder"
    },
    "teamMembers": {
      "title": "Benutzermanagement",
      "description": "Dieser Bereich bietet Unterstützung bei der Verwaltung der Benutzer*innen in Ihrer Organisation"
    },
    "inviteUser": {
      "inviteUsers": "Benutzer*innen einladen",
      "newEmailAddress": "E-Mail Adresse des neuen Teammitglieds",
      "chooseRole": "Rolle auswählen",
      "whichRoleToSelect": "Welche Rolle soll ich auswählen?",
      "roleNote": "Die ausgewählte Rolle wird allen eingeladenen Benutzer*innen zugewiesen",
      "roleError": "Bitte wählen Sie aus den verfügbaren Optionen mindestens 1 Rolle aus.",
      "roleErrorShort": "Bitte wählen Sie mindestens 1 Rolle aus",
      "cancel": "Abbrechen",
      "sendInvite": "Einladung senden",
      "fullName": "Vollständiger Name",
      "status": "Status",
      "role": "Rolle",
      "memberSince": "Mitglied seit"
    },
    "inviteSuccess": {
      "title": "Zusammenfassung der Einladung",
      "totalInvitation": "{count} Einladung wurde gesendet | {count} Einladungen wurden gesendet",
      "description": "{count} Einladung wurde nicht gesendet, da die E-Mail Adresse unter den Benutzer*innen Ihrer Organisation bereits vorhanden ist | {count} Einladungen wurden nicht gesendet, da die E-Mail Adressen unter den Benutzer*innen Ihrer Organisation bereits vorhanden sind",
      "actionContinue": "Weiter"
    },
    "roleChange": {
      "areYouSure": "Sind Sie sicher, dass Sie diese Rollenänderungen vornehmen möchten?",
      "yeahSure": "Ja, da bin ich mir sicher"
    }
  },
  "completeInviteProfile": {
    "title": "Konto fertigstellen",
    "description": "Sie wurden zum Telekom Data Intelligence Hub eingeladen. Um Ihr Konto fertigzustellen, füllen Sie bitte die erforderlichen Angaben aus.",
    "firstName": "Vorname",
    "firstNamePlaceholder": "Vornamen eingeben",
    "lastName": "Nachname",
    "lastNamePlaceholder": "Nachnamen eingeben",
    "email": "E-Mail",
    "emailPlaceholder": "E-Mail Adresse eingeben",
    "organisation": "Organisation",
    "organisationPlaceholder": "Organisation eingeben",
    "enterPassword": "Passwort eingeben",
    "dataPrivacyCheck": "Ich stimme zu, dass meine personenbezogenen Daten zu den in der  {dataAndPrivacyStatement} angegebenen Zwecken gespeichert und verarbeitet werden.* ",
    "termsAndConditionsCheck": "Ich bestätige, dass ich die  {termsandcondition} des Data Intelligence Hub gelesen habe und einhalten werde.",
    "termsandcondition": "Nutzungsbedingungen",
    "dataAndPrivacyStatement": "Datenschutzerklärung",
    "cancel": "Abbrechen",
    "submit": "Senden",
    "success": {
      "title": "Ihr Konto wurde fertiggestellt",
      "description": "Sie werden zur Anmeldeseite weitergeleitet",
      "actionContinue": "Weiter"
    }
  },
  "workbench": {
    "incomingDataAgreements": {
      "title": "Vereinbarungen über eingehende Daten",
      "addedOn": "Hinzugefügt am",
      "expiresOn": "Läuft ab am",
      "providedBy": "Bereitgestellt am",
      "dateAddedOn": "Hinzugefügt am",
      "noFilesAttached": "Dieser Vertragsvereinbarung sind derzeit keine Dateien beigefügt",
      "noAgreementsMessage": "Es liegen keine Vereinbarungen über eingehende Daten für Sie vor",
      "receivingData": "Empfangen von Daten",
      "dataReceived": "Gedownloadet",
      "error": "Fehler aufgetreten",
      "transferInitiated": "Überweisung eingeleitet",
      "transferCompleted": "Transfer abgeschlossen!",
      "view": "Ansehen",
      "successDownload": "Datenbestand erfolgreich empfangen",
      "errorDownload": "Ihr Dateidownload für {filename} wurde mit einem Fehler abgebrochen, bitte versuchen Sie es erneut",
      "viewAggreement": "Vereinbarung anzeigen",
      "agreeText": "Durch das Herunterladen der Dateien erklären Sie sich damit einverstanden, die {contractTerms} dieser Vereinbarung einzuhalten",
      "contractTerms": "Vertragsbedingungen",
      "includedFile": "Enthaltene Datei",
      "createdOn": "Erstellt am",
      "fileType": "Dateityp",
      "downloadError": "Fehler beim Abrufen eingehender Datenvereinbarungen",
      "downloadSuccess": "Datenbestand erfolgreich empfangen"
    },
    "contractDetails": {
      "termsAndPolicies": "Bedingungen & Richtlinien",
      "offerBy": "Angebot von",
      "addedOn": "Hinzugefügt am",
      "expiresOn": "Läuft ab am",
      "product": "Produkt",
      "contractID": "Vertrags ID",
      "totalFiles": "Dateien gesamt",
      "description": "Beschreibung",
      "contractTerms": "Vertragsbedingungen",
      "files": "Dateien",
      "parameters": "Parameter",
      "referencePeriod": "Bezugszeitraum",
      "location": "Ort",
      "additionalLinks": "Weiterführende Links",
      "dihMainPage": "DIH Hauptseite",
      "download": "Herunterladen",
      "search": "Suchen",
      "title": "Titel",
      "createdOn": "Erstellt am",
      "kpiName": "KPI Name",
      "fileType": "Dateityp",
      "schema": "Schema",
      "dashboard": "Instrumententafel",
      "selectAll": "Alles auswählen",
      "deselectAll": "Auswahl aufheben",
      "transfer": "Übertragung",
      "downloadSelected": "Ausgewählte herunterladen",
      "noTermsPolicy": "Zu diesem Zeitpunkt hat der Datenanbieter keine Regeln für die Verwendung von Daten im Rahmen dieses Vertrags festgelegt.",
      "youCan": "Sie können",
      "youCant": "Das geht nicht",
      "youMust": "Du musst",
      "respectContent": "Respektieren Sie den Inhalt der Lizenzvereinbarung",
      "licenceAgreement": "Lizenzvereinbarung",
      "noAttachment": "Noch keine Anhänge",
      "termsandcondition": "Allgemeine Geschäftsbedingungen",
      "resellData": "Verkaufen Sie die Daten weiter",
      "youCanUseDataCommercial": "Nutzung der Daten für kommerzielle Zwecke",
      "youCanProcessData": "Verarbeitung der Daten auf der Infrastruktur außerhalb der Europäischen Union",
      "youCanEmpty": "Zu diesem Zeitpunkt hat der Datenanbieter keine Berechtigungen für die Verwendung von Daten in diesem Vertrag angegeben",
      "youCantEmpty": "Zu diesem Zeitpunkt hat der Datenanbieter im Rahmen dieses Vertrags keine Verbote für die Verwendung von Daten festgelegt",
      "youMustEmpty": "Zu diesem Zeitpunkt hat der Datenanbieter keine Verpflichtungen für die Verwendung von Daten im Rahmen dieses Vertrags festgelegt"
    }
  },
  "messages": {
    "noDataAvailable": "Keine Daten verfügbar",
    "noMatchFound": "Keine Übereinstimmung gefunden",
    "maximumFileSize": "Maximale Dateigröße",
    "suitableFileFormats": "Geeignete Dateiformate",
    "loading": "Ladevorgang…",
    "dragDropText": "oder per Drag & Drop hierher ziehen"
  },
  "pageNotFound": {
    "title": "Hoppla! Anscheinend existiert die gesuchte Seite noch nicht.",
    "subtitle": "Schauen Sie sich in der Zwischenzeit unsere anderen Ressourcen an, die für Sie hilfreich sein könnten!"
  },
  "unauthorized": {
    "pageNotAccessible": "Hoppla! Sieht so aus, als ob die Seite, nach der Sie suchen, mit Ihren Berechtigungen nicht zugänglich ist.",
    "reachOutToAdministrator": "Bitte wenden Sie sich an Ihren Administrator, um sich weiter nach Ihrem Zugriff zu erkundigen.",
    "checkOtherResources": "Schauen Sie sich in der Zwischenzeit unsere anderen Ressourcen an."
  },
  "helpResources": {
    "needHelp": "Benötigen Sie Hilfe bei der Suche nach dem richtigen Plan, weitere Informationen, technische Fragen?",
    "FAQ": "Häufig gestellte Fragen",
    "FAQDescription": "Finden Sie häufigere Antworten von der Abrechnung bis zum Datenschutz",
    "browse": "Durchsuche",
    "demos": "Demos",
    "demosDescription": "Finden Sie Anwendungsfälle und Demos, die für Ihren Industriesektor relevant sind",
    "glossary": "Glossar",
    "glossaryDescription": "Finden Sie die Begriffe und Definitionen im Zusammenhang mit DIH, IDS, Catena-X, GAIA-X und mehr."
  },
  "feedbackCard": {
    "giveUsFeedback": "Geben Sie uns Feedback, was uns fehlt?",
    "contactUs": "Kontaktiere uns"
  },
  "requestDataspaceJoin": {
    "title": "Verbindung anfordern zu {dsName}",
    "basic": "Grundlegend",
    "orgName": "Name der Organisation:",
    "contact": "Kontakt",
    "address": "Adresse:",
    "name": "Name:",
    "email": "E-Mail:",
    "joinReasonTitle": "Bitte geben Sie an, warum Sie dem Datenraum beitreten möchten?",
    "joinConsent": "Ich bin damit einverstanden, die oben genannten Daten an den Eigentümer des Datenraums weiterzugeben",
    "sendRequest": "Anfrage senden"
  },
  "applications": {
    "myApplication": "Meine Anwendungen"
  },
  "externalConnection": {
    "title": "Externer Verbindungs Manager",
    "noConnectionConfigureTitle": {
      "title": "Sie haben noch keine Verbindung konfiguriert",
      "newConnection": "Neue Verbindung"
    },
    "request": {
      "connectionDetails": "Verbindungsdetails",
      "preferConnection": "Wählen Sie den bevorzugten Modus der Serververbindung",
      "selectConnection": "Verbindung auswählen",
      "ageeTerms": "Hiermit bestätige ich, dass alle meine oben genannten persönlichen Informationen und Daten korrekt sind",
      "grantConsent": "Ich erteile dem Telekom Data Intelligence Hub meine Einwilligung zur Verarbeitung und Speicherung dieser Daten, um Asset Transfers über die bereitgestellte Verbindung zu ermöglichen",
      "back": "Zurück",
      "addConnection": "Verbindung hinzufügen",
      "save": "Speichern",
      "verifyConnectivity": "Überprüfen der Konnektivität",
      "checkingConnection": "Überprüfen der Verbindung",
      "connectionVerified": "Verbindung verifiziert",
      "error": "Fehler",
      "requestSuccessTitle": "Erfolgreich hinzugefügt!",
      "requestSuccessText": "Sie haben Ihre Verbindungsdaten erfolgreich hinzugefügt.",
      "requestUpdatedTitle": "Mise à jour réussie !",
      "requestUpdatedText": "Sie haben Ihre Verbindungsdaten erfolgreich aktualisiert.",
      "maxConnectionReached": "Sie haben die maximale Anzahl freier Verbindungen erreicht."
    },
    "delete": {
      "title": "Löschen von Verbindungen",
      "description": "Sind Sie sicher, dass Sie diese Verbindung löschen möchten?",
      "cancel": "Abbrechen",
      "delete": "Löschen",
      "requestDeleteSuccessTitle": "Erfolgreich gelöscht!",
      "requestDeleteSuccessText": "Sie haben Ihre Verbindung erfolgreich gelöscht."
    },
    "transfer": {
      "title": "Verbindungs Manager",
      "connectionDetails": "Verbindungsdetails",
      "type": "Art",
      "hostName": "Hostname",
      "rootPath": "Root Pfad",
      "authType": "Art der Authentifizierung",
      "username": "Nutzername",
      "verificationStatus": "Status der Verifizierung",
      "disclaimer": "Verzichtserklärung",
      "disclaimerText": "Mit der Bestätigung Ihrer Auswahl erkennen Sie an, dass die gewählte Verbindung außerhalb der Kontrolle des Telekom Data Intelligence Hub liegt. Folglich übernehmen Sie die Verantwortung für alle Probleme bei der Datenübertragung, die sich aus externen Faktoren ergeben, einschließlich, aber nicht beschränkt auf unzureichenden Speicherplatz, Netzwerkkonnektivität, Zugriffs- und Authentifizierungsbeschränkungen und andere relevante Überlegungen.",
      "confirmSelection": "Auswahl bestätigen",
      "newConnection": "Neue Verbindung",
      "noConnectionModalTitle": "Sie haben noch keine Verbindungen",
      "noConnectionModalDesc": "Sie können eine neue Verbindung erstellen, indem Sie auf die Schaltfläche unten klicken.",
      "noConnectionAddNewButton": "Neue Verbindung hinzufügen",
      "successModalTitle": "Die Überweisung dauert länger als üblich",
      "successModalDesc": "Wir senden eine Benachrichtigung, sobald die Dateiübertragung abgeschlossen ist.",
      "successModalAction": "Gehe zu Eingehende Daten"
    }
  },
  "error": {
    "pageNotFound": "Seite nicht gefunden (404)",
    "unauthorized": "Unbefugter Zugriff (403)"
  },
  "helpWidget": {
    "askSupport": "Support anfragen",
    "chatboxTitle": "Hilfe chat",
    "chatboxSubtitle": "Telekom Data Intelligence Hub Unterstützung",
    "defaultMessage": {
      "body": "Hallo, willkommen im Support Kanal des Telekom Data Intelligence Hub. Bitte wählen Sie unten eine Option aus."
    },
    "whatCanWeHelpWith": "Wie können wir ihnen heute helfen?",
    "suggestedAnswers": {
      "knowledgeBase": "Wissensdatenbank, Dokumentation und FAQs",
      "contactSupport": "Kontakt zum Unterstützung",
      "contactSales": "Kontakt zu Vertrieb"
    }
  },
  "partnerNetworkManagement": {
    "title": "Partnernetzwerkverwaltung",
    "hint": "Auf dieser Seite können Benutzer Ihre Partnerorganisationen zum Telekom Data Intelligence Hub einladen und den Einladungsstatus überwachen.",
    "noInvitations": {
      "title": "Sie haben noch keine Organisationen eingeladen",
      "actionButtonText": "Eine Organisation einladen"
    },
    "overview": {
      "title": "Übersicht Partnereinladungen",
      "actionButtonText": "Organisation(en) einladen",
      "dateOfInvitation": "Einladungsdatum",
      "resendInvitation": "Einladung erneut senden",
      "deleteInvitation": "Einladung zurückziehen",
      "resendReminderInfo": "Sie können die Einladung erneut senden, sobald 24 Stunden seit der letzten Einladungsemail vergangen sind.",
      "invitedBy": "Eingeladen von"
    },
    "information": {
      "title": "Wichtige Informationen",
      "description": "Die Kontaktperson übernimmt die Rolle des \"Administrators\" innerhalb der Telekom Data Intelligence Hub. Als Administrator hat er/sie die Befugnis, Benutzer seiner/ihrer Organisation hinzuzufügen oder zu entfernen, Rollen zu überwachen und kommerzielle Entscheidungen in Bezug auf die von uns angebotenen Dienste zu treffen. Wir werden die Kontaktperson per E-Mail über die nächsten Schritte beim Onboarding der Organisation informieren und Sie können den Fortschritt auf der Seite \"Übersicht Partnereinladungen\" verfolgen."
    },
    "invite": {
      "additionalText": "Bitte überlegen Sie, ob Sie Organisationen einzeln oder in größerer Zahl einladen möchten, und treffen Sie unten Ihre Auswahl.",
      "bulkInvite": "Masseneinladungen",
      "bulkInviteHint": "Wenn Sie eine Masseneinladung (>5 Einladungen) durchführen möchten, wählen Sie bitte diese Option.",
      "limitationHint": "Begrenzung: Maximal 5 Organisationen. Um mehr hinzuzufügen, wählen Sie bitte \"Masseneinladungen\".",
      "singleInvite": "Individuelle Einladung",
      "singleInviteHint": "Um bis zu 5 Organisationen einzuladen, wählen Sie bitte diese Option.",
      "orgNameLabel": "Name der Partnerorganisation eingeben",
      "emailLabel": "E-Mail der Kontaktperson eingeben"
    },
    "inviteStatus": {
      "INVITED": "Eingeladen",
      "COMPLETED": "Akzeptiert",
      "FAILED": "Abgelehnt"
    },
    "upload": {
      "hint": "Um mehrere Organisationen einzuladen, gehen Sie bitte wie folgt vor:",
      "step1": "Laden Sie die Vorlagendatei herunter, indem Sie auf den folgenden Link klicken",
      "step2": "Füllen Sie die bereitgestellte Datei mit den Daten der eingeladenen Personen aus.",
      "step3": "Laden Sie die Datei im vorgesehenen Bereich hoch",
      "downloadTemplate": "Vorlage herunterladen",
      "templateHeader": {
        "organizationName": "organizations_name",
        "email": "e_mail"
      },
      "templateFileName": "bulk_einladungen_vorlage.csv",
      "learnMore": "Um mehr über Masseneinladungen zu erfahren, klicken Sie bitte {clickHere}.",
      "clickHere": "hier"
    },
    "report": {
      "invitationsInProgress": "Einladungen an Organisationen laufen",
      "invitationsSent": "Einladungen gesendet",
      "error": {
        "errorsInInvitations": "Fehler in {result} gesendeten Einladungen",
        "single": {
          "invalidEmail": "E-Mail-Adresse ist ungültig",
          "pleaseEnterValidEmail": "Bitte geben Sie eine gültige E-Mail-Adresse ein",
          "pleaseEnterValidOrganization": "Bitte geben Sie einen gültigen Organisationsnamen ein",
          "emailUnique": "Die E-Mail muss einzigartig sein",
          "pleaseEnterValidName": "Bitte geben Sie einen gültigen Namen ein"
        },
        "bulk": {
          "allowedFileExtension": "Nur Dateien mit der Erweiterung csv sind erlaubt",
          "fileIsTooBig": "Die Datei ist zu groß. Sie können Dateien bis zu 1 MB hochladen",
          "formatMismatch": "Die Datei entspricht nicht dem erwarteten Format. Bitte verwenden Sie die bereitgestellte Vorlage und versuchen Sie es erneut",
          "duplicateEmail": "Die Datei enthält {number} {duplicate}",
          "duplicate": "Duplikat | Duplikate",
          "fileHasNoEntries": "Die Datei enthält keine Einträge",
          "invalidEmailAddresses": "{number} E-Mail-Adressen sind ungültig.",
          "invalidOrganizationNames": "{number} Organisationen haben ungültige Namen.",
          "organizationAlreadyInvited": "{number} Organisationen wurden bereits von Ihrer Organisation eingeladen.",
          "kindlyDownloadReport": "Bitte laden Sie den Bericht herunter, um weitere Details zu sehen"
        },
        "processing": {
          "DUPLICATE_EMAILS": "Doppelte E-Mails erkannt.",
          "EMAIL_ALREADY_EXIST": "E-Mail existiert bereits.",
          "SOMETHING_WENT_WRONG": "Etwas ist schief gelaufen.",
          "INVALID_EMAIL_FORMAT": "Ungültiges E-Mail-Format."
        }
      },
      "template": {
        "fileName": "bericht_ueber_masseneinladungen.csv"
      }
    },
    "abort": {
      "title": "Einladung stornieren",
      "confirmText": "Sind Sie sicher, dass Sie diesen Vorgang beenden möchten?",
      "informationText": "Ihre Einladungen werden nicht verschickt."
    },
    "switchForm": {
      "title": "Änderungen verwerfen",
      "confirmText": "Sind Sie sicher, dass Sie das Formular wechseln möchten?",
      "informationText": "Ihre Änderungen werden nicht gespeichert."
    }
  },
  "discover": {
    "welcome": {
      "title": "Entdecken Sie Datenangebote von unseren vertrauenswürdigen Partnern und Anbietern",
      "description1": "Treffen Sie datengesteuerte Entscheidungen.",
      "description2": "Daten spielen eine zentrale Rolle im Geschäftsbetrieb und dienen verschiedenen wesentlichen Funktionen wie dem Rückgrat für die Entscheidungsfindung, der Marktsegmentierung und der Lead-Generierung. Daten können über Erfolg oder Misserfolg Ihrer Produktentwicklung entscheiden, Ihre Lieferkettenlogistik optimieren oder Ihre KI mit den Erkenntnissen versorgen, die sie benötigt, um die Schlussfolgerungen zu ziehen, die Sie benötigen, um Ihre Ziele voranzutreiben.",
      "description3": "Wir im Data Intelligence Hub der Telekom bieten eine einheitliche Plattform für Datenanbieter in ganz Deutschland. Erkunden Sie Ihre Optionen und tauchen Sie ein: Vielleicht finden Sie eine hervorragende Lösung für Ihre Datenanforderungen. Wir erweitern ständig unser Netzwerk von Datenanbietern, also seien Sie kein Fremder, auch wenn Ihre genauen Anforderungen nicht durch unser aktuelles Datenangebot abgedeckt werden: Ihre perfekte Datenanpassung kann Tage entfernt sein.",
      "buttonLabel": "Datenprodukte erkunden"
    },
    "dataOffers": {
    "title": "Datenangebote",
    "filter": {
        "subjectAreas": "Fachbereiche",
        "dataOfferingType": "Datenangebotstyp",
        "dataProvider": "Datenanbieter"
    },
    "tiles": {
        "productType": "Konfigurierbares Datenprodukt",
        "dataspaceName": "Data Intelligence Hub Datenraum",
        "subjectArea": "Fachbereich",
        "massmovementAnalytics": "Massenbewegungsanalysen",
        "termsOfUse": "Nutzungsbedingungen",
        "licensedRestricted": "Lizenziert, eingeschränkt",
        "viewOffer": "Angebot ansehen",
        "product1": {
            "name": "Traffic Analytics von Motion Data",
            "summary": "Detaillierte Bewegungsverhaltensstatistiken in Deutschland, einschließlich Bewegungsrichtung, Herkunft, Ziel, die Analysen auf verschiedenen räumlichen Ebenen in jeder Region ermöglichen."
        },
        "product2": {
            "name": "Traffic Analytics PRO von Motion Data",
            "summary": "Detaillierte Bewegungsmessungen in Deutschland, einschließlich Bewegungsrichtung, Herkunft, Ziel, die Analysen und Vergleiche auf und zwischen verschiedenen räumlichen Ebenen jederzeit und in jeder Region ermöglichen."
        },
        "product3": {
            "name": "Visitor Analytics PRO von Motion Data",
            "summary": "Analyse von Informationen über Besucherverhalten aufgrund öffentlicher Bewegungsmuster, einschließlich Gesamtanzahl der Benutzer täglich und stündlich, soziodemografische Einblicke und Herkunftsdetails der Besucher in jeder Region in Deutschland. Nächste Ebene der Analyseoptionen für das Vor-Ort-Besucherverhalten, die die Vorteile einer der größten Stichprobengrößen und beispielloser räumlicher Präzision kombiniert."
        }
      }
    }
  },
  "ospOnboarding": {
    "help": "Hilfe",
    "welcome": {
      "title": "Willkommen bei Catena-X - Das Automotive Netzwerk",
      "card": {
        "note": "Bitte füllen Sie das folgende Formular aus, um die Registrierung Ihres Unternehmens bei Catena-X abzuschließen.",
        "point-1": "Das Registrierungsformular besteht aus 3 Schritten und nimmt ca. 5-10 Minuten Ihrer Zeit in Anspruch.",
        "point-2": "Da Ihre Organisation bereits im Telekom Data Intelligence Hub registriert ist, füllen wir das Formular vorab mit Ihren verfügbaren Daten aus, um den Registrierungsprozess so reibungslos und einfach wie möglich zu gestalten.",
        "point-3": "Sie können jederzeit unseren Leitfaden in unserer Wissensdatenbank konsultieren, um den Registrierungsprozess besser zu verstehen. Bitte klicken Sie {hereLink}, um mehr zu erfahren",
        "here": "Hier",
        "letStarted": "Los geht's"
      }
    },
    "title": "Registrierung im Catena-X-Ökosystem",
    "description": "Registrieren Sie sich bei Catena-X, indem Sie in den folgenden 3 Schritten alle erforderlichen Informationen eingeben.",
    "description2": "Bitte schließen Sie das Onboarding Ihres Unternehmens ab, indem Sie die folgenden Aufgaben ausführen, um aktiv teilzunehmen und alle Funktionen des Catena-X Automotive Network Portals nutzen zu können.",
    "note": "Bitte geben Sie alle erforderlichen Informationen ein, bevor Sie fortfahren. Weitere Informationen finden Sie unter {help}.",
    "companyDataStepTitle": "Daten zum Unternehmen",
    "companyRoleStepTitle": "Rolle des Unternehmens",
    "verifyAndSubmitStepTitle": "Prüfen & Einreichen",
    "companyData": {
      "title": "Unternehmensdaten hinzufügen",
      "description": "Bitte fügen Sie unten die Daten Ihrer juristischen Person hinzu. Pflichtfelder sind mit (*) gekennzeichnet und das Formular ist mit den Informationen vorausgefüllt, die Sie uns bereits bei der Anmeldung mitgeteilt haben.",
      "organizationName": "Name der Organisation",
      "organizationNameTooltip": "Name der registrierten Organisation",
      "enterYourData": "Bitte geben Sie Ihre Daten an",
      "bpn": "Unternehmenspartnernummer",
      "bpnTooltip": "Die Business Partner Nummer (BPN) ist eine eindeutige Kennung, die jedem Geschäftspartner im Catena-X Ökosystem zugewiesen wird.",
      "legalEntityName": "Rechtlicher Name des Unternehmens",
      "registeredName": "Registrierter Name",
      "orgAddress": "Anschrift der Organisation",
      "streetName": "Straße",
      "houseNumber": "Hausnummer",
      "postCode": "Postleitzahl",
      "city": "Stadt",
      "countryCode": "Ländercode",
      "region": "Region",
      "identifierType": "Bezeichnertyp",
      "companyIdentifier": "Kennung des Unternehmens",
      "identifierNumber": "Identifikationsnummer",
      "contactInfo": "Kontaktinformationen",
      "emailAddress": "E-Mail-Adresse",
      "userRole": "Benutzerrolle",
      "CRN": {
        "label": "Handelsregisternummer",
        "helpText": "Beispiel: HRB 55933",
        "tooltipText": "Bei der Handelsregisternummer handelt es sich um eine eindeutige Kennung, die Unternehmen für ihre handelsrechtliche Registrierung zugewiesen wird. Sie wird zur Validierung und Verifizierung der Authentizität der Registrierung eines Unternehmens und der Kennungen von Finanztransaktionen auf Grundlage vertrauenswürdiger Datenquellen genutzt."
      },
      "vatId": {
        "label": "USt IdNr.",
        "helpText": "Beispiel: DE 118 645 675",
        "tooltipText": "Eine USt-IdNr. (Umsatzsteueridentifikationsnummer) ist eine eindeutige Kennung, die Unternehmen für Steuerzwecke zugewiesen wird."
      }
    },
    "companyRole": {
      "title": "Die Rolle Ihres Unternehmens",
      "description": "Bitte wählen Sie eine oder mehrere Unternehmensrollen aus, mit denen Sie im Catena-X Netzwerk agieren/teilnehmen möchten. Die Unternehmensrolle kann später jederzeit aktualisiert/geändert werden. Je nach gewählter Rolle können sich die angebotenen Portaldienste unterscheiden.",
      "activeParticipanyNote": "Passen Sie Ihre Catena-X Teilnahme mit T-Systems Connect & Integrate an und optimieren Sie diese für eine einfache und automatisierte Verknüpfung mit Catena-X. Bestellen Sie Connect & Integrate aus dem Cofinity App Marketplace für die führenden Catena-X Use Cases und starten Sie sofort mit dem Datenaustausch! Ab 49 EUR/Monat und für alle Unternehmensgrößen verfügbar.",
      "agreeTermsCheckbox": "Ja, ich stimme dem {link} der ausgewählten Rolle zu",
      "activeParticipantNote": {
        "para1": "Passen Sie Ihre Catena-X Teilnahme mit {link} an und optimieren Sie diese für eine einfache und automatisierte Verknüpfung mit Catena-X.",
        "tSystemsConnectAndIntegrate": "T-Systems Connect & Integrate",
        "para2": "Bestellen Sie Connect & Integrate aus dem Cofinity App Marketplace für die führenden Catena-X Use Cases und starten Sie sofort mit dem Datenaustausch!",
        "para3": "Ab {price}/Monat und für alle Unternehmensgrößen verfügbar."
      },
      "appProviderNote": {
        "para1" : "{link} ermöglicht es Ihnen, Geschäftsanwendungen innerhalb unserer offiziellen Catena-X Sandbox-Umgebung zu entwickeln, zu verwalten und zu zertifizieren.",
        "para2": "Bestellen Sie Build & Operate noch heute und überlassen Sie uns die Verwaltung Ihrer Dataspace-Infrastruktur, während Sie sich auf Innovationen konzentrieren!",
        "para1PlaceholderLink": "Build & Operate von T-Systems",
        "optionalInformation": "Optionale Angaben"
      }
    },
    "verifyAndSubmit": {
      "title": "Überprüfen Sie Ihre Registrierungsdaten",
      "description": "Überprüfen Sie Ihre Registrierungsdaten, bevor Sie die Registrierung abschicken. Bitte stellen Sie sicher, dass alle Angaben korrekt sind. Sobald die Überprüfung abgeschlossen ist, klicken Sie unten auf die Schaltfläche 'Absenden', um die Registrierung zur Genehmigung einzureichen.",
      "companyData": "Unternehmensdaten",
      "activeRole": "Aktive Rolle",
      "consentText": "Ich willige ein, dass T-Systems International GmbH meine Registrierungsanfrage an die Cofinity-X GmbH weiterleitet und die entsprechenden personenbezogenen Daten zum Zwecke der Durchführung des Onboarding-Verfahrens und damit verbundener Zwecke verarbeitet. Ich erkenne an, dass meine Daten in Übereinstimmung mit den geltenden Datenschutzgesetzen und -vorschriften, einschließlich, aber nicht beschränkt auf die Allgemeine Datenschutzverordnung (GDPR), verarbeitet werden. Mir ist bekannt, dass meine personenbezogenen Daten nur so lange gespeichert werden, wie es für die Erfüllung dieser Zwecke erforderlich ist. Mir ist bekannt, dass diese Einwilligung für die Registrierung und das Onboarding bei der Cofinity-X GmbH erforderlich ist, und dass ich meine Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen kann."
    },
    "actions": {
      "back": "Zurück",
      "next": "Weiter",
      "submit": "Abschicken"
    },
    "validation": {
      "DE_COMMERCIAL_REG_NUMBER": "Please enter a valid number. Hint: Alphanumeric with hyphen and space with exact 9 digits.",
      "DE_VAT_ID": "Please enter a valid number. Hint: Starting with DE and followed by 9 digit numbers",
      "Worldwide_COMMERCIAL_REG_NUMBER": "Please enter a valid number. Hint: Alphanumeric with hyphen with 6 to 21 digits.",
      "Worldwide_VAT_ID": "Please enter a valid number. Hint: Alphanumeric with hyphen and space with 8 to 15 digits.",
      "invalid_bpn": "Ungültige BPN-Nummer. Die BPN-Nummer muss mit BPNL beginnen, gefolgt von 12 alphanumerischen Zeichen."
    },
    "error": {
      "title": "Validierungsfehler",
      "para": "Entweder ist Ihre Organisation bereits in das Cofinity-X-Netz eingebunden, oder es gibt technische Probleme, die derzeit gelöst werden. Bitte lassen Sie uns mehr über Ihre Erfahrungen wissen, damit wir Ihnen weiterhelfen können.",
      "buttonText": "Kontaktiere uns"
    },
    "success": {
      "title": "Vielen Dank für Ihre Anfrage.",
      "para": "Ihre Angaben wurden an Cofinity-X gesendet, Sie erhalten Informationen über die nächsten Schritte per E-Mail",
      "buttonText": "Zur Startseite"
    }
  }
}
