<template>
  <div
    class="t-chatbox t-flex t-flex-column"
  >
    <ChatboxHeader @close-chat="$emit('close-chat')" />
    <div
      ref="chatboxContentContainer"
      class="t-chatbox-content"
    >
      <ChatboxMessageFeed
        :messages="messages"
        @vue:mounted="scrollChatToBottom"
        @vue:updated="scrollChatToBottom"
      />
    </div>
    <ChatboxFooter :suggested-answers="suggestedAnswers" />
  </div>
</template>

<script setup lang="ts">
import ChatboxMessageFeed from './ChatboxMessageFeed.vue';
import ChatboxFooter from './ChatboxFooter.vue';
import ChatboxHeader from './ChatboxHeader.vue';
import type { SuggestedAnswer, ChatMessage } from './IChatbox';
import SupportAvatar from '../assets/support-avatar.svg';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { SUPPORT_EMAIL } from '@/common/constants';

const { t } = useI18n();

defineEmits<{(e: 'close-chat'): void}>();

const messages = computed<ChatMessage[]>(() => [
  {
    sender: { name: 'Support representative', picture: SupportAvatar },
    body: t('helpWidget.defaultMessage.body'),
    date: Date.now(),
  },
]);

const suggestedAnswers = computed<SuggestedAnswer[]>(() => [
  {
    url: 'https://docs.dih.telekom.com/',
    caption: t('helpWidget.suggestedAnswers.knowledgeBase'),
  },
  {
    url: `mailto:${SUPPORT_EMAIL}`,
    caption: t('helpWidget.suggestedAnswers.contactSupport'),
  },
  {
    url: 'mailto:DIH_Sales@telekom.de',
    caption: t('helpWidget.suggestedAnswers.contactSales'),
  },
]);

const chatboxContentContainer = ref<HTMLDivElement | null>(null);

const scrollChatToBottom = () => {
  if (chatboxContentContainer.value) {
    chatboxContentContainer.value.scrollTo({
      top: chatboxContentContainer.value.scrollHeight * 2,
      left: 0,
      behavior: 'smooth',
    });
  }
};
</script>

<style scoped lang="scss">
@import '@/styles/utilities/variables';
@import '@/styles/variables/spacing';

.t-chatbox {
  border-radius: var(--scl-radius-8);
  box-shadow: var(--telekom-shadow-floating-standard);
  max-width: 324px;
  width: 100%;
  background: var(--telekom-color-background-canvas);
  overflow: hidden;
  margin-right: $space-2;
  pointer-events: auto;
  &-content {
    height: 290px;
    overflow-y: auto;
    padding: $space-1 $space-5;
  }
}
</style>
