import axios, { type AxiosError, type AxiosResponse } from 'axios';
import Util from '../util';

const myCertificatesServices = {
  fetchCertificatesList: (payload: { orgId: string, type: string, status: string, searchText: string }) => {
    return axios.get(
      Util.getRestApiUrl(`certificates/specification?orgId=${payload.orgId}&type=${payload.type}&status=${payload.status}&searchText=${payload.searchText}`,
        'digital-id',
      ), {});
  },

  downloadCertificate: (uuid: string) => {
    return axios.get(Util.getRestApiUrl(`certificates/file/${uuid}`, 'digital-id'));
  },

  deleteCertificate: (uuid: string): Promise<AxiosError | AxiosResponse> => {
    return axios.delete(Util.getRestApiUrl(`certificates/${uuid}`, 'digital-id'));
  },
};

export default myCertificatesServices;
