import type { GenericObject } from '../interfaces/IGeneric';
import { required, url } from '@vuelidate/validators';

export const filterOptions: GenericObject[] = [
  {
    text: 'verify.verifiableCredentials.status.all',
    value: '',
  },
  {
    text: 'verify.verifiableCredentials.status.valid',
    value: 'valid',
  },
  {
    text: 'verify.verifiableCredentials.status.expired',
    value: 'expired',
  },
];

export const requestVCTypeOptions: GenericObject[] = [
  {
    text: 'verify.verifiableCredentials.form.types.legalPerson',
    value: 'LEGAL_PERSON',
  },
  {
    text: 'verify.verifiableCredentials.form.types.serviceOffering',
    value: 'SERVICE_OFFERING',
  },
  {
    text: 'verify.verifiableCredentials.form.types.resource',
    value: 'RESOURCE',
  },
];

export const requestTypeSOOptions: GenericObject[] = [
  {
    text: 'verify.serviceOfferingForm.requestTypesList.api',
    value: 'API',
  },
  {
    text: 'verify.serviceOfferingForm.requestTypesList.email',
    value: 'email',
  },
  {
    text: 'verify.serviceOfferingForm.requestTypesList.webform',
    value: 'webform',
  },
  {
    text: 'verify.serviceOfferingForm.requestTypesList.unregisteredLetter',
    value: 'unregisteredLetter',
  },
  {
    text: 'verify.serviceOfferingForm.requestTypesList.registeredLetter',
    value: 'registeredLetter',
  },
  {
    text: 'verify.serviceOfferingForm.requestTypesList.supportCenter',
    value: 'supportCenter',
  },
];

export const accessTypeSOOptions: GenericObject[] = [
  {
    text: 'verify.serviceOfferingForm.accessTypesList.digital',
    value: 'digital',
  },
  {
    text: 'verify.serviceOfferingForm.accessTypesList.physical',
    value: 'physical',
  },
];

export const resourceTypeOptions: GenericObject[] = [
  {
    text: 'verify.resourceForm.physicalResource',
    value: 'gx:PhysicalResource',
  },
  {
    text: 'verify.resourceForm.softwareResource',
    value: 'gx:SoftwareResource',
  },
  {
    text: 'verify.resourceForm.dataResource',
    value: 'gx:DataResource',
  },
];

export const pageSize = 20;
export const apiLimitUsageThreshold = 90;
export const digitalIdProduct = 'DIGITAL_ID';
export const digitalIdMyCertificatesProducts = 'CERTIFICATE';
export const digitalIdAPIUsageProduct = 'DIGITAL_ID_API_LIMIT';

export enum enumCredentialStatus {
  VALID = 'valid',
  EXPIRED = 'expired',
}

export enum enumRequestType {
  legalPerson = 'LEGAL_PERSON',
  serviceOffering = 'SERVICE_OFFERING',
  resource = 'RESOURCE',
}

export enum enumResourceType {
  physical = 'gx:PhysicalResource',
  software = 'gx:SoftwareResource',
  data = 'gx:DataResource',
}

export const partnerValidationLink = 'https://docs.dih.telekom.com/en/digitalid/business-partner-validation';
export const typeSelectHelpLink = 'https://docs.dih.telekom.com/en/digitalid';
export const gaiaXTermsLink = 'https://gx-registry.gxdch.dih.telekom.com/v1/api/termsAndConditions';
// CSV file Format Value
export const CSVFormat = 'LEGAL_NAME;COUNTRY;REGION;STREET;CITY;POSTAL_CODE;CRN;VAT_ID;EORI;LEI;DID';

export const integrationValidationRules = {
  validationClientId: {
  },
  complianceClientId: {
  },
  validationClientSecret: {
  },
  complianceClientSecret: {
  },
  validationTokenUrl: {
    url
  },
  complianceTokenUrl: {
    url
  },
  legalPersonCallbackUrl: {
    url
  },
  serviceOfferingCallbackUrl: {
    url
  },
}

export const clientIntegrationValidationRules = {
  clientId: {
    required
  }
}
