import type { ActionTree } from 'vuex';
import { FETCH_COUNTRIES, FETCH_REGIONS } from '../action-types';
import {
  SET_COUNTRIES,
  SET_REGIONS,
} from '../mutation-types';
import GetVerifiedService from '@/common/services/GetVerifiedServices';
import { ADD_ERROR_NOTIFICATION } from '@/components/NotificationBar/store/mutation-types';
import type { IGetVerifiedState } from '../index';

export const actions: ActionTree<IGetVerifiedState, {}> = {
  [FETCH_COUNTRIES] ({ commit }, language: string) {
    return new Promise((resolve, reject) => {
      GetVerifiedService.getAllCountries(language)
        .then((res) => {
          const { data } = res;
          commit(SET_COUNTRIES, data);
          resolve('success');
        })
        .catch(() => {
          commit(ADD_ERROR_NOTIFICATION, 'genericError', { root: true });
          reject(new Error('error'));
        });
    });
  },

  [FETCH_REGIONS] ({ commit }, country: string) {
    return new Promise((resolve, reject) => {
      GetVerifiedService.getRegions(country)
        .then((res) => {
          const { data } = res;
          commit(SET_REGIONS, data);
          resolve('success');
        })
        .catch(() => {
          commit(ADD_ERROR_NOTIFICATION, 'genericError', { root: true });
          reject(new Error('error'));
        });
    });
  },
};
