import i18n from '@/i18n';
import { DateTimeFormat } from '@/components/global/TDateTime/IDateTime';

const dateUtil = {
  /**
   * Format a timestamp into a custom date and time string.
   * @param {string} timestamp - A string representing a timestamp.
   * @param {DateTimeFormat} format - A format string containing placeholders for year, month, day, hours, minutes, and seconds.
   * @returns {string} - Formatted date and time string.
   */
  formatDateTime: (timestamp: string, format: DateTimeFormat): string => {
    const trimmedTimestamp = timestamp.split('.')[0] + 'Z';
    const date = new Date(trimmedTimestamp);

    if (isNaN(date.getTime())) {
      return '-';
    }

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const locale = i18n.global.locale.value;

    const is24HourFormat = format !== DateTimeFormat.HH_mm_AM_PM;

    const parts = new Intl.DateTimeFormat(locale, {
      timeZone: timezone,
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: !is24HourFormat,
    }).formatToParts(date);

    const lookup: Record<string, string> = {};
    parts.forEach(({ type, value }) => {
      lookup[type] = value;
    });

    const numericMonth = new Intl.DateTimeFormat(locale, {
      timeZone: timezone,
      month: '2-digit',
    }).format(date);

    const year = lookup.year ?? '';
    const monthLetters = lookup.month ?? '';
    const day = lookup.day ?? '';
    const hours = lookup.hour ?? '';
    const minutes = lookup.minute ?? '';
    const seconds = lookup.second ?? '';
    const ampm = lookup.dayPeriod ?? '';

    const parsedHours = parseInt(hours, 10);
    const amPmHours = is24HourFormat
      ? hours
      : !isNaN(parsedHours)
          ? (parsedHours % 12 === 0 ? 12 : parsedHours % 12)
          : 12;

    let formattedDate = format as string;

    formattedDate = formattedDate.replace('YYYY', year);
    formattedDate = formattedDate.replace('MMM', monthLetters);
    formattedDate = formattedDate.replace('MM', numericMonth);
    formattedDate = formattedDate.replace('DD', day);
    formattedDate = formattedDate.replace('HH', hours.padStart(2, '0'));
    formattedDate = formattedDate.replace('mm', minutes.padStart(2, '0'));
    formattedDate = formattedDate.replace('ss', seconds.padStart(2, '0'));
    formattedDate = formattedDate.replace('hhh', amPmHours.toString().padStart(2, '0'));
    formattedDate = formattedDate.replace('ampm', ampm);

    return formattedDate;
  },
};

export default dateUtil;
