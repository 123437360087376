import { v4 as uuidv4 } from 'uuid';
import store from '@/store';
import { ADD_HINT_NOTIFICATION } from '@/components/NotificationBar/store/mutation-types';

export default class Util {
  /**
   * Get REST API URL
   * @param {string} url part to get full URL
   * @param {number} version Endpoint versions
   * @returns {string} full REST API URL
   */
  static getRestApiUrl (url: string, type: string) {
    let API_ENDPOINT = '';
    switch (type) {
      case 'org':
        API_ENDPOINT = `${import.meta.env.VITE_APP_API_ORG_USER_URL}`;
        break;
      case 'dataspace':
        API_ENDPOINT = `${import.meta.env.VITE_APP_API_DATASPACE_URL}`;
        break;
      case 'plan':
        API_ENDPOINT = `${import.meta.env.VITE_APP_API_PLAN_URL}`;
        break;
      case 'cx-bridge':
        API_ENDPOINT = `${import.meta.env.VITE_APP_CX_BRIDGE_URL}`;
        break;
      case 'notification':
        API_ENDPOINT = `${import.meta.env.VITE_APP_API_NOTIFICATION_URL}`;
        break;
      case 'dataspace-test-connector':
        API_ENDPOINT = `${import.meta.env.VITE_APP_TEST_CONNECTOR}`;
        break;
      case 'digital-id':
        API_ENDPOINT = `${import.meta.env.VITE_APP_API_DIGITAL_ID_URL}`;
        break;
    }
    return `${API_ENDPOINT}/${url}`;
  }

  /**
   * Create two way mapper Getter <-> Mutation for computed property
   * @param {string} getter
   * @param {string} mutation
   * @returns {any} Mapper for computed property
   */
  static mapTwoWay<T>(getter: string, mutation: string) {
    return {
      get (): T {
        return store.getters[getter];
      },
      set (value: T) {
        store.commit(mutation, value);
      },
    };
  }

  public static generateUUID () {
    return uuidv4();
  }

  /**
   * Sort by field function
   * @param {string} field Field to sort for
   * @param {boolean} reverse Reverse sorting direction
   * @param {boolean} ignoreCase case for strings
   * @returns {(a: {[p: string]: {}}, b: {[p: string]: {}}) => (number | number)} Sort function
   */
  static sortByField<T extends object>(field: keyof T, reverse = false, ignoreCase = false) {
    return (a: T, b: T) => {
      const aField = ignoreCase ? String(a[field]).toLowerCase() : a[field];
      const bField = ignoreCase ? String(b[field]).toLowerCase() : b[field];

      return reverse
        ? Util.compareValues(aField, bField)
        : Util.compareValues(bField, aField);
    };
  }

  // window scroll to top
  static scrollToTop (): void {
    window.scroll(0, 0);
  }

  private static compareValues (a: any, b: any): number {
    if (a > b) {
      return 1;
    }
    if (a < b) {
      return -1;
    }
    return 0;
  }
}

/**
 * Sort by field function
 * @param {string} field Field to sort for
 * @param {boolean} reverse Reverse sorting direction
 * @param {boolean} ignoreCase case for strings
 * @returns {(a: {[p: string]: {}}, b: {[p: string]: {}}) => (number | number)} Sort function
 */
export function sortByField<T extends object> (field: keyof T, reverse = false, ignoreCase = false) {
  return (a: T, b: T) => {
    const aField = ignoreCase ? String(a[field]).toLowerCase() : a[field];
    const bField = ignoreCase ? String(b[field]).toLowerCase() : b[field];

    return reverse ? compareValues(aField, bField) : compareValues(bField, aField);
  };
}

// sort
export function compareValues (a: {}, b: {}): number {
  if (a > b) {
    return 1;
  }
  if (a < b) {
    return -1;
  }
  return 0;
}

// copy to clipboard
export function copyToClipboard (text: string) {
  void navigator.clipboard.writeText(text);
  store.commit(ADD_HINT_NOTIFICATION, 'Copied to clipboard');
}
