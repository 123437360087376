import axios from 'axios';
import Util from '../util';
import type { subscriptionPackageInterface, credentialInterface, getWalletAPIPayloadInt } from '../interfaces/verify/IGetVerified';
import type { 
  legalPersonPayloadDataInterface,
  serviceOfferingPayloadDataInterface,
  resourcePayloadDataInterface,
  bulkVCPayloadData,
} from '../../modules/verify/pages/vcRequest/interface';

const getVerifiedService = {
  getAllCountries: (locale: string) => {
    return axios.get(Util.getRestApiUrl(`country?${locale}`, 'org'));
  },

  getRegions: (country: string) => {
    return axios.get(Util.getRestApiUrl(`region?isoCode=${country}`, 'org'));
  },

  fetchSubscriptionData: (orgId: string): Promise<{ data: subscriptionPackageInterface, status: number }> => {
    return axios.get(Util.getRestApiUrl(`subscriptions/fetch/${orgId}`, 'digital-id'));
  },

  requestIntegration: (requestPayload: unknown) => {
    return axios.post(Util.getRestApiUrl('technical', 'digital-id'), requestPayload, {
      headers: {
        UI: true,
      },
    });
  },

  editIntegration: (requestPayload: unknown) => {
    return axios.post(Util.getRestApiUrl('technical/callbackProperties', 'digital-id'), requestPayload, {
      headers: {
        UI: true,
      },
    });
  },

  getWalletVC: (requestPayload: getWalletAPIPayloadInt): Promise<{ data: credentialInterface[], status: number }> => {
    return axios.post(Util.getRestApiUrl('credentials/internal/get', 'digital-id'), requestPayload);
  },

  issueWalletVC: (
    requestPayload: legalPersonPayloadDataInterface | serviceOfferingPayloadDataInterface | resourcePayloadDataInterface | bulkVCPayloadData,
  ): Promise<{ data: credentialInterface[], status: number }> => {
    return axios.post(Util.getRestApiUrl('credentials/internal/issue', 'digital-id'), requestPayload);
  },

  downloadVP: (uuid: string): Promise<{ data: any, status: number }> => {
    return axios.get(Util.getRestApiUrl(`compliance/json/${uuid}`, 'digital-id'));
  },
};

export default getVerifiedService;
