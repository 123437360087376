import type { ActionTree } from 'vuex';
import { FETCH_API_USAGE_LIMIT, FETCH_SUBSCRIPTION_DATA } from '../action-types';
import { SET_API_USAGE_LIMIT_DATA, SET_FETCH_API_USAGE_IN_PROGRESS } from '../mutation-types';
import GetInsightsServices from '@/common/services/GetInsightsServices';
import { ADD_ERROR_NOTIFICATION } from '@/components/NotificationBar/store/mutation-types';
import GetVerifiedService from '@/common/services/GetVerifiedServices';
import type { IGetVerifiedState } from '../index';

export const actions: ActionTree<IGetVerifiedState, {}> = {
  async [FETCH_API_USAGE_LIMIT] ({ commit }) {
    commit(SET_FETCH_API_USAGE_IN_PROGRESS, true);

    try {
      const response = await GetInsightsServices.fetchApiUsageLimit();
      const { data } = response;
      commit(SET_API_USAGE_LIMIT_DATA, data);
    } catch (error) {
      commit(ADD_ERROR_NOTIFICATION, 'genericError', { root: true });
    } finally {
      commit(SET_FETCH_API_USAGE_IN_PROGRESS, false);
    }
  },

  async [FETCH_SUBSCRIPTION_DATA] ({ commit }, orgId: string) {
    try {
      const response = await GetVerifiedService.fetchSubscriptionData(orgId);
      return response?.data;
    } catch (error) {
      throw new Error('error');
    }
  },
};
