import type { ActionTree } from 'vuex';
import { FETCH_REPORT_LIST, DOWNLOAD_REPORTS } from '../action-types';
import { SET_REPORT_LIST_DETAILS, SET_FETCH_REPORT_LIST_IN_PROGRESS } from '../mutation-types';
import GetInsightsServices from '@/common/services/GetInsightsServices';
import {
  ADD_ERROR_NOTIFICATION,
  ADD_SUCCESS_NOTIFICATION
} from '@/components/NotificationBar/store/mutation-types';
import type { IGetVerifiedState } from '../index';
import saveFile from 'save-as-file';
import i18n from '@/i18n';

export const actions: ActionTree<IGetVerifiedState, {}> = {
  async [FETCH_REPORT_LIST] ({ commit, state }) {
    commit(SET_FETCH_REPORT_LIST_IN_PROGRESS, true);
    const { startDate, endDate, page, pageSize, find, sort } = state.reportListFilterParams;
    try {
      const response = await GetInsightsServices.fetchReportsRequest(startDate, endDate, page, pageSize, find, sort);
      const { status, data } = response;
      if (status === 200) {
        commit(SET_REPORT_LIST_DETAILS, data);
        return data;
      } else throw new Error('error');
    } catch (error) {
      commit(ADD_ERROR_NOTIFICATION, 'genericError', { root: true });
    } finally {
      commit(SET_FETCH_REPORT_LIST_IN_PROGRESS, false);
    }
  },

  async [DOWNLOAD_REPORTS] ({ commit }, payload: string[]) {
    try {
      const res = await GetInsightsServices.downloadReport(payload);
      const data = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
      saveFile(data, payload.length > 1 ? 'validationReports.zip' : payload[0]);
      commit(ADD_SUCCESS_NOTIFICATION, i18n.global.t('verify.getInsights.successDownload'), { root: true });
    } catch {
      commit(ADD_ERROR_NOTIFICATION, i18n.global.t('verify.getInsights.errorDownload', { filename: payload }), { root: true });
    }
  },
};
