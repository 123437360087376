import { actions as geolocationActions } from './geolocations';
import { actions as verifiableCredentialsActions } from './verifiableCredentials';
import { actions as reportsActions } from './reports';
import { actions as signaturesActions } from './signatures';
import { actions as technicalIntegrationActions } from './technicalIntegration';
import { actions as usageLimitActions } from './usageLimit';

export function errorMessagesList (errors: Array<{ code: string, detailMessage: string }>) {
  const errorMessages: string[] = [];
  errors.forEach((error: { code: string, detailMessage: string }) => errorMessages.push(error.detailMessage));
  return errorMessages;
}

export default {
  ...geolocationActions,
  ...verifiableCredentialsActions,
  ...reportsActions,
  ...signaturesActions,
  ...technicalIntegrationActions,
  ...usageLimitActions,
};
