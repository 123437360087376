import type { ActionTree } from 'vuex';
import { DELETE_INTEGRATION, DOWNLOAD_INTEGRATION } from '../action-types';
import { ADD_ERROR_NOTIFICATION, ADD_SUCCESS_NOTIFICATION } from '@/components/NotificationBar/store/mutation-types';
import GetInsightsServices from '@/common/services/GetInsightsServices';
import i18n from '@/i18n';
import { AxiosError } from 'axios';
import type { IGetVerifiedState } from '../index';
import { errorMessagesList } from './index';

export const actions: ActionTree<IGetVerifiedState, {}> = {
  async [DELETE_INTEGRATION] ({ commit }, clientId: string) {
    try {
      const response = await GetInsightsServices.deleteIntegration(clientId);
      commit(ADD_SUCCESS_NOTIFICATION, `${clientId} - ` + i18n.global.t('verify.addIntegration.deleteSuccess'), { root: true });
      return 'success';
    } catch (error) {
      if (error instanceof AxiosError && error?.response?.data?.errors) {
        const errorsList = errorMessagesList(error.response.data.errors);
        commit(ADD_ERROR_NOTIFICATION, { text: errorsList.length > 1 ? i18n.global.t('common.errors') : i18n.global.t('common.error'), list: errorsList }, { root: true });
      } else {
        commit(ADD_ERROR_NOTIFICATION, 'genericError', { root: true });
      }
      return 'error';
    }
  },

  async [DOWNLOAD_INTEGRATION] ({ commit }, clientId: string) {
    try {
      const response = await GetInsightsServices.downloadIntegration(clientId);
      commit(ADD_SUCCESS_NOTIFICATION, `${clientId} - ` + i18n.global.t('verify.addIntegration.downloadSuccess'), { root: true });
      return response?.data;
    } catch (error) {
      commit(ADD_ERROR_NOTIFICATION, 'genericError', { root: true });
    }
  },
};
