import { computed } from 'vue';

import {
  createRouter,
  createWebHistory,
  type NavigationGuardNext,
  type RouteLocationNormalized,
  type RouteRecordRaw,
} from 'vue-router';
import store from '@/store';

import manageRoutes from './protected/manage';

import { SET_PAGE_BREADCRUMBS } from '@/store/modules/user/mutation-types';
import { GET_USER_INFO, GET_IS_EXTERNAL_ACCOUNT_COMPLETED } from '@/store/modules/user/getter-types';
import { CHECK_TOKEN } from '@/store/modules/auth/action-types';
import UserService from '@/common/services/UserServices';

import { EnumUserStatus } from '@/common/interfaces/user/IUser';
import { Permission } from '@/common/enums/Permission';

import { isNullish } from '@/utils/check-runtime-types';
import { usePermission } from '@/composables/usePermission';

export const env: string = import.meta.env.VITE_APP_ENVIRONMENT;

const dataspaceConnectGuard = (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
  if (from.fullPath === '/') {
    const reg = /\/dataspaces\/user(\/?)$/gm;
    if (reg.exec(to.fullPath) !== null) {
      return router.push('/dataspaces/user');
    } else {
      return router.push('/dataspaces/browse');
    }
  }
  return true;
};

const isExternalAccountCompleted = computed<boolean>(() => {
  return store.getters[`UserProfileStore/${GET_IS_EXTERNAL_ACCOUNT_COMPLETED}`];
});

const routes: RouteRecordRaw[] = [{
  path: '',
  name: 'dashboard',
  component: () => import('@/layout/DashboardLayout.vue'),
  redirect: '/dashboard',
  meta: {
    isProtected: true,
  },
  children: [
    {
      path: '/dashboard',
      name: 'maindashboard',
      meta: {
        isProtected: true,
        navigation: [{
          title: 'Home',
          translationKey: 'common.home',
          routeName: 'maindashboard',
        }],
      },
      beforeEnter: async (_: RouteLocationNormalized, from: RouteLocationNormalized) => {
        if (from.fullPath === '/') {
          try {
            const result = await UserService.getUserInfo();
            if (result.data.firstLogin as boolean) {
              return await router.push('/dataspaces/user');
            } else {
              return true;
            }
          } catch (error) {
            return true;
          }
        } else {
          return true;
        }
      },
      component: () => import('@/modules/dashboard/DashboardView.vue'),
    }, {
      path: '/marketplace',
      name: 'marketplace',
      component: () => import('@/layout/AbstractRouteView.vue'),
      redirect: '/marketplace/discover',
      meta: {
        isProtected: true,
      },
      children: [
        {
          path: 'discover',
          name: 'discover',
          component: () => import('@/layout/AbstractRouteView.vue'),
          meta: {
            isProtected: true,
          },
          children: [
            {
              path: '',
              name: 'discoverDataLandingPage',
              component: () => import('@/modules/marketplace/discover/pages/DiscoverDataLandingPage.vue'),
              meta: {
                isProtected: true,
                permission: Permission.VIEW_DATA_PRODUCTS,
                navigation: [{
                  title: 'Discover',
                  translationKey: 'sideMenu.discover',
                  routeName: '',
                }],
              },
            },
            {
              path: 'data-offers',
              name: 'dataOffers',
              children: [
                {
                  path: '',
                  name: 'dataOffersLandingPage',
                  component: () => import('@/modules/marketplace/discover/pages/DiscoverDataOffers.vue'),
                  meta: {
                    navigation: [{
                      title: 'Discover',
                      translationKey: 'sideMenu.discover',
                      routeName: 'discoverDataLandingPage',
                    },
                    {
                      title: 'Data Offers',
                      translationKey: 'discover.dataOffers.title',
                      routeName: '',
                    }],
                  },
                },
                {
                  path: 'visitor-analytics-pro-by-motion-data/offer-details',
                  name: 'offerDetails',
                  redirect: { name: 'productOverview' },
                  component: () => import('@/modules/marketplace/discover/pages/DiscoverOfferDetails.vue'),
                  children: [
                    {
                      path: 'product-overview',
                      name: 'productOverview',
                      component: () => import('@/modules/marketplace/discover/pages/discover-offer-details-pages/ProductOverview.vue'),
                      meta: {
                        navigation: [{
                          title: 'Discover',
                          translationKey: 'sideMenu.discover',
                          routeName: 'discoverDataLandingPage',
                        },
                        {
                          title: 'Data Offers',
                          translationKey: 'discover.dataOffers.title',
                          routeName: 'dataOffersLandingPage',
                        },
                        {
                          title: 'Traffic Analytics by Motion Data',
                          translationKey: 'discover.dataOffers.tiles.product3.name',
                          routeName: 'offerDetails',
                        },
                        {
                          title: 'Offer Details',
                          translationKey: 'discover.dataOffers.offerDetails.title',
                          routeName: 'offerDetails',
                        },
                        {
                          title: 'Product Overview',
                          translationKey: 'discover.dataOffers.offerDetails.productOverview.title',
                          routeName: '',
                        }],
                      },
                    },
                    {
                      path: 'general-information',
                      name: 'generalInformation',
                      component: () => import('@/modules/marketplace/discover/pages/discover-offer-details-pages/GeneralInformation/GeneralInformation.vue'),
                      meta: {
                        navigation: [{
                          title: 'Discover',
                          translationKey: 'sideMenu.discover',
                          routeName: 'discoverDataLandingPage',
                        },
                        {
                          title: 'Data Offers',
                          translationKey: 'discover.dataOffers.title',
                          routeName: 'dataOffersLandingPage',
                        },
                        {
                          title: 'Traffic Analytics by Motion Data',
                          translationKey: 'discover.dataOffers.tiles.product3.name',
                          routeName: 'offerDetails',
                        },
                        {
                          title: 'Offer Details',
                          translationKey: 'discover.dataOffers.offerDetails.title',
                          routeName: 'offerDetails',
                        },
                        {
                          title: 'General Information',
                          translationKey: 'discover.dataOffers.offerDetails.generalInformation.title',
                          routeName: '',
                        }],
                      },
                    },
                    {
                      path: 'sample-data',
                      name: 'sampleData',
                      component: () => import('@/modules/marketplace/discover/pages/discover-offer-details-pages/SampleData.vue'),
                      meta: {
                        navigation: [{
                          title: 'Discover',
                          translationKey: 'sideMenu.discover',
                          routeName: 'discoverDataLandingPage',
                        },
                        {
                          title: 'Data Offers',
                          translationKey: 'discover.dataOffers.title',
                          routeName: 'dataOffersLandingPage',
                        },
                        {
                          title: 'Traffic Analytics by Motion Data',
                          translationKey: 'discover.dataOffers.tiles.product3.name',
                          routeName: 'offerDetails',
                        },
                        {
                          title: 'Offer Details',
                          translationKey: 'discover.dataOffers.offerDetails.title',
                          routeName: 'offerDetails',
                        },
                        {
                          title: 'Sample Data',
                          translationKey: 'discover.dataOffers.offerDetails.sampleData.title',
                          routeName: '',
                        }],
                      },
                    },
                    {
                      path: 'terms-of-use',
                      name: 'termsOfUse',
                      component: () => import('@/modules/marketplace/discover/pages/discover-offer-details-pages/TermsOfUse/TermsOfUse.vue'),
                      meta: {
                        navigation: [{
                          title: 'Discover',
                          translationKey: 'sideMenu.discover',
                          routeName: 'discoverDataLandingPage',
                        },
                        {
                          title: 'Data Offers',
                          translationKey: 'discover.dataOffers.title',
                          routeName: 'dataOffersLandingPage',
                        },
                        {
                          title: 'Traffic Analytics by Motion Data',
                          translationKey: 'discover.dataOffers.tiles.product3.name',
                          routeName: 'offerDetails',
                        },
                        {
                          title: 'Offer Details',
                          translationKey: 'discover.dataOffers.offerDetails.title',
                          routeName: 'offerDetails',
                        },
                        {
                          title: 'Terms Of Use',
                          translationKey: 'discover.dataOffers.offerDetails.termsOfUse.title',
                          routeName: '',
                        }],
                      },
                    },
                    {
                      path: 'additional-information',
                      name: 'additionalInformation',
                      component: () => import('@/modules/marketplace/discover/pages/discover-offer-details-pages/AdditionalInformation/AdditionalInformation.vue'),
                      meta: {
                        navigation: [{
                          title: 'Discover',
                          translationKey: 'sideMenu.discover',
                          routeName: 'discoverDataLandingPage',
                        },
                        {
                          title: 'Data Offers',
                          translationKey: 'discover.dataOffers.title',
                          routeName: 'dataOffersLandingPage',
                        },
                        {
                          title: 'Traffic Analytics by Motion Data',
                          translationKey: 'discover.dataOffers.tiles.product3.name',
                          routeName: 'offerDetails',
                        },
                        {
                          title: 'Offer Details',
                          translationKey: 'discover.dataOffers.offerDetails.title',
                          routeName: 'offerDetails',
                        },
                        {
                          title: 'Additional Information',
                          translationKey: 'discover.dataOffers.offerDetails.additionalInformation.title',
                          routeName: '',
                        }],
                      },
                    },
                  ],
                  meta: {
                    navigation: [{
                      title: 'Discover',
                      translationKey: 'sideMenu.discover',
                      routeName: 'discoverDataLandingPage',
                    },
                    {
                      title: 'Data Offers',
                      translationKey: 'discover.dataOffers.title',
                      routeName: 'dataOffersLandingPage',
                    },
                    {
                      title: 'Offer Details',
                      translationKey: 'discover.offerDetails.title',
                      routeName: '',
                    }],
                  },
                },
              ],
              meta: {
                isProtected: true,
                permission: Permission.VIEW_DATA_PRODUCTS,
              },
            },
          ],
        },
      ],
    }, {
      path: '/dataspaces',
      name: 'dataspaces',
      component: () => import('@/layout/AbstractRouteView.vue'),
      meta: {
        isProtected: true,
      },
      children: [
        {
          name: 'dataspacesview',
          path: '',
          component: () => import('@/modules/dataspaces/browse/DataspacesView.vue'),
          meta: {
            isProtected: true,
            permission: Permission.BROWSE_DATASPACES,
            navigation: [{
              title: 'Data Spaces',
              translationKey: 'sideMenu.participate',
              routeName: '',
            }, {
              title: 'Browse Data Spaces',
              translationKey: 'dataspaces.browse.browseDataspace',
              routeName: 'browseDataspaces',
            }],
          },
        }, {
          path: 'browse',
          name: 'browseDataspaces',
          component: () => import('@/modules/dataspaces/browse/DataspacesView.vue'),
          meta: {
            isProtected: true,
            permission: Permission.BROWSE_DATASPACES,
            navigation: [{
              title: 'Data Spaces',
              translationKey: 'sideMenu.participate',
              routeName: '',
            }, {
              title: 'Browse Data Spaces',
              translationKey: 'dataspaces.browse.browseDataspace',
              routeName: 'browseDataspaces',
            }],
          },
        }, {
          path: 'connect/:id',
          name: 'connectDS',
          component: () => import('@/modules/dataspaces/connect/ConnectDS.vue'),
          beforeEnter: [dataspaceConnectGuard],
          meta: {
            isProtected: true,
            permission: Permission.JOIN_DATASPACE,
            navigation: [{
              title: 'Data Spaces',
              translationKey: 'sideMenu.participate',
              routeName: '',
            }, {
              title: 'Browse Data Spaces',
              translationKey: 'dataspaces.browse.browseDataspace',
              routeName: 'browseDataspaces',
            }, {
              title: '',
              translationKey: '',
              routeName: '',
            }],
          },
        }, {
          path: 'user/:id?',
          name: 'userDataspaces',
          component: () => import('@/modules/dataspaces/user/UserDataspace.vue'),
          meta: {
            isProtected: true,
            permission: Permission.ENTER_DATASPACE,
            navigation: [{
              title: 'Data Spaces',
              translationKey: 'sideMenu.participate',
              routeName: '',
            }, {
              title: 'My memberships',
              translationKey: 'dataspaces.user.myMemberships',
              routeName: 'userDataspaces',
            }],
          },
        }, {
          name: 'connectorHealthTest',
          path: 'connector-health-test/:id?',
          component: () => import('@/modules/dataspaces/details/ConnectorHealthTest.vue'),
          meta: {
            navigation: [
              {
                title: 'Data Spaces',
                translationKey: 'sideMenu.participate',
                routeName: '',
              },
              {
                title: 'My memberships',
                translationKey: 'dataspaces.user.myMemberships',
                routeName: 'userDataspaces',
              },
              {
                title: 'Connector health check',
                translationKey: 'build.connectorHealthCheck.title',
                routeName: 'connectorHealthTest',
              },
            ],
            isProtected: true,
          },
        }, {
          path: 'details/:id?/:name?',
          name: 'dataspaceDetails',
          component: () => import('@/modules/dataspaces/details/DataspaceDetails.vue'),
          meta: {
            isProtected: true,
            permission: Permission.ENTER_DATASPACE,
            navigation: [{
              title: 'Dataspaces',
              translationKey: 'sideMenu.participate',
              routeName: '',
            }, {
              title: 'My memberships',
              translationKey: 'dataspaces.user.myMemberships',
              routeName: 'userDataspaces',
            }, {}],
          },
        }, {
          path: 'browse/catenax/register/:id?',
          name: 'external-onboard',
          component: () => import('@/modules/osp-onboarding/pages/OspOnboarding.vue'),
          meta: {
            isProtected: true,
            permission: Permission.JOIN_DATASPACE,
            navigation: [{
              title: 'Data Spaces',
              translationKey: 'sideMenu.participate',
              routeName: '',
            }, {
              title: 'Data Spaces',
              translationKey: 'dataspaces.browse.browseDataspace',
              routeName: 'browseDataspaces',
            }, {
              title: '',
              translationKey: '',
              routeName: '',
            }],
          },
        },
      ],
    }, {
      path: '/build',
      name: 'build',
      component: () => import('@/layout/AbstractRouteView.vue'),
      meta: {
        isProtected: true,
      },
      children: [{
        name: 'owned-dataspaces',
        path: 'owned-dataspaces',
        component: () => import('@/modules/build/owned/OwnedDataspaces.vue'),
        meta: {
          isProtected: true,
          permission: Permission.VIEW_LIVING_LAB,
          navigation: [{
            title: 'Dataspaces',
            translationKey: 'sideMenu.build',
            routeName: '',
          }, {
            title: 'My dataspaces',
            translationKey: 'build.ownedDataspaces',
            routeName: 'owned-dataspaces',
          }],
        },
      }, {
        name: 'owned-dataspaces-details',
        path: 'owned-dataspaces-details/:id?/:name?',
        component: () => import('@/modules/build/owned/OwnedDataspaceDetails.vue'),
        meta: {
          isProtected: true,
          permission: Permission.MANAGE_LIVING_LAB,
          navigation: [{
            title: 'Dataspaces',
            translationKey: 'sideMenu.build',
            routeName: '',
          }, {
            title: 'My dataspaces',
            translationKey: 'build.ownedDataspaces',
            routeName: 'owned-dataspaces',
          }, {
            title: '',
            translationKey: '',
            routeName: '',
          }],
        },
      }, {
        name: 'connector-test',
        path: 'connector-test/:id?/:name?',
        component: () => import('@/modules/build/owned/ConnectorTest.vue'),
        meta: {
          isProtected: true,
          permission: Permission.MANAGE_LIVING_LAB,
          navigation: [{
            title: 'Dataspaces',
            translationKey: 'sideMenu.build',
            routeName: '',
          }, {
            title: 'My dataspaces',
            translationKey: 'build.ownedDataspaces',
            routeName: 'owned-dataspaces',
          }, {
            title: '',
            translationKey: '',
            routeName: 'owned-dataspaces-details',
          }, {
            title: 'Connector health check',
            translationKey: 'build.connectorHealthCheck.title',
            routeName: 'connector-test',
          }],
        },
      },
      {
        name: 'register-application',
        path: 'register-application/:id/:name',
        component: () => import('@/modules/build/owned/RegisterApplication.vue'),
        meta: {
          isProtected: true,
          permission: Permission.MANAGE_LIVING_LAB,
          navigation: [{
            title: 'Dataspaces',
            translationKey: 'sideMenu.build',
            routeName: '',
          }, {
            title: 'My dataspaces',
            translationKey: 'build.ownedDataspaces',
            routeName: 'owned-dataspaces',
          }, {
            title: '',
            translationKey: '',
            routeName: 'owned-dataspaces-details',
          }, {
            title: 'Register application',
            translationKey: 'build.applicationManagement.registerForm.pageTitle',
            routeName: 'register-application',
          }],
        },
      },
      {
        name: 'allocate-connectors',
        path: 'allocate-connectors/:id/:name',
        component: () => import('@/modules/build/owned/AllocateConnectors.vue'),
        meta: {
          isProtected: true,
          permission: Permission.MANAGE_LIVING_LAB,
          navigation: [{
            title: 'Dataspaces',
            translationKey: 'sideMenu.build',
            routeName: '',
          }, {
            title: 'My dataspaces',
            translationKey: 'build.ownedDataspaces',
            routeName: 'owned-dataspaces',
          }, {
            title: '',
            translationKey: '',
            routeName: 'owned-dataspaces-details',
          }, {
            title: 'Allocate Connectors',
            translationKey: 'build.allocateConnectors.allocateConnectorLabel',
            routeName: 'allocate-connectors',
          }],
        },
      },
      {
        name: 'edit-application',
        path: 'edit-application/:id/:name',
        component: () => import('@/modules/build/owned/EditApplication.vue'),
        meta: {
          isProtected: true,
          permission: Permission.MANAGE_LIVING_LAB,
          navigation: [{
            title: 'Dataspaces',
            translationKey: 'sideMenu.build',
            routeName: '',
          }, {
            title: 'My dataspaces',
            translationKey: 'build.ownedDataspaces',
            routeName: 'owned-dataspaces',
          }, {
            title: '',
            translationKey: '',
            routeName: 'owned-dataspaces-details',
          }, {
            title: 'Edit application',
            translationKey: 'build.applicationManagement.editForm.pageTitle',
            routeName: 'edit-application',
          }],
        },
      },
      ],
    }, {
      path: '/workbench',
      name: 'workbench',
      component: () => import('@/layout/AbstractRouteView.vue'),
      meta: {
        isProtected: true,
      },
      children: [
        {
          name: 'data-incoming',
          path: 'data-incoming',
          component: () => import('@/modules/workbench/incoming-contracts/pages/IncomingDataAgreements.vue'),
          meta: {
            isProtected: true,
            permission: Permission.VIEW_INCOMING_CONTRACTS,
            navigation: [{
              title: 'Workbench',
              translationKey: 'sideMenu.workbench',
              routeName: '',
            }, {
              title: 'Data Incoming',
              translationKey: 'sideMenu.dataIncoming',
              routeName: 'data-incoming',
            }],
          },
        },
        {
          name: 'contract-details',
          path: 'contract-details/:id',
          component: () => import('@/modules/workbench/incoming-contracts/pages/ContractDetails.vue'),
          meta: {
            isProtected: true,
            permission: Permission.VIEW_INCOMING_CONTRACTS,
            navigation: [{
              title: 'Workbench',
              translationKey: 'sideMenu.workbench',
              routeName: '',
            }, {
              title: 'Data Incoming',
              translationKey: 'sideMenu.dataIncoming',
              routeName: 'data-incoming',
            }, {}],
          },
        },
        {
          name: 'applications',
          path: 'applications',
          component: () => import('@/modules/workbench/applications/pages/ApplicationsPage.vue'),
          meta: {
            isProtected: true,
            permission: Permission.CONSUME_APPLICATION,
            navigation: [{
              title: 'Workbench',
              translationKey: 'sideMenu.workbench',
              routeName: '',
            }, {
              title: 'Applications',
              translationKey: 'sideMenu.applications',
              routeName: 'applications',
            }],
          },
        },
      ],
    },
    {
      path: '/verify',
      name: 'verify',
      component: () => import('@/layout/AbstractRouteView.vue'),
      meta: {
        isProtected: true,
      },
      children: [
        {
          path: 'get-verified',
          name: 'get-verified',
          component: () => import('@/modules/verify/DigitalIdView.vue'),
          meta: {
            isProtected: true,
            permission: Permission.VIEW_CREDENTIALS,
            navigation: [{
              title: 'Verify',
              translationKey: 'sideMenu.verify',
              routeName: '',
            }, {
              title: 'Get verified',
              translationKey: 'verify.verifiableCredentials.getVerified',
              routeName: '',
            }],
          },
        }, {
          path: 'credential-overview/:id',
          name: 'credential-overview',
          component: () => import('@/modules/verify/pages/vcOverview/CredentialList.vue'),
          meta: {
            isProtected: true,
            permission: Permission.VIEW_CREDENTIALS,
            navigation: [{
              title: 'Verify',
              translationKey: 'sideMenu.verify',
              routeName: '',
            }, {
              title: 'Get verified',
              translationKey: 'verify.verifiableCredentials.getVerified',
              routeName: 'get-verified',
            }, {
              title: 'Credential overview',
              translationKey: 'verify.vcOverview.credentialOverview',
              routeName: 'credential-overview',
            }],
          },
        }, {
          path: 'get-insights',
          name: 'get-insights',
          component: () => import('@/modules/verify/GetInsights.vue'),
          meta: {
            isProtected: true,
            permission: [Permission.VIEW_API_USAGE_LIMIT, Permission.VIEW_MONTHLY_REPORTS, Permission.VIEW_API_INTEGRATION],
            navigation: [{
              title: 'Verify',
              translationKey: 'sideMenu.verify',
              routeName: '',
            }, {
              title: 'Get Insights',
              translationKey: 'sideMenu.digitalIDDashboard',
              routeName: 'get-insights',
            }],
          },
        }, {
          path: 'edit-integration/:id',
          name: 'edit-integration',
          component: () => import('@/modules/verify/EditIntegration.vue'),
          meta: {
            isProtected: true,
            permission: Permission.VIEW_API_INTEGRATION,
            navigation: [{
              title: 'Verify',
              translationKey: 'sideMenu.verify',
              routeName: '',
            }, {
              title: 'Get Insights',
              translationKey: 'sideMenu.digitalIDDashboard',
              routeName: 'get-insights',
            }, {
              title: 'Add callback',
              translationKey: 'Add callback',
              routeName: 'edit-integration',
            }],
          },
        }, {
          path: 'add-integration',
          name: 'add-integration',
          component: () => import('@/modules/verify/AddIntegration.vue'),
          meta: {
            isProtected: true,
            permission: Permission.VIEW_API_INTEGRATION,
            navigation: [{
              title: 'Verify',
              translationKey: 'sideMenu.verify',
              routeName: '',
            }, {
              title: 'Get Insights',
              translationKey: 'sideMenu.digitalIDDashboard',
              routeName: 'get-insights',
            }, {
              title: 'Add an integration',
              translationKey: 'verify.addIntegration.addAnIntegration',
              routeName: 'add-integration',
            }],
          },
        }, {
          path: 'my-signatures',
          name: 'my-signatures',
          component: () => import('@/modules/verify/pages/mySignatures/MySignaturesView.vue'),
          meta: {
            isProtected: true,
            permission: Permission.VIEW_CERTIFICATES,
            navigation: [{
              title: 'Verify',
              translationKey: 'sideMenu.verify',
              routeName: '',
            }, {
              title: 'My Signatures',
              translationKey: 'sideMenu.mySignatures',
              routeName: 'my-signatures',
            }],
          },
        }, {
          path: 'request',
          name: 'get-verified-request',
          component: () => import('@/modules/verify/pages/vcRequest/RequestForm.vue'),
          meta: {
            isProtected: true,
            permission: Permission.REQUEST_CREDENTIALS,
            navigation: [{
              title: 'Verify',
              translationKey: 'sideMenu.verify',
              routeName: '',
            }, {
              title: 'Get verified',
              translationKey: 'verify.verifiableCredentials.getVerified',
              routeName: 'get-verified',
            }],
          },
        },
      ],
    },
    {
      path: 'settings',
      name: 'usersettings',
      component: () => import('@/layout/AbstractRouteView.vue'),
      meta: {
        isProtected: true,
      },
      children: [{
        path: 'user',
        name: 'userprofile',
        component: () => import('@/modules/settings/user/profile/UserProfile.vue'),
        meta: {
          isProtected: true,
          permission: Permission.VIEW_OWN_PROFILE,
          navigation: [{
            title: 'Profile',
            translationKey: 'profile.profile',
            routeName: '',
          }, {
            title: 'My account',
            translationKey: 'topMenu.myAccount',
            routeName: 'userprofile',
          }],
        },
      }, {
        path: 'organization',
        name: 'orgSettings',
        component: () => import('@/modules/settings/organization/OrgSettingsView.vue'),
        meta: {
          isProtected: true,
          permission: Permission.VIEW_ORGANIZATION_PROFILE,
          navigation: [{
            title: 'Profile',
            translationKey: 'profile.profile',
            routeName: '',
          }, {
            title: 'My organization',
            translationKey: 'topMenu.myOrganization',
            routeName: 'orgSettings',
          }],
        },
      }, {
        path: 'partner-network-management',
        name: 'partnerNetworkManagement',
        component: () => import('@/modules/settings/partner/PartnerNetworkManagement.vue'),
        meta: {
          isProtected: true,
          permission: Permission.VIEW_PARTNER_NETWORK,
          navigation: [{
            title: 'Profile',
            translationKey: 'profile.profile',
            routeName: '',
          }, {
            title: 'Partner network management',
            translationKey: 'partnerNetworkManagement.title',
            routeName: 'partnerNetworkManagement',
          }],
        },
      }],
    }, {
      path: 'notifications',
      name: '',
      component: () => import('@/layout/AbstractRouteView.vue'),
      meta: {
        isProtected: true,
      },
      children: [{
        path: '',
        name: 'notifications',
        component: () => import('@/modules/notifications/NotificationsView.vue'),
        meta: {
          isProtected: true,
          navigation: [{
            title: 'Notification',
            routeName: 'notifications',
            translationKey: 'notifications.notifications',
          }],
        },
      }],
    },
    {
      path: '/unauthorized-access',
      name: 'unauthorized',
      component: () => import('@/modules/error/ErrorPage.vue'),
      meta: {
        isProtected: true,
        variation: 'unauthorized',
        navigation: [
          {
            title: 'Home',
            translationKey: 'common.home',
            routeName: 'maindashboard',
          },
          {
            title: 'Unauthorized',
            translationKey: 'error.unauthorized',
            routeName: '',
          },
        ],
      },
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'pageNotFound',
      component: () => import('@/modules/error/ErrorPage.vue'),
      meta: {
        isProtected: true,
        variation: 'page-not-found',
        navigation: [
          {
            title: 'Home',
            translationKey: 'common.home',
            routeName: 'maindashboard',
          },
          {
            title: 'Page not found',
            translationKey: 'error.pageNotFound',
            routeName: '',
          },
        ],
      },
    },
    ...manageRoutes,
  ],
},
{
  path: '/pending-action',
  name: 'pendingAction',
  component: () => import('@/layout/SimpleLayout.vue'),
  children: [{
    path: 'complete-account',
    name: 'completeAccount',
    meta: {
      isProtected: true,
    },
    component: () => import('@/modules/settings/user/invited/UpdateProfile.vue'),
  },
  {
    path: 'complete-external-account',
    name: 'completeExternalAccount',
    component: () => import('@/modules/settings/user/external/CompleteExternalAccount.vue'),
    beforeEnter: () => {
      if (!isExternalAccountCompleted.value) {
        return true;
      }
      return router.push('/');
    },
  }],
},
{
  path: '/onboarding',
  name: 'onboarding',
  component: () => import('@/layout/SimpleLayout.vue'),
  children: [{
    path: 'register-organization/:UUId?',
    name: 'registerOrg',
    component: () => import('@/modules/onboarding/organization/OrganizationOnboarding.vue'),
  }, {
    path: 'verify-organization',
    name: 'verifyOrg',
    component: () => import('@/modules/onboarding/organization/VerifyOrganisation.vue'),
  }, {
    path: 'organization-not-found',
    name: 'orgNotFound',
    component: () => import('@/modules/onboarding/organization/OrganizationNotFound.vue'),
    beforeEnter: () => {
      if (store.getters.TOKEN !== null) {
        return true;
      } else {
        return router.push('/');
      }
    },
  }],
}];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: 'is-active',
  linkExactActiveClass: 'is-active',
  scrollBehavior () {
    // always scroll top
    return { top: 0 };
  },
});

// Interceptor for checking user is authenticated or not for protected pages
router.beforeEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  store.commit(`UserProfileStore/${SET_PAGE_BREADCRUMBS}`, to.meta.navigation !== undefined && to.meta.navigation.length > 0 ? to.meta.navigation : []);
  // check for protected pages
  if (to.meta?.isProtected ?? false) {
    try {
      await store.dispatch(CHECK_TOKEN, true);
      const user = store.getters[`UserProfileStore/${GET_USER_INFO}`];
      if (user === null) {
        if (!isExternalAccountCompleted.value) {
          next({
            name: 'completeExternalAccount',
          });
        } else {
          next();
        }
      }
      // redirect invited user to complete account
      if (user.userStatusInOrg === EnumUserStatus.INVITED && to.name !== 'completeAccount') {
        next({
          name: 'completeAccount',
        });
      } else {
        next();
      }
    } catch {
      next();
    }
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (!isNullish(to.meta.isProtected) && !isNullish(to.meta.permission)) {
    const { checkPermissions } = usePermission();

    const isAuthorized = checkPermissions(to.meta.permission as Permission);

    if (isAuthorized) {
      next();
    } else {
      next({
        name: 'unauthorized',
      });
    }
  } else {
    next();
  }
});

export default router;
