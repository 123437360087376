import type { ActionTree } from 'vuex';
import { jwtDecode } from 'jwt-decode';
import {
  SEND_BULK_VC_REQUEST,
  GET_WALLET_VC,
  ISSUE_WALLET_VC,
  GET_MIME_TYPES,
} from '../action-types';
import {
  SET_VC_REQUEST_IN_PROGRESS,
  SET_FETCH_VC_DETAILS_IN_PROGRESS,
  SET_BULK_ISSUING_STATUSES,
} from '../mutation-types';
import GetVerifiedService from '@/common/services/GetVerifiedServices';
import { ADD_SUCCESS_NOTIFICATION, ADD_ERROR_NOTIFICATION } from '@/components/NotificationBar/store/mutation-types';
import i18n from '@/i18n';
import { AxiosError } from 'axios';
import type { IGetVerifiedState } from '../index';
import { errorMessagesList } from './index';
import { enumCredentialStatus, credentialPageSize } from '@/common/constants/getVerifiedConstants';
import { enumCredentialStatus, credentialPageSize } from '@/common/constants/getVerifiedConstants';
import type {
  bulkVCPayloadData,
  legalPersonPayloadDataInterface,
  serviceOfferingPayloadDataInterface,
  resourcePayloadDataInterface,
} from '../../pages/vcRequest/interface';
import type { WalletRetrieveVCPayloadInt, WalletRetrieveFilterExpressionInt, InternalOrchestrationVCInterface } from '@/common/interfaces/verify/IGetVerified';

interface filterParams {
  pageNumber?: number
  pageSize?: number
  credentialType?: string
  filterBy?: string
  searchBy?: string
}

function updateVCExpStatus (expirationDate: string) {
  return new Date(expirationDate) >= new Date() ? enumCredentialStatus.VALID : enumCredentialStatus.EXPIRED;
}

const getWalletAPIPayload = ({ pageNumber, pageSize, credentialType, filterBy, searchBy }: filterParams): WalletRetrieveVCPayloadInt => {
  const filterExpressions: WalletRetrieveFilterExpressionInt[] = [];

  const payload: WalletRetrieveVCPayloadInt = {
    pageSize: pageSize ?? credentialPageSize,
    page: pageNumber,
    filterExpression: filterExpressions,
    sortOrder: 'ASC',
    sortField: 'addedOn',
    searchQuery: searchBy ?? '',
  };

  if (credentialType != null) {
    filterExpressions.push({
      operandLeft: 'verifiableCredential.credential.credentialSubject.type',
      operator: '=',
      operandRight: credentialType,
    });
  }

  if (filterBy != null && filterBy !== '') {
    filterExpressions.push(
      {
        operandLeft: 'state',
        operator: filterBy === 'valid' ? '!=' : '=',
        operandRight: 800,
      },
    );
  }

  return payload;
};

export const actions: ActionTree<IGetVerifiedState, {}> = {
  async [SEND_BULK_VC_REQUEST] ({ commit }, payload: bulkVCPayloadData) {
    try {
      await GetVerifiedService.issueWalletVC(payload);
      commit(SET_BULK_ISSUING_STATUSES, {
        legalName: payload.credentials[0].credentialSubject['gx:legalName'],
        status: 'Success',
        errors: '',
      });
      return 'success';
    } catch (error) {
      let errorMessages;
      if (error instanceof AxiosError && error?.response?.data?.errors) {
        errorMessages = errorMessagesList(error.response.data.errors);
      } else {
        errorMessages = [i18n.global.t('genericError')];
      }
      commit(SET_BULK_ISSUING_STATUSES, {
        legalName: payload.credentials[0].credentialSubject['gx:legalName'],
        status: 'Failed',
        errors: errorMessages,
      });
    }
  },

  async [GET_WALLET_VC] ({ commit, state }, filterCriteria: filterParams) {
    commit(SET_FETCH_VC_DETAILS_IN_PROGRESS, true);
    try {
      const response = await GetVerifiedService.getWalletVC(getWalletAPIPayload(filterCriteria));
      const { status, data } = response;
      if (status === 200) {
        if (Array.isArray(data.credentials) && data.credentials.length > 0) {
          data.credentials?.map((item: InternalOrchestrationVCInterface, index: number) => {
            item.vc = jwtDecode(item.document);
            item.status = updateVCExpStatus(item.vc.vc.expirationDate);
            return item;
          });
          return data;
        }
        return 'empty';
      } else {
        return 'error';
      }
    } catch (error) {
      if (error instanceof AxiosError && error?.response?.data?.errors) {
        const errorsList = errorMessagesList(error.response.data.errors);
        commit(
          ADD_ERROR_NOTIFICATION,
          { text: errorsList.length > 1 ? i18n.global.t('common.errors') : i18n.global.t('common.error'), list: errorsList },
          { root: true },
        );
      } else {
        commit(ADD_ERROR_NOTIFICATION, 'genericError', { root: true });
      }
      return 'error';
    } finally {
      commit(SET_FETCH_VC_DETAILS_IN_PROGRESS, false);
    }
  },

  async [ISSUE_WALLET_VC] ({ commit }, payload: legalPersonPayloadDataInterface | serviceOfferingPayloadDataInterface | resourcePayloadDataInterface) {
    commit(SET_VC_REQUEST_IN_PROGRESS, true);
    try {
      await GetVerifiedService.issueWalletVC(payload);
      commit(ADD_SUCCESS_NOTIFICATION, i18n.global.t('verify.verifiableCredentials.vcRequestSuccess'), { root: true });
      return 'success';
    } catch (error) {
      if (error instanceof AxiosError && error?.response?.data?.errors) {
        const errorsList = errorMessagesList(error.response.data.errors);
        commit(
          ADD_ERROR_NOTIFICATION,
          { text: errorsList.length > 1 ? i18n.global.t('common.errors') : i18n.global.t('common.error'), list: errorsList },
          { root: true },
        );
      } else {
        commit(ADD_ERROR_NOTIFICATION, 'genericError', { root: true });
      }
      return 'error';
    } finally {
      commit(SET_VC_REQUEST_IN_PROGRESS, false);
    }
  },

  async [GET_MIME_TYPES] ({ commit }) {
    try {
      const response = await GetVerifiedService.getMIMETypes();
      const { status, data } = response;
      if (status === 200) {
        return data.map((item: string) => ({
          text: item,
          value: item,
        }));
      } else {
        return [];
      }
    } catch (error) {
      commit(ADD_ERROR_NOTIFICATION, 'genericError', { root: true });
      return [];
    }
  },
};
