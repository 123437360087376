import type { MutationTree } from 'vuex';
import { pageSize } from '../../../common/constants/getVerifiedConstants';
import {
  SET_FETCH_VC_DETAILS_IN_PROGRESS,
  SET_COUNTRIES,
  SET_REGIONS,
  SET_BULK_ISSUING_STATUSES,
  SET_VC_REQUEST_IN_PROGRESS,
  SET_REPORT_LIST_DETAILS,
  SET_RL_FILTER_PARAMS,
  SET_FETCH_REPORT_LIST_IN_PROGRESS,
  SET_API_USAGE_LIMIT_DATA,
  SET_FETCH_API_USAGE_IN_PROGRESS,
  SET_CERTIFICATE_LIST,
  SET_FETCH_CERTIFICATE_LIST_IN_PROGRESS,
} from './mutation-types';
import type { certificateInterface } from './IMyCertificates';
import type { IGetVerifiedState } from './index';

const mutations: MutationTree<IGetVerifiedState> = {
  [SET_FETCH_VC_DETAILS_IN_PROGRESS] (state: IGetVerifiedState, payload: boolean) {
    state.fetchVCDetailsInProgress = payload;
  },
  [SET_VC_REQUEST_IN_PROGRESS] (state: IGetVerifiedState, payload: boolean) {
    state.vcRequestInProgress = payload;
  },
  [SET_COUNTRIES] (state: IGetVerifiedState, payload) {
    state.countries = payload;
  },
  [SET_REGIONS] (state: IGetVerifiedState, payload) {
    state.regions = payload;
  },
  [SET_BULK_ISSUING_STATUSES] (state: IGetVerifiedState, payload) {
    Array.isArray(payload) ? state.bulkIssuingStatuses = payload : state.bulkIssuingStatuses.push(payload);
  },
  [SET_RL_FILTER_PARAMS] (state: IGetVerifiedState, payload) {
    state.reportListFilterParams.sort = payload?.sort !== undefined ? payload.sort : null;
    state.reportListFilterParams.page = payload?.page !== undefined ? payload.page : 1;
    state.reportListFilterParams.pageSize = payload?.pageSize !== undefined ? payload.pageSize : pageSize;
    state.reportListFilterParams.find = payload?.find !== undefined ? payload.find : '';
    state.reportListFilterParams.startDate = payload?.startDate !== undefined ? payload.startDate : '';
    state.reportListFilterParams.endDate = payload?.endDate !== undefined ? payload.endDate : '';
  },
  [SET_REPORT_LIST_DETAILS] (state: IGetVerifiedState, payload) {
    state.reportsListDetails.reportList = payload.content;
    state.reportsListDetails.pageNumber = payload.pageNumber;
    state.reportsListDetails.totalElements = payload.totalElements;
    state.reportsListDetails.totalPages = payload.totalPages;
  },
  [SET_FETCH_REPORT_LIST_IN_PROGRESS] (state: IGetVerifiedState, payload: boolean) {
    state.fetchReportListInProgress = payload;
  },
  [SET_API_USAGE_LIMIT_DATA] (state: IGetVerifiedState, payload) {
    state.apiUsageLimitData = payload;
  },
  [SET_FETCH_API_USAGE_IN_PROGRESS] (state: IGetVerifiedState, payload: boolean) {
    state.fetchApiUsageInProgress = payload;
  },
  [SET_CERTIFICATE_LIST] (state: IGetVerifiedState, payload: certificateInterface[]) {
    state.certificateList = payload;
  },
  [SET_FETCH_CERTIFICATE_LIST_IN_PROGRESS] (state: IGetVerifiedState, payload: boolean) {
    state.fetchCertificateListInProgress = payload;
  },
};
export default mutations;
