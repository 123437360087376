import type { ActionTree } from 'vuex';
import { FETCH_CERTIFICATE_LIST } from '../action-types';
import { SET_CERTIFICATE_LIST, SET_FETCH_CERTIFICATE_LIST_IN_PROGRESS } from '../mutation-types';
import myCertificatesServices from '@/common/services/MyCertificatesServices';
import { ADD_ERROR_NOTIFICATION } from '@/components/NotificationBar/store/mutation-types';
import type { IGetVerifiedState } from '../index';

export const actions: ActionTree<IGetVerifiedState, {}> = {
  async [FETCH_CERTIFICATE_LIST] ({ commit }, payload) {
    commit(SET_FETCH_CERTIFICATE_LIST_IN_PROGRESS, true);
    try {
      const response = await myCertificatesServices.fetchCertificatesList(payload);
      const { status, data } = response;

      if (status === 200) {
        commit(SET_CERTIFICATE_LIST, data.certificates);
        return data.certificates;
      }
    } catch (error) {
      commit(ADD_ERROR_NOTIFICATION, 'genericError', { root: true });
    } finally {
      commit(SET_FETCH_CERTIFICATE_LIST_IN_PROGRESS, false);
    }
  },
};
