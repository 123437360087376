/**
 * This service is use for common request to get the data
 */

import axios, { type AxiosResponse } from 'axios';
import Util from '../util';
import type { IntReqPayloadOnboardingCxOrg, IntOnboardingOrgCXResponse } from '@/common/interfaces/organization/ICreateOrganization';

interface Country {
  id: number | string
  title: string
}

const commonService = {
  /**
   * Get a list of countries
   * @returns Country[]
   */
  getAllCountries: () => {
    return axios.get<Country[]>(Util.getRestApiUrl('country', 'org'));
  },

  sendConsultationRequestData: (payload: {
    product: string
    description: string
  }) => {
    return axios.post(Util.getRestApiUrl('product-requests', 'dataspace'), payload);
  },

  getLatestConsultationRequestData: (product: string) => {
    return axios.get(Util.getRestApiUrl(`product-consultation-requests?product=${product}`, 'dataspace'));
  },

  /**
   * Get registration form data
   * @returns OrganisationPreFilledData[]
   */
  getOrganisationPreFilledData: (orgId: string): Promise<AxiosResponse<IntOnboardingOrgCXResponse>> => {
    // response for org prefilled data
    return axios.get(`${Util.getRestApiUrl('organization', 'cx-bridge')}/${orgId}`, {
      validateStatus: function (status) {
        return status >= 200 && status <= 302;
      },
    });
  },

  /**
   * Onboard prefilled organization
   */
  createOnboardingOfOrganisation: (orgReqPayload: IntReqPayloadOnboardingCxOrg) => {
    // response for org prefilled data
    return axios.post(Util.getRestApiUrl('user/request-to-onboard', 'cx-bridge'), orgReqPayload);
  },

  /**
   * Update organisation status after completion
   */
  updateOrganisationStatusAfterCompletion: (payload: { uuid: string, status: string }) => {
    return axios.put(`${Util.getRestApiUrl('organization', 'cx-bridge')}/${payload.uuid}`, {
      status: payload.status,
    });
  },
};

export default commonService;
